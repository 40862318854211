import { format } from 'date-fns'
import { TableCell, TableRow } from '@material-ui/core'

import { ISection } from 'store/modules/goal/types'

interface SectionRowProps {
  section: ISection
}

export const SectionRow = ({ section }: SectionRowProps) => {
  return (
    <TableRow key={section._id}>
      <TableCell>{format(new Date(section.start), 'dd/MM/yyyy')}</TableCell>
      <TableCell>{format(new Date(section.end!), 'dd/MM/yyyy')}</TableCell>
    </TableRow>
  )
}
