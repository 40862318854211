import { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'

import { Element, animateScroll as scroll } from 'react-scroll'
import { toast } from 'react-toastify'
import { ArrowForwardIos } from '@material-ui/icons'

import { SelectUsersSection } from './components/SelectUsersSection'
import { MonthGoalsSection } from './components/MonthGoalsSection'
import { CalendarSection } from './components/CalendarSection'
import { SectionPickerModal } from './components/SectionPickerModal'
import { IStoreProps } from 'store/modules/store/types'
import { storeRequest } from 'store/modules/store/actions'
import {
  IDays,
  IGoal,
  IMonthGoal,
  ISection,
  IUserWorking
} from 'store/modules/goal/types'
import api from 'repositories/api'

import { NextStepButton } from '../../../style'
import { Container } from './styles'

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface UseParamsProps {
  storeId: string
  goalId: string
}

export interface ICurrentGoalProps extends IGoal {
  daysWeightSuggestion: number[]
  lastDaysBackup: IDays[]
}

export interface SectionList {
  start: Date
  end: Date | null
  goal: number
  daysWorking: number
}

interface GoalStepProps {
  goal: ICurrentGoalProps
  defaultDays: IDays[]
  loadingUpload: boolean
  handleNextStep: () => void
  updateGoal: (goal: Partial<ICurrentGoalProps>) => void
  updateCurrentGoal: (goal: Partial<IGoal>) => void
  setDeleteStep: (steps: string[]) => void
  setResetModal: (steps: string[]) => void
  setDeleteGoalStep: (steps: string[]) => void
  deleteGoalStep: (steps: string[]) => void
  goalList: IGoal[]
}

export const GoalStep = ({
  goal,
  defaultDays,
  handleNextStep,
  updateGoal,
  updateCurrentGoal,
  setDeleteStep,
  setResetModal,
  setDeleteGoalStep,
  deleteGoalStep,
  goalList,
  loadingUpload
}: GoalStepProps) => {
  // MonthGoals
  const [monthGoals, setMonthGoals] = useState<IMonthGoal[]>(goal.monthGoals)
  const [isCreatingSectionModalOpen, setIsCreatingSectionModalOpen] =
    useState(false)

  // UsersWorking
  const [users, setUsers] = useState<IUserWorking[]>([])
  const [usersWorking, setUsersWorking] = useState<IUserWorking[]>([])
  const [usersAccWeight, setUsersAccWeight] = useState(0)
  const [monthGoalLoading, setMonthGoalLoading] = useState(false)

  // Days
  const [days, setDays] = useState(defaultDays) // Days que vai ser renderizado no calendario (apenas de renderizacao)
  const [sections, setSections] = useState<ISection[]>([]) // Sections pura pra ir pro back

  // Auxs
  const [suggestionAnimationModal, setSuggestionAnimationModal] =
    useState(false)
  const [sectionsOpened, setSectionsOpened] = useState(false)
  const [isLoadingSuggestionRequest, setIsLoadingSuggestionRequest] =
    useState(false)

  const [basicCommissionView, setBasicCommissionView] = useState(false)

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  const { storeId, goalId } = useParams<UseParamsProps>()
  const dispatch = useDispatch()
  const token = localStorage.getItem('@NeoPro:token')

  const generateMonthGoals = async () => {
    // Meta principal da loja
    const mainGoalValue = goal.mainGoals.total / usersWorking.length
    const newUsersWorking =
      goal.usersWorking.length > 0
        ? [...usersWorking, ...users].map(user => ({
            ...user,
            userId: user.userId
          }))
        : usersWorking.map(user => ({
            ...user,
            userId: user.userId
          }))

    setMonthGoalLoading(true)
    const hasNoMonthGoals = monthGoals.length === 0
    if (hasNoMonthGoals) {
      const hasPreviousMonthGoals = goalList.find(
        ({ monthGoals }) => monthGoals.length > 0
      )

      if (hasPreviousMonthGoals) {
        const newMonthGoals = hasPreviousMonthGoals.monthGoals.map(
          (monthGoal, index) => {
            const { _id, initialCommission, ...monthGoalRest } = monthGoal

            const isFirstMonthGoal = index === 0
            if (isFirstMonthGoal) {
              return {
                ...monthGoalRest,
                value: mainGoalValue
              }
            }
            // Percentual calculado com relação à meta principal (index=0)
            const percentual =
              hasPreviousMonthGoals.monthGoals[index].value /
              hasPreviousMonthGoals.monthGoals[0].value

            return {
              ...monthGoalRest,
              value: percentual * mainGoalValue
            }
          }
        )
        if (newMonthGoals.length > 0) {
          const createMonthGoals = async () => {
            try {
              const response = await api.axios.post(
                `/goal/${goalId}/monthGoals`,
                newMonthGoals
              )

              setMonthGoals(response.data.monthGoals)
              updateCurrentGoal({ monthGoals: response.data.monthGoals })

              updateGoal({ usersWorking: newUsersWorking })
            } catch (err) {
              console.log(err)
            } finally {
              setMonthGoalLoading(false)
            }
          }

          await createMonthGoals()
        }
      } else {
        updateGoal({ usersWorking: newUsersWorking })
        setMonthGoalLoading(false)
      }
    } else {
      const updatedMonthGoals = monthGoals.map((monthGoal, index) => {
        const { _id } = monthGoal
        const isFirstMonthGoal = index === 0
        if (isFirstMonthGoal) {
          return {
            _id,
            value: mainGoalValue
          }
        }
        // Percentual calculado com relação à meta principal (index=0)
        const percentual = monthGoals[index].value / monthGoals[0].value

        return {
          _id,
          value: percentual * mainGoalValue
        }
      })

      if (updatedMonthGoals.length > 0) {
        try {
          const updateMonthGoals = async () => {
            const response = await api.axios.put(
              `/goal/${goalId}/monthGoals`,
              updatedMonthGoals
            )

            setMonthGoals(response.data.monthGoals)
            updateCurrentGoal({ monthGoals: response.data.monthGoals })

            updateGoal({ usersWorking: newUsersWorking })
          }
          await updateMonthGoals()
        } catch (err) {
          console.log(err)
        } finally {
          setMonthGoalLoading(false)
        }
      }
    }
  }

  // OS USE EFFECTS ESTÃO EM ORDEM DE ACONTECIMENTOS NA ETAPA DE META
  useEffect(() => {
    if (!store) {
      dispatch(storeRequest(storeId, token))
    }

    goal.usersWorking.length > 0
      ? scroll.scrollToBottom()
      : scroll.scrollToTop()
  }, [])

  // DEFINE A LISTA DE USUÁRIOS PARA RENDERIZAR OS VENDEDORES
  useEffect(() => {
    if (store && store.users.length > 0) {
      // users => lista da esquerda | usersWorking => lista da direita
      if (goal.usersWorking.length > 0) {
        const usersFromStore = store.users
          .filter(user => {
            const currentStore = user.stores.find(
              userStore => userStore.storeId === store._id
            )
            //Seleciona só quem não estiver no usersWorking e for do tipo seller
            return (
              currentStore?.type === 'seller' &&
              (goal.usersWorking.length > 0
                ? !goal.usersWorking.find(
                    userW => userW.userId?._id === user._id
                  )
                : true)
            )
          })
          .map(user => ({
            _id: user._id,
            userId: { name: user.name, photo: user.photo, _id: user._id },
            dayOff: {
              enable: false
            },
            goalWeight: 1,
            dsr: goal.dsr > 0,
            active: false,
            weightLocked: false
          }))

        // pode ter alguem com active false mas no working por causa do componente no overview
        const users = goal.usersWorking.reduce(
          (acc, user) => {
            const usersLinkedToStore = store.users.some(
              ({ _id }) => _id === user.userId?._id
            )

            if (usersLinkedToStore) {
              if (user.active) acc.working = [...acc.working, user]
              else {
                acc.notWorking = [...acc.notWorking, { ...user, active: false }]
              }
            }
            return acc
          },
          { working: [] as IUserWorking[], notWorking: [] as IUserWorking[] }
        )

        if (users.working.length === 0) {
          updateGoal({ usersWorking: [] })
          scroll.scrollToTop()
        }

        setUsers([...users.notWorking, ...usersFromStore])
        setUsersWorking(users.working)
      } else {
        if (usersWorking.length === 0) {
          //Todos os usuários
          const sellers = store.users.filter(user => {
            const currentStore = user.stores.find(
              userStore => userStore.storeId === store._id
            )
            return (
              currentStore?.type === 'seller' &&
              (users.length > 0
                ? !users.find(u => u.userId?._id === user._id)
                : true)
            )
          })
          setUsersAccWeight(sellers.length * 100)
          setUsersWorking(
            //Seleciona só quem ja estiver no usersWorking (sem esse find, ele joga todo mundo pra direita, e precisa manter na esquerda quem tava na esquerda)
            sellers.map(user => {
              return {
                _id: '',
                userId: {
                  _id: user._id,
                  name: user.name,
                  photo: user.photo
                },
                dayOff: {
                  enable: false
                },
                goalWeight: 1,
                dsr: goal.dsr > 0 ? true : false,
                active: true,
                weightLocked: false
              }
            }) //Coloca no padrão se nunca tiver preenchido ou se alterou e salvou
          )
        }
      }
    }
  }, [store, goal.usersWorking, goalList.length])

  useEffect(() => {
    const hasNoGoals = !(goalList.length > 0)
    if (hasNoGoals) {
      setMonthGoalLoading(false)
    }
  }, [goalList.length])

  useEffect(() => {
    // Modifica o acumulo de pesos sempre que sair ou entrar um vendedor
    const newUsersAccWeight =
      usersWorking.reduce((acc, user) => (acc += user.goalWeight), 0) * 100

    setUsersAccWeight(newUsersAccWeight)
  }, [usersWorking])

  //Caso o calendário esteja sendo renderizado, seta como true a parte de "Meta dos vendedores", para que seja renderizado também.
  useEffect(() => {
    const hasUsersWorking = goal.usersWorking.length > 0
    const hasMonthGoals = goal.monthGoals.length > 0
    const hasFixedCommission = goal.config?.commission?.fixed?.commission >= 0
    const hasDays = goal.days.length > 0

    if ((hasUsersWorking && hasMonthGoals && hasFixedCommission) || hasDays) {
      setBasicCommissionView(true)
    }
  }, [isLoadingSuggestionRequest])

  useEffect(() => {
    const hasUsersWorkingAndGoals =
      goal.usersWorking.length > 0 && goal.monthGoals.length > 0
    const hasFixedCommission = goal.config?.commission?.fixed?.commission >= 0
    const hasDaysAndMainGoal = goal.days.length > 0 && !!goal.mainGoals?.total
    const conditionsMet =
      (hasUsersWorkingAndGoals && hasFixedCommission) || hasDaysAndMainGoal

    const viewsAndLoadingConditions =
      basicCommissionView &&
      !(basicCommissionView || monthGoalLoading) &&
      !(isLoadingSuggestionRequest && !suggestionAnimationModal)

    if (conditionsMet && viewsAndLoadingConditions) {
      const goalDaysSum = goal.days.reduce(
        (acc, currDay) => acc + currDay.goal,
        0
      )
      const isGoalDaysSumEqual =
        Number(goalDaysSum.toFixed(2)) === goal.mainGoals.total

      if (!isGoalDaysSumEqual) {
        deleteGoalStep(['days'])
      }
    }
  }, [
    goal,
    basicCommissionView,
    monthGoalLoading,
    isLoadingSuggestionRequest,
    suggestionAnimationModal
  ])

  const handleSubmitSectionsPicker = () => {
    usersWorking.length !== Number(usersAccWeight.toFixed(0)) / 100 &&
      setResetModal(['usersWorking', 'days', 'dwt'])
    setDeleteGoalStep(['monthGoals', 'fixed'])
    setBasicCommissionView(true)

    const mainGoalValue = goal.mainGoals.total / usersWorking.length

    if (
      !goal.config.firstActivation &&
      goal.config.firstActivation !== undefined &&
      (!(monthGoals.length > 0) || mainGoalValue !== monthGoals[0].value)
    ) {
      generateMonthGoals()
    } else {
      const newUsersWorking =
        goal.usersWorking.length > 0
          ? [...usersWorking, ...users].map(user => ({
              ...user,
              userId: user.userId
            }))
          : usersWorking.map(user => ({
              ...user,
              userId: user.userId
            }))

      updateGoal({ usersWorking: newUsersWorking })
    }
  }

  const handleEditSectionsPicker = () => {
    setBasicCommissionView(false)
    setDeleteStep(['usersWorking', 'days', 'dwt'])
  }

  const handleGoToNextStep = () => {
    const hasSomeWorkingDay = goal.days.some(day => day.working)
    if (hasSomeWorkingDay) {
      handleNextStep()
    } else {
      toast.warn('A meta deve ter pelo menos um dia de trabalho')
    }
  }

  const hasFilledDaysAndSections =
    goal.days?.length > 0 && goal.sections.length > 0

  return (
    <>
      <Container>
        <Element name='sellers'>
          <SelectUsersSection
            goal={goal}
            users={users}
            usersWorking={usersWorking}
            usersAccWeight={usersAccWeight}
            handleSubmitSectionsPicker={handleSubmitSectionsPicker}
            handleEditSectionsPicker={handleEditSectionsPicker}
            setUsers={setUsers}
            setUsersWorking={setUsersWorking}
          />
        </Element>

        <Element name='monthGoals'>
          <MonthGoalsSection
            goal={goal}
            monthGoals={monthGoals}
            goalList={goalList}
            usersWorking={usersWorking}
            basicCommissionView={basicCommissionView}
            isLoadingSuggestionRequest={isLoadingSuggestionRequest}
            monthGoalLoading={monthGoalLoading}
            suggestionAnimationModal={suggestionAnimationModal}
            setMonthGoals={setMonthGoals}
            setDeleteStep={setDeleteStep}
            updateCurrentGoal={updateCurrentGoal}
          />
        </Element>

        <Element name='calendar'>
          <CalendarSection
            goal={goal}
            days={days}
            goalId={goalId}
            defaultDays={defaultDays}
            usersWorking={usersWorking}
            suggestionAnimationModal={suggestionAnimationModal}
            isLoadingSuggestionRequest={isLoadingSuggestionRequest}
            basicCommissionView={basicCommissionView}
            sectionsOpened={sectionsOpened}
            sections={sections}
            updateGoal={updateGoal}
            updateCurrentGoal={updateCurrentGoal}
            setDays={setDays}
            setSections={setSections}
            setIsLoadingSuggestionRequest={setIsLoadingSuggestionRequest}
            setSuggestionAnimationModal={setSuggestionAnimationModal}
            setIsCreatingSectionModalOpen={setIsCreatingSectionModalOpen}
            setDeleteStep={setDeleteStep}
            loadingUpload={loadingUpload}
          />
        </Element>

        <Element name='next-button-step'>
          {hasFilledDaysAndSections && !isLoadingSuggestionRequest && (
            <NextStepButton
              onClick={handleGoToNextStep}
              style={{ margin: 'auto' }}
              data-cy='nextStepButton'
            >
              Próxima etapa
              <ArrowForwardIos className='next-button-icon' />
            </NextStepButton>
          )}
        </Element>
      </Container>

      {/*   MODAL DE SECTIONS COM SECTION PICKER   */}
      <SectionPickerModal
        isOpen={isCreatingSectionModalOpen}
        goal={goal}
        days={days}
        sections={sections}
        setSections={setSections}
        setIsCreatingSectionModalOpen={setIsCreatingSectionModalOpen}
        setSectionsOpened={setSectionsOpened}
        updateGoal={updateGoal}
      />
    </>
  )
}
