import { formatPrice } from 'utils/format'
import { format } from 'date-fns'

import Tooltip from 'components/Tooltip'
import {
  Delete,
  DeleteButton,
  GoalValue,
  Index,
  SectionContainer
} from './styles'
import { SectionProps as ISection } from '../SectionsTable'

interface SectionProps {
  section: ISection
  handleDeleteSections: (index: number) => void
  index: number
}

export const Section = ({
  handleDeleteSections,
  section,
  index
}: SectionProps): JSX.Element => {
  const tooltipContent = (
    <p>
      {`Período total: ${format(new Date(section.start), 'dd/MM')} - ${format(
        new Date(section.end),
        'dd/MM'
      )}`}
    </p>
  )
  return (
    <SectionContainer data-tip data-for={`day-range-section-${index}`}>
      <Index>{index + 1}</Index>
      <GoalValue>{formatPrice(section.goal)}</GoalValue>
      <div className='util-day'>
        <span>{section.days - 1}</span>
        <Tooltip id={`day-range-section-${index}`} content={tooltipContent} />
      </div>
      <DeleteButton onClick={() => handleDeleteSections(index)}>
        <Delete />
      </DeleteButton>
    </SectionContainer>
  )
}
