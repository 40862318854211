import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import SpeedIcon from '@material-ui/icons/Speed'

import Tooltip from 'components/Tooltip'
import TransferListCard from './components/TransferListCard'
import EditUserGoalModal from './components/EditUserGoalModal'
import { IDays, IGoal, IUserWorking } from 'store/modules/goal/types'
import LockArrow from 'assets/Icons/LockArrow'
import { AccWeightCounter, Container, ContentBox } from './styles'

interface UsersGoalProps {
  usersNotWorking: IUserWorking[]
  usersWorking: IUserWorking[]
  totalWeight: number
  loading: boolean
  showUnlock: boolean
  onClickUnlock: () => void
  goal: IGoal | undefined
  handleUpdateUserWeight: (
    days: IDays[],
    user: IUserWorking,
    tab: 'fixed' | 'auto',
    sellerWeight: number,
    goalId: string,
    isRemoving: boolean
  ) => void
  newUser: IUserWorking | undefined
}

const UsersGoal = ({
  usersNotWorking,
  usersWorking,
  totalWeight,
  loading,
  showUnlock,
  onClickUnlock,
  goal,
  handleUpdateUserWeight,
  newUser
}: UsersGoalProps) => {
  //Caso haja usuário novo, abre o modal para editar o mesmo e adicionar a meta
  const [editUser, setEditUser] = useState<IUserWorking | undefined>(undefined)

  const history = useHistory()

  useEffect(() => {
    if (newUser) {
      setEditUser(newUser)
    }
  }, [newUser])

  const handleRemoveUser = (user: IUserWorking) => {
    if (goal) {
      handleUpdateUserWeight(
        [],
        {
          _id: user._id,
          userId: user.userId,
          goalWeight: 1,
          weightLocked: false,
          dsr: goal.dsr > 0,
          active: false
        },
        'auto',
        1,
        goal._id,
        true
      )
    }
    setEditUser(undefined)
  }

  const handleCloseEditUserModal = () => {
    setEditUser(undefined)
    if (window.location.href.indexOf(`/`) >= 0)
      history.push(`/${goal?.storeId}/goals/${goal?._id}`)
  }

  const hasGoalDistribute = !!goal?.distribute

  const allSellersAreWorking = !(usersNotWorking.length > 0)
  const totalWeightIsDifferentFromUsersWorking =
    Number(totalWeight.toFixed(2)) >
    Number(usersWorking?.filter(user => user.goalWeight > 0).length)

  const hasAccWeightCounterError = Boolean(
    hasGoalDistribute && totalWeightIsDifferentFromUsersWorking
  )

  const hasToAdjustUsersWeight =
    hasGoalDistribute && totalWeightIsDifferentFromUsersWorking

  const usersWorkingTotalWeight = usersWorking.reduce(
    (acc, user) => (acc += user.goalWeight * 100),
    0
  )

  const usersLengthDiffFromUsersWeight =
    usersWorking.length * 100 !== usersWorkingTotalWeight

  const hasSomeUserWeightDiffFromOne = usersWorking.find(
    user => user.goalWeight !== 1
  )

  return (
    <>
      <Container>
        <ContentBox>
          <div>
            <div>
              <h1>Não participam</h1>
              <span>{usersNotWorking.length}</span>
            </div>
          </div>
          <ul>
            {allSellersAreWorking ? (
              <p>
                {loading
                  ? `Carregando...`
                  : `Todos os vendedores estão na meta`}
              </p>
            ) : (
              usersNotWorking.map((user, i) => (
                <TransferListCard
                  key={i}
                  user={user}
                  onClick={() => setEditUser(user)}
                />
              ))
            )}
          </ul>
        </ContentBox>
        <ContentBox>
          <div>
            <div>
              <h1>Participam da meta</h1>
              <span>{usersWorking.length}</span>
            </div>
            <div>
              <AccWeightCounter error={hasAccWeightCounterError}>
                <div data-tip data-for='total-weight-tooltip'>
                  <Tooltip
                    fixed
                    place='top'
                    id='total-weight-tooltip'
                    content={
                      <p>
                        {hasToAdjustUsersWeight
                          ? `Ajuste os pesos para totalizar ${
                              usersWorking?.length * 100
                            }%`
                          : 'Total de pesos acumulado dos vendedores'}
                      </p>
                    }
                  />
                  <SpeedIcon fontSize='small' />
                  <h3>{(totalWeight * 100).toFixed(0)}%</h3>
                </div>

                <div data-tip data-for='locker-weight-tooltip'>
                  {showUnlock &&
                    (hasSomeUserWeightDiffFromOne ||
                      usersLengthDiffFromUsersWeight) && (
                      <>
                        <Tooltip
                          fixed
                          place='top'
                          id='locker-weight-tooltip'
                          content={
                            <p>
                              Desafixar peso dos vendedores para cálculo de Peso
                              Automático
                            </p>
                          }
                        />
                        <button onClick={onClickUnlock}>
                          <LockArrow />
                        </button>
                      </>
                    )}
                </div>
              </AccWeightCounter>
            </div>
          </div>
          <ul data-cy='usersWorkingSellersListTab'>
            {usersWorking.map((userW, i) => {
              const activeWorkingDays = goal?.days.filter(day => {
                const isUserWorkingOnCurrentDay = day.users.find(
                  usr => usr.userId === userW.userId?._id
                )
                const isUserActiveAndWorking =
                  isUserWorkingOnCurrentDay &&
                  !isUserWorkingOnCurrentDay.dayOff?.enable

                return isUserActiveAndWorking && userW.active && day.working
              }).length

              return (
                <TransferListCard
                  working
                  inGoal
                  isGoalOverview
                  key={i}
                  user={userW}
                  data={goal}
                  days={activeWorkingDays}
                  onClick={() => setEditUser(userW)}
                />
              )
            })}
          </ul>
        </ContentBox>
      </Container>

      {goal && (
        <EditUserGoalModal
          modal={!!editUser}
          currentMonthGoal={goal}
          usersWorkingLength={usersWorking.length}
          handleUpdateUserWeight={handleUpdateUserWeight}
          handleRemoveUser={handleRemoveUser}
          user={editUser}
          onClose={handleCloseEditUserModal}
          loading={loading}
        />
      )}
    </>
  )
}

export default UsersGoal
