import { ReviewCard } from '../ReviewCard'
import { ReviewItem } from '../ReviewItem'
import { IGoal } from 'store/modules/goal/types'

interface CommissionSectionProps {
  goal: IGoal
  setActiveStep: () => void
}

export const CommissionSection = ({
  goal,
  setActiveStep
}: CommissionSectionProps) => {
  const commissionType = goal.salary.directCommission
    ? 'Comissão direta'
    : 'Piso salarial + comissão'

  const periodType =
    goal.sections.length > 1 ? 'Vários períodos' : 'Período único'
  const periodLength = `${goal.sections.length}`

  const baseCommissionType = goal.salary.splitCommission
    ? 'Resultado por período'
    : 'Resultado por mês'

  const goalDistributionType = `${
    goal.distribute ? 'Meta distribuída' : 'Meta fixa'
  }`

  const receivingCommission = goal.config.commission.storeSold
    ? 'Total da loja'
    : 'Meta individual'

  return (
    <ReviewCard
      name='Comissão'
      items={
        <>
          <ReviewItem title='Tipo de Comissão' value={commissionType} />
          <ReviewItem
            title='Período da meta'
            value={periodType}
            subValue={periodLength}
          />
          <ReviewItem title='Base da comissão' value={baseCommissionType} />
          <ReviewItem
            title='Distribuição da meta'
            value={goalDistributionType}
          />
          <ReviewItem
            title='Recebimento da comissão'
            value={receivingCommission}
          />
        </>
      }
      setActiveStep={setActiveStep}
    />
  )
}
