import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  display: flex;
  width: 55vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Roboto', sans-serif;

  > button {
    margin-right: auto;
  }
`
