export const valuesInfo = {
  wageFloor: {
    title: 'Piso Salarial',
    description: 'O mínimo que pode ser pago para a classe de vendedores.',
    tooltipContent: <p>Mínimo fixado por lei ou convenção coletiva</p>,
    inputLabel: 'Valor do piso salarial'
  },
  salesValue: {
    title: '1ª Meta da loja',
    description: null,
    tooltipContent: <p>Valor esperado de faturamento mensal.</p>,
    inputLabel: 'Valor do Faturamento'
  },
  averageTMValue: {
    title: 'Meta de Ticket Médio',
    description: null,
    tooltipContent: <p>Valor médio esperado por venda.</p>,
    inputLabel: 'Valor do Ticket Médio'
  },
  averagePMValue: {
    title: 'Meta de Preço Médio',
    description: null,
    tooltipContent: <p>Valor médio esperado dos produtos por venda.</p>,
    inputLabel: 'Valor do Preço Médio'
  },
  salaryComplementCheckbox: {
    title: 'Complemento de salário',
    description:
      'Complementar a remuneração automaticamente quando a comissão não alcançar o Piso Salarial?',
    tooltipContent: (
      <p>
        João vendeu <span>R$ 25.000</span>, e sua comissão é de <span>5%</span>.
        <br />
        Piso Salarial é de <span>R$ 1.500</span>
        <br />O complemento automático será de <span>R$ 250</span>.
        <br />
        <br />
        25.000 * 0,05 = R$ 1.250
        <br />
        <span style={{ color: '#FFC700' }}>R$ 1.250</span> {'<'} R$ 1.500 <br />
        1.500 - 1.250 = <span>R$ 250</span>
      </p>
    ),
    inputLabel: 'Valor do Preço Médio'
  },
  dsrCheckbox: {
    title: 'Descanso Semanal Remunerado (DSR)',
    description: 'Deseja incluir DSR no cálculo da remuneração?',
    tooltipContent: (
      <p>
        Dia de folga semanal garantido por lei, que não será descontado do
        salário.
        <br />
        <br />
        Em caso de dúvidas, consulte o setor do RH ou contabilidade.
      </p>
    ),
    inputLabel: 'Valor do Preço Médio'
  }
} as const
