import {
  useEffect,
  forwardRef,
  useState,
  useRef,
  useLayoutEffect,
  KeyboardEvent,
  FocusEvent,
  ChangeEvent,
  ForwardedRef
} from 'react'

import { getDate, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { Checkbox, Modal } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'

import CurrencyTextField from 'components/CurrencyTextField'
import PromptModal from '../../../../../../PromptModal'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import {
  IDays,
  IDaysUsers,
  IDaysWithDayOff,
  IUserWorking
} from 'store/modules/goal/types'
import { ICurrentGoalProps } from '../../../..'
import { UsersDaysWorkingAmount } from '../..'
import palette from 'theme/palette'
import WandIcon from 'assets/Icons/WandIcon'
import CheckPerson from 'assets/Icons/CheckPerson'

import {
  ArrowBackIcon,
  Container,
  PersonIcon,
  SellersAmountButton
} from './styles'
import { UsersModal } from '../UsersModal'

export type IWorkOptions = 'willWork' | 'willNotWork' | 'dayOff'

interface CardDayProps {
  data: ICurrentGoalProps
  day?: IDaysWithDayOff
  date: Date
  disabled?: boolean
  locked?: boolean
  editableDay?: IDaysWithDayOff | undefined

  setEditableUsersOnDays?: (newUsers: IDaysUsers[]) => void
  setWorkDay?: (day: boolean) => void
  setValue?: (data: { target: { inputValue: number } }) => void
  usersWorking?: IUserWorking[]
  editUsersWorking?: (day: IDays, date: Date) => void
  usersDaysWorkingAmount?: UsersDaysWorkingAmount

  index: number
  isEditing?: number
  setIsEditing?: (value: number) => void
  suggestion?: boolean
  updateGoal?: (obj: any) => void
  handleNext?: () => void
}

const CardDay = (
  {
    data,
    day,
    date,
    disabled = false,
    locked,
    editableDay,

    setEditableUsersOnDays,
    setWorkDay,
    setValue,
    usersWorking,
    editUsersWorking,
    usersDaysWorkingAmount,

    index,
    isEditing,
    setIsEditing,
    suggestion,
    updateGoal,
    handleNext
  }: CardDayProps,
  ref: ForwardedRef<unknown>
) => {
  const currentInput = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState(0)
  const [isDisableSuggestionModalOpen, setIsDisableSuggestionModalOpen] =
    useState(false)

  const [sellerModal, setSellerModal] = useState(false)

  const usersWorkingIds = usersWorking?.map(user => user.userId._id) ?? []

  const availableUsersToPick = !!editableDay?.users
    ? editableDay.users.filter(user => {
        const usrId = user.userId._id
        return usersWorkingIds.includes(usrId)
      })
    : []

  const activeUsersLength = editableDay?.users.length ?? 0

  const hasSellersWorking =
    editableDay?.users.filter(usr => !usr.dayOff.enable && !usr.notWorking)
      ?.length ?? 0

  const handleChangeUserActive = (newUsersList: IDaysUsers[]) => {
    setEditableUsersOnDays && setEditableUsersOnDays(newUsersList)
  }

  const handleFinishEditCardByKeyboard = (
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    const hasFinished = event.key === 'Enter' || event.key === 'Tab'
    const hasInputGoalValue = inputValue >= 0
    if (hasFinished && hasInputGoalValue) {
      const isCurrentGoalDifferentFromPreviousGoal = inputValue !== day?.goal
      if (isCurrentGoalDifferentFromPreviousGoal) {
        event.preventDefault()
        !!setValue &&
          setValue({
            target: { inputValue: inputValue === null ? 0 : inputValue }
          })
        !!handleNext && handleNext()
      } else {
        !!handleNext && handleNext()
      }
    }
    if (event.key === 'Escape') {
      !!setIsEditing && setIsEditing(-1)
    }
  }

  const handleFinishEditCardByMouse = () => {
    // Veio de fora do input
    if (inputValue !== editableDay?.goal && currentInput.current) {
      currentInput.current.blur()
      setInputValue(Number(currentInput.current.value))

      !!setValue &&
        setValue({
          target: { inputValue: inputValue === null ? 0 : inputValue }
        })
    }
    setIsEditing && setIsEditing(-1)
  }

  const handleCancel = () => {
    setInputValue(day?.goal ?? 0)
    setIsEditing && setIsEditing(-1)
  }

  useLayoutEffect(() => {
    if (day?.goal) setInputValue(day.goal)
  }, [day?.goal])

  useEffect(() => {
    // Define a referência para o Calendar
    if (
      currentInput &&
      ref &&
      typeof ref !== 'function' &&
      ref.current !== currentInput.current
    )
      ref.current = currentInput.current
  }, [ref, currentInput])

  const handleCloseDisableSuggestionModal = () => {
    currentInput.current?.blur()
    setInputValue(day?.goal ?? 0)
    setIsDisableSuggestionModalOpen(false)
  }

  const handleChangeGoalInputValue = (
    _: ChangeEvent<HTMLInputElement>,
    value: number
  ) => {
    if (!isEditing && setIsEditing) setIsEditing(index)
    setInputValue(value)
  }

  const handleDisableSuggestion = () => {
    currentInput.current?.blur()

    const newTargetValue = inputValue === null ? 0 : inputValue

    !!setValue && setValue({ target: { inputValue: newTargetValue } })

    const newBackupDays = data.lastDaysBackup.map((backupDay, i) => {
      if (i === index - 1) {
        return {
          ...backupDay,
          goal: newTargetValue
        }
      }
      return backupDay
    })

    !!updateGoal &&
      updateGoal({
        lastDaysBackup: newBackupDays,
        config: {
          ...data.config,
          suggestion: false
        }
      })
    !!handleNext && handleNext()
    setIsDisableSuggestionModalOpen(false)
  }

  const handleBackToSuggestionInputGoalValue = () => {
    !disabled && !!setWorkDay && setWorkDay(true)
    setIsEditing && setIsEditing(-1)
  }

  const isEditingCurrentDay = isEditing === index

  const isWorkingDay = (!disabled || locked) && day?.working

  const sellerModalTitle = `Vendedores que irão trabalhar ${format(
    date,
    'EEEE',
    {
      locale: ptBR
    }
  )}, ${format(date, 'dd/MM/yyyy')}:`

  const goalInputTextStyle = {
    background: '#F7F7F8',
    padding: '0px 5px',
    borderRadius: 3,
    width: '100%',
    underline: {
      '&:before': {
        borderBottom: '1px solid rgba(255, 133, 51, 0.42)'
      },
      '&:after': {
        borderBottom: `2px solid rgba(255, 133, 51, 0.42)`
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `2px solid rgba(255, 133, 51, 0.42)`
      }
    }
  }

  const handleSaveUserEditingGoal = () => {
    if (!!editUsersWorking && editableDay && !!day) {
      const newUsers: IDaysUsers[] = editableDay.users
        .filter(usr => {
          return !usr.notWorking
        })
        .map(usr => {
          return {
            dayOff: usr.dayOff,
            userId: usr.userId._id
          }
        })

      const formattedDay: IDays = {
        ...day,
        users: newUsers
      }

      editUsersWorking(formattedDay, new Date(date))
    }
    setSellerModal(false)
  }

  const onCloseDayCardModal = () => {
    setSellerModal(false)
    const newUsers = !!day?.users
      ? day.users.map(usr => ({
          ...usr,
          dayOff: usr.dayOff,
          userId: usr.userId._id
        }))
      : []

    setEditableUsersOnDays && setEditableUsersOnDays(newUsers)
  }

  return (
    <>
      {isEditingCurrentDay && (
        <div className='editing-modal-backdrop' onClick={handleCancel} />
      )}
      <Container isModal={isEditingCurrentDay}>
        <div {...(disabled && { className: 'disable-day' })}>
          <div className='calendar-card-top'>
            <div
              className='calendar-card-top-checkbox'
              onClick={() => {
                !!setWorkDay && setWorkDay(!day?.working)
              }}
            >
              <Checkbox
                className='checkbox'
                checked={isWorkingDay}
                style={{
                  color: '#78909C',
                  padding: 0,
                  marginRight: 2
                }}
                size='small'
              />
              <span>{getDate(date)}</span>
            </div>

            {isWorkingDay && (
              <BootstrapTooltip title='Vendedores que irão trabalhar neste dia'>
                <div
                  className={`calendar-card-top-buttons ${
                    !!locked && 'disable-day'
                  }`}
                  data-tip
                  data-for={`cardDay-${getDate(
                    new Date(day?.date)
                  )}-sellers-tooltip`}
                >
                  <SellersAmountButton
                    onClick={() => setSellerModal(true)}
                    disabled={!!locked}
                    isLocked={!!locked}
                    areAllSellersWorking={
                      !!usersWorking && activeUsersLength < usersWorking.length
                    }
                  >
                    <PersonIcon fontSize='small' />
                    <span>{activeUsersLength}</span>
                  </SellersAmountButton>
                </div>
              </BootstrapTooltip>
            )}
          </div>
          {isWorkingDay && (
            <>
              <BootstrapTooltip
                title={
                  locked
                    ? 'Dia anterior. Desbloqueie o calendário para edição'
                    : ''
                }
              >
                <div
                  className={`calendar-card-input ${
                    suggestion && 'proft-suggestion'
                  } ${locked && 'disable-day'}`}
                  onClick={() => {
                    !!setIsEditing && setIsEditing(index)
                  }}
                  onKeyDown={handleFinishEditCardByKeyboard}
                  data-tip
                  data-for={`cardDay-${getDate(
                    new Date(day?.date)
                  )}-disabled-day-description`}
                >
                  <CurrencyTextField
                    ref={(currRef: any) =>
                      (currentInput.current = currRef?.input)
                    }
                    disabled={locked}
                    style={goalInputTextStyle}
                    fullWidth
                    onFocus={(event: FocusEvent<HTMLInputElement>) =>
                      event.target.select()
                    }
                    currencySymbol='R$'
                    decimalCharacter=','
                    digitGroupSeparator='.'
                    value={inputValue}
                    inputProps={{
                      style: {
                        textAlign: 'left',
                        color: '#000'
                      }
                    }}
                    onChange={handleChangeGoalInputValue}
                  />
                </div>
              </BootstrapTooltip>
              {day.working && day.goalLocked && !locked && (
                <ArrowBackIcon
                  fontSize='small'
                  onClick={handleBackToSuggestionInputGoalValue}
                />
              )}
            </>
          )}
        </div>

        {isEditingCurrentDay && (
          <div className='modal-buttons'>
            <button onClick={handleCancel}>
              <Close />
              ESC
            </button>
            <button
              disabled={day?.goal === null && !inputValue}
              onClick={handleFinishEditCardByMouse}
            >
              <Check />
              ENTER
            </button>
          </div>
        )}

        <Modal open={sellerModal}>
          <PromptModal
            size='xl'
            icon={<CheckPerson />}
            title={sellerModalTitle}
            onClose={onCloseDayCardModal}
            leftTitle='Cancelar'
            disableRight={!hasSellersWorking}
            onRight={handleSaveUserEditingGoal}
            rightTitle={
              activeUsersLength ? 'Salvar' : 'Adicione pelo menos um vendedor'
            }
          >
            {editableDay && (
              <UsersModal
                day={editableDay}
                availableUsersToPick={availableUsersToPick}
                activeUsersLength={activeUsersLength}
                handleChangeUserActive={handleChangeUserActive}
                usersDaysWorkingAmount={usersDaysWorkingAmount}
              />
            )}
          </PromptModal>
        </Modal>
        <Modal open={isDisableSuggestionModalOpen}>
          <PromptModal
            size='md'
            icon={<WandIcon color={palette.primary.main} size={20} />}
            onClose={handleCloseDisableSuggestionModal}
            title='Desativar Sugestão?'
            description='Ao alterar os valores de meta diária, a Sugestão NeoPro será desativada.'
            onLeft={handleCloseDisableSuggestionModal}
            onRight={handleDisableSuggestion}
            rightTitle='Desativar Sugestão'
          />
        </Modal>
      </Container>
    </>
  )
}

export default forwardRef(CardDay)
