import { InputHTMLAttributes, ReactNode } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import Tooltip from 'components/Tooltip'
import { valuesInfo } from '../../valuesInfo'

import { Container } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  'data-cy'?: string
}

type CardsInfoKeys = keyof typeof valuesInfo

interface CardCheckProps {
  id: CardsInfoKeys
  children?: ReactNode
  inputDataCy: string
  isSelected: boolean
  setIsSelected: () => void
}

export const CardCheck = ({
  id,
  children,
  inputDataCy,
  isSelected,
  setIsSelected
}: CardCheckProps) => {
  const currentCard = valuesInfo[id]

  return (
    <Container isSelected={isSelected}>
      <header className='card-check-header'>
        <Checkbox
          checked={isSelected}
          color='primary'
          onChange={setIsSelected}
          inputProps={
            {
              'data-cy': inputDataCy
            } as InputProps
          }
        />
        <h1>{currentCard.title}</h1>
        {currentCard.tooltipContent && (
          <>
            <ErrorOutlineIcon
              data-tip
              data-for={currentCard.title}
              style={{ color: '#C4C4C4', fontSize: 20 }}
            />
            <Tooltip
              id={currentCard.title}
              content={currentCard.tooltipContent}
            />
          </>
        )}
      </header>
      <span className='disclaimer'>{currentCard.description}</span>
      {children && (
        <div className='card-check-children-container'>{children}</div>
      )}
    </Container>
  )
}
