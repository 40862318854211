import { format } from 'date-fns'

import { ReviewItem } from '../ReviewItem'
import { formatPrice } from 'utils/format'
import { IGoal } from 'store/modules/goal/types'
import { CardSubValue, CardValue, Container } from '../ReviewItem/styles'

interface GoalPeriodsSectionProps {
  goal: IGoal
}

interface SectionsProps {
  start: Date
  end: Date
  goal: number
  days: number
}

export const GoalPeriodsSection = ({ goal }: GoalPeriodsSectionProps) => {
  const sectionsLength = goal.sections.length
  const hasSuggestion = goal.config.suggestion

  const extraChildComponent = hasSuggestion ? (
    <h3 style={{ color: '#FAB300' }}>Com sugestão NeoPro</h3>
  ) : (
    <h3 style={{ color: 'grey' }}>Sem sugestão NeoPro</h3>
  )

  const formattedSections = goal.days.reduce<SectionsProps[]>(
    (sections, day) => {
      // Passa por todos os days já somando a meta dentro de cada section
      const isWorkingDay = day.working
      if (isWorkingDay) {
        const newDate = new Date(day.date)

        sections = sections.map(section => {
          const isCurrentDateOnOrAfterStart = newDate >= section.start
          const isCurrentDateBeforeOrOnEnd = newDate <= section.end

          if (isCurrentDateOnOrAfterStart && isCurrentDateBeforeOrOnEnd) {
            section.goal += day.goal
            section.days++
          }
          return section
        })
      }
      return sections
    },
    //Inicializa valor inicial do reduce apenas com sections completas e valores zerados
    goal.sections.reduce((acc: SectionsProps[], { start, end }) => {
      if (end) {
        const dateStart = typeof start === 'string' ? new Date(start) : start
        const dateEnd = typeof end === 'string' ? new Date(end) : end

        acc.push({
          start: dateStart,
          end: dateEnd,
          goal: 0,
          days: 1
        })
      }

      return acc
    }, [])
  )

  const contentChildren = formattedSections.map((section, index) => {
    const periodNumber = index + 1
    const sectionGoal = formatPrice(section.goal)
    const startDate = format(new Date(section.start), 'dd/MM')
    const endDate = format(new Date(section.end), 'dd/MM')

    return (
      <Container key={index}>
        <CardValue>{`Período ${periodNumber} - ${sectionGoal}`}</CardValue>
        <CardSubValue>
          {`${startDate} a ${endDate}
              - ${section.days - 1} dias úteis`}
        </CardSubValue>
      </Container>
    )
  })

  return (
    <ReviewItem
      title={`Períodos da meta (${sectionsLength})`}
      extraChildren={extraChildComponent}
      contentChildren={contentChildren}
    />
  )
}
