import { ChangeEvent } from 'react'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import CurrencyTextField from 'components/CurrencyTextField'
import Tooltip from 'components/Tooltip'
import { valuesInfo } from '../../valuesInfo'

import { Container } from './styles'

type CardsInfoKeys = keyof typeof valuesInfo

interface InputCardProps {
  id: CardsInfoKeys
  value: number | null
  hasError: boolean
  dataCy?: string
  onChange: (_: ChangeEvent<HTMLInputElement>, value: number) => void
}

export const InputCard = ({
  id,
  hasError,
  value,
  onChange,
  dataCy = undefined
}: InputCardProps) => {
  const currentInputCard = valuesInfo[id]

  return (
    <Container>
      <header>
        <h1>{currentInputCard.title}</h1>
        {currentInputCard.tooltipContent && (
          <>
            <ErrorOutlineIcon
              data-tip
              data-for={currentInputCard.title}
              style={{ color: '#C4C4C4', fontSize: 20 }}
            />
            <Tooltip
              id={currentInputCard.title}
              content={currentInputCard.tooltipContent}
            />
          </>
        )}
      </header>
      {currentInputCard.description && (
        <span>{currentInputCard.description}</span>
      )}
      <div data-cy={dataCy}>
        <CurrencyTextField
          error={hasError}
          data-cy='wageFloorInput'
          label={currentInputCard.inputLabel}
          variant='outlined'
          fullWidth
          value={value}
          currencySymbol='R$'
          decimalCharacter=','
          digitGroupSeparator='.'
          inputProps={{
            style: { textAlign: 'left' },
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
          InputLabelProps={{ shrink: false }}
          onChange={onChange}
        />
      </div>
    </Container>
  )
}
