import { useState } from 'react'

import { Add as AddIcon } from '@material-ui/icons/'

import { TableItem } from './components/TableItem'
import { GoalModal } from './components/GoalModal'
import { IGoal, IMonthGoal, IUserWorking } from 'store/modules/goal/types'
import api from 'repositories/api'

import { Container, Header, Content, Table } from './styles'

interface GoalsTableProps {
  goal: IGoal
  updateCurrentGoal: (currentGoal: Partial<IGoal>) => void
  usersWorking: IUserWorking[]
  monthGoals: IMonthGoal[]
  setMonthGoals: (monthGoals: IMonthGoal[]) => void
}

export const GoalsTable = ({
  goal,
  updateCurrentGoal,
  usersWorking,
  monthGoals,
  setMonthGoals
}: GoalsTableProps) => {
  const [isCreatingNewGoal, setIsCreatingNewGoal] = useState(false)
  const [isEditingGoal, setIsEditingGoal] = useState(false)
  const [indexToEdit, setIndexToEdit] = useState(-1)

  const handleSetIsCreatingGoal = (value: boolean) => {
    setIsCreatingNewGoal(value)
  }

  const handleSetIsEditingGoal = (value: boolean) => {
    setIsEditingGoal(value)
  }

  // na chamada inicial formatamos ('no-bonus') do back pra usar no front,
  // agora precisamos formatar os no-bonus pra conseguir enviar de volta pro back
  // no-bonus = bonusPercentage: false e bonus: 0
  const formatMonthGoalsToBackendFormat = (monthGoals: IMonthGoal[]) => {
    return monthGoals.map(monthGoal => {
      if (monthGoal.bonusPercentage === 'no-bonus' && !monthGoal.bonus) {
        return {
          ...monthGoal,
          bonusPercentage: false,
          bonus: 0
        }
      }
      return monthGoal
    })
  }

  const formatMonthGoalsToFrontendFormat = (
    monthGoals: IMonthGoal[]
  ): IMonthGoal[] => {
    return monthGoals.map(monthGoal => {
      if (!monthGoal.bonusPercentage && monthGoal.bonus === 0) {
        return {
          ...monthGoal,
          bonusPercentage: 'no-bonus'
        }
      }
      return monthGoal
    })
  }

  const getMonthGoalConfig = (
    monthGoal: Omit<IMonthGoal, '_id' | 'initialCommission'>
  ) => {
    const hasNoBonus =
      monthGoal.bonusPercentage === 'no-bonus' && !monthGoal.bonus

    const monthGoalConfig = hasNoBonus
      ? {
          ...monthGoal,
          bonusPercentage: false,
          bonus: 0
        }
      : monthGoal

    return monthGoalConfig
  }

  const handleCreateNewMonthGoal = async (
    monthGoal: Omit<IMonthGoal, '_id' | 'initialCommission'>
  ) => {
    const response = await api.axios.post(
      `/goal/${goal._id}/monthGoal`,
      getMonthGoalConfig(monthGoal)
    )

    const monthGoalsResponse = response.data.monthGoals
    setMonthGoals(formatMonthGoalsToFrontendFormat(monthGoalsResponse))
    updateCurrentGoal({ monthGoals: monthGoalsResponse })
  }

  const handleEditMonthGoal = async (
    index: number,
    monthGoal: Omit<IMonthGoal, 'initialCommission' | '_id'>
  ) => {
    const response = await api.axios.put(
      `/goal/${goal._id}/monthGoal/${monthGoals[index]._id}`,
      getMonthGoalConfig(monthGoal)
    )

    const monthGoalsResponse = response.data.monthGoals
    setMonthGoals(formatMonthGoalsToFrontendFormat(monthGoalsResponse))
    updateCurrentGoal({ monthGoals: monthGoalsResponse })
  }

  const handleDeleteMonthGoal = async (index: number) => {
    await api.axios.delete(
      `/goal/${goal._id}/monthGoal/${monthGoals[index]._id}`
    )
    const newMonthGoal = monthGoals.filter((_, i) => i !== index)
    const formattedMonthGoals = formatMonthGoalsToBackendFormat(newMonthGoal)

    setMonthGoals(newMonthGoal)
    updateCurrentGoal({ monthGoals: formattedMonthGoals })
  }

  const hasMonthGoals = goal.monthGoals.length > 0 || monthGoals.length > 0

  return (
    <>
      <Container>
        <Header>
          <div className='table-header-info'>
            <h1>Metas individuais</h1>
            <p>Defina a meta mensal para cada vendedor</p>
          </div>
          <div className='table-header-action'>
            <button
              data-cy='addGoalButton'
              className='table-header-add-goal-button'
              onClick={() => handleSetIsCreatingGoal(true)}
            >
              Adicionar Meta <AddIcon fontSize='small' />
            </button>
          </div>
        </Header>
        <GoalModal
          goal={goal}
          isCreatingNewGoal={isCreatingNewGoal}
          isEditingGoal={isEditingGoal}
          handleSetIsCreatingGoal={handleSetIsCreatingGoal}
          handleSetIsEditingGoal={handleSetIsEditingGoal}
          createNewGoal={handleCreateNewMonthGoal}
          editGoal={(goal: Omit<IMonthGoal, 'initialCommission' | '_id'>) =>
            handleEditMonthGoal(indexToEdit, goal)
          }
          currentGoal={isEditingGoal ? monthGoals[indexToEdit] : undefined}
          monthGoals={monthGoals}
          usersWorking={usersWorking}
        />
        <Content>
          <Table>
            {hasMonthGoals ? (
              <>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Valor</th>
                    <th>Comissão</th>
                    <th>Bonificação</th>
                    <th>Total</th>
                    <th />
                  </tr>
                </thead>
                <tbody className='table-row-container'>
                  {(goal.monthGoals.length > 0
                    ? goal.monthGoals
                    : monthGoals
                  ).map((goal, index) => (
                    <TableItem
                      key={goal._id}
                      index={index}
                      goal={goal}
                      onDelete={handleDeleteMonthGoal}
                      onEdit={() => {
                        handleSetIsEditingGoal(true)
                        setIndexToEdit(index)
                      }}
                    />
                  ))}
                </tbody>
              </>
            ) : (
              <thead>
                <tr className='seller-without-goal-message'>
                  <th>Adicione pelo menos uma meta para o vendedor.</th>
                </tr>
              </thead>
            )}
          </Table>
        </Content>
      </Container>
    </>
  )
}
