import { useEffect } from 'react'

import { animateScroll as scroll, Link } from 'react-scroll'
import { endOfMonth, getDate, set, startOfMonth } from 'date-fns'
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons'

// Components
import { CardButton } from './components/CardButton'
import { OrLineDivisor } from '../../OrLineDivisor'
import { IGoal } from 'store/modules/goal/types'
import { timeConfiguration } from '../../../timeConfiguration'

// Assets
import { DirectCommission } from '../../../../../../../assets/Icons/Stepper/Commission/DirectCommission'
import { CommissionFloor } from '../../../../../../../assets/Icons/Stepper/Commission/CommissionFloor'
import { MultiplePeriods } from '../../../../../../../assets/Icons/Stepper/Period/MultiplePeriods'
import { SinglePeriod } from '../../../../../../../assets/Icons/Stepper/Period/SinglePeriod'
import { MonthlyResult } from '../../../../../../../assets/Icons/Stepper/CommissionBase/MonthlyResult'
import { PeriodResult } from '../../../../../../../assets/Icons/Stepper/CommissionBase/PeriodResult'
import { FixedGoal } from '../../../../../../../assets/Icons/Stepper/GoalDistribution/FixedGoal'
import { DistributedGoal } from '../../../../../../../assets/Icons/Stepper/GoalDistribution/DistributedGoal'
import { StoreTotal } from '../../../../../../../assets/Icons/Stepper/ReceiveCommission/StoreTotal'
import { IndividualGoal } from '../../../../../../../assets/Icons/Stepper/ReceiveCommission/IndividualGoal'

// Styles
import { CardContent, Container } from './style'
import {
  CardContainer,
  CreateIcon,
  EditButton,
  Element,
  NextStepButton,
  Title
} from '../../../style'

interface CustomIGoal extends Omit<IGoal, 'sections'> {
  sections: {
    id?: string
    start: string
    end: string
  }[]
}

interface CommissionStepProps {
  goal: CustomIGoal
  handleNextStep: () => void
  setDeleteStep: (steps: string[]) => void
  updateGoal: (goalInfo: Partial<CustomIGoal>) => void
}

const CommissionStep = ({
  goal,
  handleNextStep,
  setDeleteStep,
  updateGoal
}: CommissionStepProps) => {
  const isCommissionCardSelected =
    typeof goal.salary?.directCommission === 'boolean'
  const isSectionsCardSelected = goal.sections?.length > 0
  const isSplitCommissionCardSelected =
    typeof goal?.salary?.splitCommission === 'boolean'
  const isDistributeCardSelected = typeof goal.distribute === 'boolean'
  const isStoreCommissionCardSelected =
    typeof goal.config?.commission?.storeSold === 'boolean'

  const areAllCardsSelected =
    isDistributeCardSelected &&
    isSectionsCardSelected &&
    isCommissionCardSelected &&
    isCommissionCardSelected &&
    isSplitCommissionCardSelected

  useEffect(() => {
    // Auto scroll para o botão de proxima etapa se ja tiver tudo
    areAllCardsSelected ? scroll.scrollToBottom() : scroll.scrollToTop()
  }, [])

  const handleSelectPureCommissionCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      salary: { ...goal.salary, directCommission: true }
    })
  }

  const handleSelectSalaryPlusCommissionCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      salary: { ...goal.salary, directCommission: false }
    })
  }

  const handleSelectUniquePeriodCard = () => {
    scroll.scrollToBottom()
    const startGoal = set(
      startOfMonth(new Date(goal?.month)),
      timeConfiguration
    )

    const endGoal = set(endOfMonth(new Date(goal?.month)), timeConfiguration)

    const isoStart = startGoal.toISOString()
    const isoEnd = endGoal.toISOString()

    const newSections = [
      {
        start: isoStart,
        end: isoEnd
      }
    ]

    updateGoal({
      sections: newSections,
      salary: { ...goal.salary, splitCommission: false }
    })
  }

  const handleSelectMultiplePeriodsCard = () => {
    scroll.scrollToBottom()

    const lastDayOfCurrentMonthGoal = getDate(endOfMonth(new Date(goal?.month)))

    const startDates = [1, 9, 16, 23]
    const endDates = [8, 15, 22, lastDayOfCurrentMonthGoal]

    const newSections = startDates.map((date, index) => {
      const startGoal = set(new Date(goal?.month), {
        ...timeConfiguration,
        date
      })

      const endGoal = set(new Date(goal?.month), {
        ...timeConfiguration,
        date: endDates[index]
      })

      const isoStart = startGoal.toISOString()
      const isoEnd = endGoal.toISOString()

      return {
        start: isoStart,
        end: isoEnd
      }
    })

    updateGoal({
      config: {
        ...goal.config
      },
      sections: newSections
    })
  }

  const handleSelectMonthSalesCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      salary: { ...goal.salary, splitCommission: false }
    })
  }

  const handleSelectSalesByPeriodCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      salary: { ...goal.salary, splitCommission: true }
    })
  }

  const handleSelectDoNotRedistributeGoalCard = () => {
    scroll.scrollToBottom()
    updateGoal({ ...goal, distribute: false })
  }

  const handleSelectRedistributeGoalCard = () => {
    scroll.scrollToBottom()
    updateGoal({ ...goal, distribute: true })
  }

  const handleSelectStoreGoalCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      config: {
        ...goal.config,
        commission: { ...goal.config.commission, storeSold: true }
      }
    })
  }

  const handleSelectSellerGoalCard = () => {
    scroll.scrollToBottom()
    updateGoal({
      config: {
        ...goal.config,
        commission: { ...goal.config.commission, storeSold: false }
      }
    })
  }

  const hasMoreThanOnePeriod = goal.sections?.length > 1

  return (
    <Container>
      <Element name='commission'>
        <CardContainer isSelected={isCommissionCardSelected}>
          <Title>Como será a remuneração dos vendedores?</Title>
          <CardContent>
            <Link to='period' offset={-100} smooth={true}>
              <CardButton
                id='pureCommission'
                dataCy='pureCommissionButton'
                isSelected={goal.salary?.directCommission}
                buttonAction={handleSelectPureCommissionCard}
              >
                <DirectCommission />
              </CardButton>
            </Link>

            <OrLineDivisor />

            <Link to='period' offset={-90} smooth={true}>
              <CardButton
                id='salaryPlusCommission'
                dataCy='salaryPlusCommissionButton'
                isSelected={goal.salary?.directCommission === false}
                buttonAction={handleSelectSalaryPlusCommissionCard}
              >
                <CommissionFloor />
              </CardButton>
            </Link>
          </CardContent>
        </CardContainer>
        {isCommissionCardSelected && (
          <Link to='commission' offset={-170} smooth={true}>
            <EditButton
              onClick={() => {
                setDeleteStep(['directCommission'])
              }}
            >
              Alterar
              <CreateIcon />
            </EditButton>
          </Link>
        )}
      </Element>

      {(isCommissionCardSelected || isSectionsCardSelected) && (
        <Element name='period'>
          <CardContainer isSelected={isSectionsCardSelected}>
            <Title>Qual o período da meta?</Title>
            <CardContent>
              <Link to='commission-base' smooth={true} offset={-100}>
                <CardButton
                  id='uniquePeriod'
                  dataCy='uniquePeriodButton'
                  isSelected={goal.sections?.length === 1}
                  buttonAction={handleSelectUniquePeriodCard}
                >
                  <SinglePeriod />
                </CardButton>
              </Link>

              <OrLineDivisor />

              <Link to='commission-base' smooth={true} offset={-100}>
                <CardButton
                  id='multiplePeriods'
                  dataCy='multiplePeriodsButton'
                  isSelected={goal.sections.length > 1}
                  buttonAction={handleSelectMultiplePeriodsCard}
                >
                  <MultiplePeriods />
                </CardButton>
              </Link>
            </CardContent>
          </CardContainer>
          {isSectionsCardSelected && (
            <Link to='period' offset={-170} smooth={true}>
              <EditButton
                onClick={() => {
                  setDeleteStep(['sections', 'days', 'splitCommission'])
                }}
              >
                Alterar
                <CreateIcon />
              </EditButton>
            </Link>
          )}
        </Element>
      )}

      {hasMoreThanOnePeriod && (
        <Element name='commission-base'>
          <CardContainer isSelected={isSplitCommissionCardSelected}>
            <Title>Qual a referência para receber a comissão?</Title>
            <CardContent>
              <Link to='goal-distribution' offset={-100} smooth={true}>
                <CardButton
                  id='monthSales'
                  dataCy='salesByMonthButton'
                  isSelected={goal?.salary?.splitCommission === false}
                  buttonAction={handleSelectMonthSalesCard}
                >
                  <MonthlyResult />
                </CardButton>
              </Link>

              <OrLineDivisor />

              <Link to='goal-distribution' offset={-100} smooth={true}>
                <CardButton
                  id='salesByPeriod'
                  dataCy='salesByPeriodButton'
                  isSelected={goal?.salary?.splitCommission}
                  buttonAction={handleSelectSalesByPeriodCard}
                >
                  <PeriodResult />
                </CardButton>
              </Link>
            </CardContent>
          </CardContainer>
          {isSplitCommissionCardSelected && (
            <Link to='commission-base' offset={-170} smooth={true}>
              <EditButton
                onClick={() => {
                  setDeleteStep(['splitCommission'])
                }}
              >
                Alterar
                <CreateIcon />
              </EditButton>
            </Link>
          )}
        </Element>
      )}

      {(isSplitCommissionCardSelected || isDistributeCardSelected) && (
        <Element name='goal-distribution'>
          <CardContainer isSelected={isDistributeCardSelected}>
            <Title>A meta poderá ser redistribuída para a equipe?</Title>
            <CardContent>
              <Link to='receive-commission' offset={-100} smooth={true}>
                <CardButton
                  id='doNotRedistributeGoal'
                  dataCy='doNotRedistributeGoalButton'
                  isSelected={goal?.distribute === false}
                  buttonAction={handleSelectDoNotRedistributeGoalCard}
                >
                  <FixedGoal />
                </CardButton>
              </Link>

              <OrLineDivisor />

              <Link to='receive-commission' offset={-100} smooth={true}>
                <CardButton
                  id='redistributeGoal'
                  dataCy='redistributeGoalButton'
                  isSelected={goal?.distribute}
                  buttonAction={handleSelectRedistributeGoalCard}
                >
                  <DistributedGoal />
                </CardButton>
              </Link>
            </CardContent>
          </CardContainer>
          {isDistributeCardSelected && (
            <Link to='goal-distribution' offset={-170} smooth={true}>
              <EditButton
                onClick={() => {
                  setDeleteStep(['distribute'])
                }}
              >
                Alterar
                <CreateIcon />
              </EditButton>
            </Link>
          )}
        </Element>
      )}

      {(isDistributeCardSelected || isStoreCommissionCardSelected) && (
        <Element name='receive-commission'>
          <CardContainer isSelected={isStoreCommissionCardSelected}>
            <Title>Qual resultado garante a comissão do vendedor?</Title>
            <CardContent>
              <Link to='next-step' offset={-170} smooth={true}>
                <CardButton
                  id='storeGoal'
                  dataCy='storeGoalButton'
                  isSelected={goal?.config?.commission?.storeSold}
                  buttonAction={handleSelectStoreGoalCard}
                >
                  <StoreTotal />
                </CardButton>
              </Link>

              <OrLineDivisor />

              <Link to='next-step' offset={-170} smooth={true}>
                <CardButton
                  id='sellerGoal'
                  dataCy='individualSellerGoalButton'
                  isSelected={goal?.config?.commission?.storeSold === false}
                  buttonAction={handleSelectSellerGoalCard}
                >
                  <IndividualGoal />
                </CardButton>
              </Link>
            </CardContent>
          </CardContainer>
          {isStoreCommissionCardSelected && (
            <Link to='receive-commission' offset={-170} smooth={true}>
              <EditButton
                onClick={() => {
                  setDeleteStep(['storeSold'])
                }}
              >
                Alterar
                <CreateIcon />
              </EditButton>
            </Link>
          )}
        </Element>
      )}

      {areAllCardsSelected && (
        <Element name='next-step'>
          <NextStepButton onClick={handleNextStep} data-cy='nextStepButton'>
            Próxima etapa
            <ArrowForwardIosIcon />
          </NextStepButton>
        </Element>
      )}
    </Container>
  )
}

export default CommissionStep
