import { Dispatch, SetStateAction, useState } from 'react'

import { Link } from 'react-scroll'
import { Speed as SpeedIcon } from '@material-ui/icons'

import Tooltip from 'components/Tooltip'
import TransferListCard from 'components/UsersGoal/components/TransferListCard'
import { IUserWorking } from 'store/modules/goal/types'
import { ICurrentGoalProps } from '../..'
import { deepClone } from 'utils/deepClone'
import LockArrow from 'assets/Icons/LockArrow'

import {
  CreateIcon,
  EditButton,
  NextStepButton
} from 'views/Goals/components/AddGoal/style'
import { Content } from '../../styles'
import {
  AccWeightCounter,
  Container,
  ContentBox,
  ContentBoxContainer
} from './styles'

interface SelectUsersSectionProps {
  goal: ICurrentGoalProps
  usersWorking: IUserWorking[]
  users: IUserWorking[]
  usersAccWeight: number
  handleSubmitSectionsPicker: () => void
  handleEditSectionsPicker: () => void
  setUsers: Dispatch<SetStateAction<IUserWorking[]>>
  setUsersWorking: Dispatch<SetStateAction<IUserWorking[]>>
}

export const SelectUsersSection = ({
  goal,
  users,
  usersWorking,
  usersAccWeight,
  handleSubmitSectionsPicker,
  handleEditSectionsPicker,
  setUsers,
  setUsersWorking
}: SelectUsersSectionProps) => {
  const [isUsersWeightLocked, setIsUsersWeightLocked] = useState(false) // state para cuidar do botao de reset

  // USERS WORKING
  const handleAddSellerToGoal = (user: IUserWorking) => {
    setUsers(state => state.filter(u => u.userId?._id !== user.userId?._id))

    const updatedUsersWorking = [
      ...usersWorking,
      { ...user, dsr: true, active: true }
    ]
    const distributedUsersWeight = distributeWeights(updatedUsersWorking)
    setUsersWorking(distributedUsersWeight)
  }

  const handleRemoveSellerFromGoal = (user: IUserWorking) => {
    setUsers(state => [
      ...state,
      { ...user, weightLocked: false, active: false }
    ])

    const usersWorkingWithoutCurrentUser = [...usersWorking].filter(
      u => u.userId?._id !== user.userId?._id
    )
    const distributedUsersWeight = distributeWeights(
      usersWorkingWithoutCurrentUser
    )
    setUsersWorking(distributedUsersWeight)
  }

  const handleUpdateUserDSR = (user: IUserWorking, dsr: boolean) => {
    const updatedUsers = [...usersWorking].map(userW => {
      const isUserToBeUpdated = userW.userId?._id === user.userId?._id
      if (isUserToBeUpdated) {
        return {
          ...userW,
          dsr
        }
      }
      return userW
    })
    setUsersWorking(updatedUsers)
  }

  const handleUpdateUserWeight = (
    user: IUserWorking,
    type: string,
    weight: number
  ) => {
    const updatedUsers = [...usersWorking].map(userW => {
      const isUserToBeUpdated = userW.userId?._id === user.userId?._id
      // filtra pelo cara que foi alterado
      if (isUserToBeUpdated) {
        return {
          ...userW,
          goalWeight: +weight,
          weightLocked: type === 'fixed'
        }
      } // muda o peso dele e bloqueia ele para nao ser alterado novamente na redistribuição

      return userW
    })

    const distributedUsers = distributeWeights(updatedUsers)
    // se nenhum vendedor tiver sido alterado ou se ja clicou no cadeado uma vez, bloqueia para poder clicar no cadeado dnv
    if (!isUsersWeightLocked) {
      setIsUsersWeightLocked(true)
    }
    setUsersWorking(distributedUsers)
  }

  const distributeWeights = (users: IUserWorking[]) => {
    const usersResults = users.reduce(
      (acc, user, i) => {
        if (user.active && user.goalWeight > 0) {
          acc.accWeights += 1
          user.weightLocked
            ? (acc.accWeights -= user.goalWeight)
            : (acc.usersUnlocked += 1)
        }
        const isLastUser = i === users.length - 1
        if (isLastUser) {
          acc.accWeights = acc.accWeights / acc.usersUnlocked
        }
        return acc
      },
      { accWeights: 0, usersUnlocked: 0 }
    )

    const usersUpdated: IUserWorking[] = deepClone(users).map(
      (user: IUserWorking) => {
        if (user.active && !user.weightLocked && goal?.distribute) {
          return {
            ...user,
            goalWeight:
              usersResults.accWeights < 0 ? 0 : usersResults.accWeights
          }
        }

        return user
      }
    )

    return usersUpdated
  }

  const handleUnlockWeightUsers = () => {
    setUsersWorking(state =>
      state.map(user => {
        user.weightLocked = false
        user.goalWeight = 1
        return user
      })
    )

    setIsUsersWeightLocked(false)
  }

  const hasAccWeightError =
    usersAccWeight.toFixed(0) !==
      (usersWorking.filter(user => user.goalWeight > 0).length * 100).toFixed(
        0
      ) && goal?.distribute

  const hasAtLeastOneUserWorking =
    Array.isArray(goal.usersWorking) &&
    usersWorking &&
    !(goal.usersWorking.length > 0) &&
    usersWorking.length > 0

  const isUsersAccWeightNotEqualGoalWeightTotal =
    usersAccWeight.toFixed(0) !==
    (usersWorking.filter(user => user.goalWeight > 0).length * 100).toFixed(0)

  const tooltipWeightContent = (
    <p>
      {isUsersAccWeightNotEqualGoalWeightTotal && goal?.distribute
        ? `Ajuste os pesos para totalizar ${(usersWorking.length * 100).toFixed(
            0
          )}%`
        : 'Total de pesos acumulado dos vendedores'}
    </p>
  )

  return (
    <Container>
      <Content isFilled={goal.usersWorking?.length > 0}>
        <h1>Selecione quem irá participar da meta</h1>
        <ContentBoxContainer>
          <ContentBox>
            <div>
              <div>
                <h1>Não participam</h1>
                <span>{users.length}</span>
              </div>
            </div>
            <ul>
              {users.map((user, i) => (
                <TransferListCard
                  key={i}
                  user={user}
                  onClick={() => handleAddSellerToGoal(user)}
                />
              ))}
            </ul>
          </ContentBox>
          <ContentBox>
            <div>
              <div>
                <h1>Participam da meta</h1>
                <span>{usersWorking.length}</span>
              </div>
              <div>
                <AccWeightCounter error={hasAccWeightError}>
                  <div data-tip data-for='total-weight-tooltip'>
                    <Tooltip
                      fixed
                      place='top'
                      id='total-weight-tooltip'
                      content={tooltipWeightContent}
                    />
                    <SpeedIcon fontSize='small' />
                    <h3>{usersAccWeight.toFixed(0)}%</h3>
                  </div>
                  <div data-tip data-for='locker-weight-tooltip'>
                    {isUsersWeightLocked && (
                      <>
                        <Tooltip
                          fixed
                          place='top'
                          id='locker-weight-tooltip'
                          content={
                            <p>
                              Desafixar peso dos vendedores para cálculo de Peso
                              Automático
                            </p>
                          }
                        />
                        <button onClick={handleUnlockWeightUsers}>
                          <LockArrow />
                        </button>
                      </>
                    )}
                  </div>
                </AccWeightCounter>
              </div>
            </div>
            <ul>
              {usersWorking.map((userW, i) => (
                <TransferListCard
                  usersWorking={usersWorking}
                  working
                  key={i}
                  user={userW}
                  inGoal
                  data={goal}
                  updateUserDSR={handleUpdateUserDSR}
                  updateUserWeight={handleUpdateUserWeight}
                  onClick={() => handleRemoveSellerFromGoal(userW)}
                />
              ))}
            </ul>
          </ContentBox>
        </ContentBoxContainer>
      </Content>

      {hasAtLeastOneUserWorking ? (
        <Link to='monthGoals' offset={-170} smooth={true}>
          <NextStepButton
            onClick={handleSubmitSectionsPicker}
            disabled={
              usersWorking.filter(user => user.goalWeight > 0).length !==
                Number(usersAccWeight.toFixed(0)) / 100 && goal?.distribute
            }
            data-cy='pickSellersForGoalNextButton'
          >
            Avançar
          </NextStepButton>
        </Link>
      ) : (
        goal.usersWorking.length > 0 && (
          <EditButton onClick={handleEditSectionsPicker}>
            Alterar
            <CreateIcon />
          </EditButton>
        )
      )}
    </Container>
  )
}
