import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import Tooltip from 'components/Tooltip'
import { IInitialCommissionProps } from '../GoalSection'
import UserDefaultPhoto from '../../../../UserDefaultPhoto'
import { IGoal, IMonthGoal, IUserWorking } from 'store/modules/goal/types'
import { IStore } from 'store/modules/store/types'
import { formatPrice } from 'utils/format'

import {
  Container,
  SellerCard,
  SellerCardGoal,
  SellerCardGoalContainer,
  SellerCardInfo,
  TooltipContent
} from './styles'
import { getMonthGoalTooltipInfos } from 'utils/getMonthGoalTooltipInfos'

interface AccGoalProps {
  seller: IUserWorking
  goals: IMonthGoal[]
}

interface GoalProps {
  goal: IGoal
  store: IStore | null
  accGoal: AccGoalProps[]
  usersCommissionInfo: IInitialCommissionProps[]
}

const MAX_USER_NAME_LENGTH = 16

export const Goal = ({
  goal,
  store,
  accGoal,
  usersCommissionInfo
}: GoalProps) => {
  return (
    accGoal &&
    !!usersCommissionInfo &&
    accGoal.map((user, i) => {
      const currentUser = usersCommissionInfo.find(
        userInfo => userInfo.userId === user.seller.userId?._id
      )

      const sellerName =
        user.seller.userId.name.complete.length > MAX_USER_NAME_LENGTH
          ? user.seller.userId.name.complete.substring(
              0,
              MAX_USER_NAME_LENGTH - 3
            ) + '...'
          : user.seller.userId.name.complete

      const hasDsr = user.seller.dsr && goal.dsr > 0
      const dsrType = hasDsr ? 'Com DSR' : 'Sem DSR'
      const sellerGoalWeight = (user.seller.goalWeight * 100).toFixed(2)

      const isUserOnSomeGoalDay = goal.days?.some(({ users }) => {
        const usersIds = users.map(user => user.userId)
        return usersIds?.includes(user.seller.userId?._id)
      })

      const isUserInStore =
        store && store.users.some(({ _id }) => user.seller.userId?._id === _id)

      const isUserActive = user.seller.active

      return (
        isUserOnSomeGoalDay &&
        isUserInStore &&
        isUserActive && (
          <Container key={user.seller._id}>
            <SellerCard key={user.seller.userId?._id}>
              <SellerCardInfo>
                <div>
                  <h3>{sellerName}</h3>
                  <span>{`Peso ${sellerGoalWeight}% / ${dsrType}`}</span>
                </div>
                {user.seller?.userId?.photo?.url ? (
                  <img
                    style={{ backgroundSize: 'cover' }}
                    src={user.seller.userId.photo.url}
                  />
                ) : (
                  <UserDefaultPhoto
                    username={user.seller.userId.name.first}
                    size={30}
                  />
                )}
              </SellerCardInfo>

              <SellerCardGoalContainer>
                {currentUser?.goals.map((userGoal, index) => {
                  const { sumCommission } = userGoal.initialCommission

                  const monthGoalTooltipInfos = getMonthGoalTooltipInfos(
                    userGoal.initialCommission
                  )

                  return (
                    <SellerCardGoal key={userGoal._id + index}>
                      <h3>{formatPrice(userGoal.value)}</h3>
                      <div>
                        <span>
                          {!!sumCommission && formatPrice(sumCommission)}
                        </span>
                        <ErrorOutlineIcon
                          data-tip
                          data-for={`seller-card-tooltip-${i}-${index}`}
                          style={{
                            fontSize: 16,
                            color: '#BDBDBD',
                            marginLeft: 5
                          }}
                        />
                        <Tooltip
                          id={`seller-card-tooltip-${i}-${index}`}
                          content={
                            <TooltipContent>
                              <strong>{`${user.seller.userId.name.first} (${userGoal.name})`}</strong>
                              <strong>Remuneração prevista:</strong>
                              <span>
                                {Object.entries(monthGoalTooltipInfos).map(
                                  ([key, value]) => (
                                    <p key={key}>
                                      <strong>{key}: </strong> {value}
                                      <br />
                                    </p>
                                  )
                                )}

                                <p>
                                  <strong>Total: </strong>
                                  {formatPrice(sumCommission ?? 0)}
                                </p>
                              </span>
                            </TooltipContent>
                          }
                        />
                      </div>
                    </SellerCardGoal>
                  )
                })}
              </SellerCardGoalContainer>
            </SellerCard>
          </Container>
        )
      )
    })
  )
}
