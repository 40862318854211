import styled, { css } from 'styled-components'
import palette from 'theme/palette'

interface ContentProps {
  isFilled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`

export const Content = styled.div<ContentProps>`
  ${({ isFilled }) => css`
    pointer-events: ${isFilled ? 'none' : 'auto'};
    opacity: ${isFilled ? 0.3 : 1};
  `}
`

export const DeleteAlertModal = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  width: 400px;
  border-radius: 8px;
  color: #4f4f4f;
  border: 1px solid #999999;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 11px;
      font-style: italic;
      color: #b3b3b3;
      font-family: 'Roboto', sans-serif;
    }

    button {
      cursor: pointer;
      padding: 10px;
      background-color: ${palette.primary.main};
      border: none;
      border-radius: 5px;
      color: white;
      font-weight: 600;
      font-family: 'Roboto', sans-serif;
    }
  }
`
