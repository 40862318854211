import { Dispatch, SetStateAction } from 'react'

import { Info } from '@material-ui/icons'
import { endOfMonth, getDate } from 'date-fns'

import { ISection } from 'store/modules/goal/types'
import { ICurrentGoalProps, SectionList } from '../../../..'
import {
  FooterContainer,
  SectionsPickerInfo,
  SubmitSectionButton
} from './styles'

interface ModalFooterProps {
  goal: ICurrentGoalProps
  sections: ISection[]
  sectionsList: SectionList[]
  setIsCreatingSectionModalOpen: Dispatch<SetStateAction<boolean>>
  setSectionsOpened: Dispatch<SetStateAction<boolean>>
  updateGoal: (goal: any) => void
}

export const ModalFooter = ({
  goal,
  sections,
  sectionsList,
  setIsCreatingSectionModalOpen,
  setSectionsOpened,
  updateGoal
}: ModalFooterProps): JSX.Element => {
  const onSubmit = () => {
    setIsCreatingSectionModalOpen(false)
    setSectionsOpened(true)
    updateGoal({ sections, sectionsList })
  }

  const hasLessThanTwoSections = sections.length < 2
  const lastSection = sections[sections.length - 1]
  const lastSectionHasNoEnd = !lastSection?.end
  const lastSectionEndDate = getDate(new Date(lastSection?.end || 31))
  const goalMonthEndDate = getDate(endOfMonth(new Date(goal.month)))
  const isLastSectionInvalid = lastSectionEndDate < goalMonthEndDate

  const isDisabled =
    hasLessThanTwoSections || lastSectionHasNoEnd || isLastSectionInvalid

  return (
    <FooterContainer>
      {sections.length > 0 && (
        <>
          <SectionsPickerInfo>
            <Info />
            <span>
              Para editar os períodos no calendário, delete (clicando na lixeira
              de cada período) os períodos já existentes na tabela!
            </span>
          </SectionsPickerInfo>

          <div data-tip data-for='section-submit-tooltip'>
            <SubmitSectionButton onClick={onSubmit} disabled={isDisabled}>
              Salvar
            </SubmitSectionButton>
          </div>
        </>
      )}
    </FooterContainer>
  )
}
