import { ChangeEvent, useEffect, useState } from 'react'

import { DefaultRootState, useSelector } from 'react-redux'
import { animateScroll as scroll, Link } from 'react-scroll'
import { getDate, endOfMonth, getDay, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { eachWeekendOfMonth } from 'date-fns/esm'
import { TextField } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { OrLineDivisor } from '../../OrLineDivisor'
import { InputCard } from './components/InputCard'
import GoalIndicatorCard from '../../GoalIndicatorCard'
import { CardCheck } from './components/CardCheck'
import { IGoal, IGoalProps, IMainGoal } from 'store/modules/goal/types'
import { safeDivision } from 'utils/format'
import palette from 'theme/palette'
import WandIcon from '../../../../../../../assets/Icons/WandIcon'

import {
  CardContainer,
  CreateIcon,
  EditButton,
  Element,
  NextStepButton,
  Title
} from '../../../style'
import {
  CardContent,
  Container,
  PriceInput,
  PriceOverview,
  PriceStep,
  PriceStepInfo
} from './style'

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface ValueStepStateProps {
  base: number | null
  dsr: number
  total: number | null
  ticket: number | null
  price: number | null
  complement: boolean
}

interface ValueStepProps {
  goal: IGoal
  handleNextStep: () => void
  updateGoal: (goal: Partial<IGoal>) => void
  setDeleteStep: (steps: string[]) => void
  storedMainGoals: IMainGoal
}

export const ValueStep = ({
  goal,
  handleNextStep,
  updateGoal,
  setDeleteStep,
  storedMainGoals
}: ValueStepProps) => {
  const { month, dsr, mainGoals, salary } = goal

  const getSundaysInMonth = () => {
    // Pega quantidade de domingos para definir DSR
    const weekends = eachWeekendOfMonth(new Date(month))

    return weekends.reduce((acc, day) => {
      if (getDay(day) === 0) acc++
      return acc
    }, 0)
  }

  const [valueStep, setValueStep] = useState<ValueStepStateProps>({
    base: goal?.salary?.base || null,
    dsr: typeof goal?.dsr === 'number' ? goal.dsr : getSundaysInMonth(),
    total: mainGoals.total || null,
    ticket: mainGoals.ticket || null,
    price: mainGoals.price || null,
    complement: salary?.complement || true
  })

  const { goalList } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )

  const daysInMonth = getDate(endOfMonth(new Date(month)))
  const hasSalaryBase = !!salary?.base
  const hasDirectCommission = salary?.directCommission

  useEffect(() => {
    const areFieldsFilled = salary.base && dsr
    areFieldsFilled ? scroll.scrollToBottom() : scroll.scrollToTop()
  }, [])

  useEffect(() => {
    const lastGoalWithSameMonthActive = goalList.find(
      goal => goal.config.active && goal.month === month
    )

    const lastGoalActive = goalList.find(goal => goal.config.active)

    const setNewValueStep = (prevGoal: IGoal) => {
      const valuesStepProp = {
        base: goal?.salary?.base || prevGoal.salary?.base,
        total: goal?.mainGoals?.total || prevGoal.mainGoals?.total,
        ticket: goal?.mainGoals?.ticket || prevGoal.mainGoals?.ticket,
        price: goal?.mainGoals?.price || prevGoal.mainGoals?.price
      }

      setValueStep(state => ({ ...state, ...valuesStepProp }))
    }

    const hasPreviousGoals = goalList.length > 0
    // Pega o salario da ultima meta criada
    if (hasPreviousGoals) {
      if (lastGoalWithSameMonthActive) {
        setNewValueStep(lastGoalWithSameMonthActive)
      } else if (lastGoalActive) {
        setNewValueStep(lastGoalActive)
      }
    }
  }, [goalList])

  const storedTotalGoal = () => {
    // Caso o valor da meta seja igual ao valor anterior, não irá recalcular o calendário
    if (
      storedMainGoals.total !== valueStep.total &&
      Array.isArray(goal['days'])
    ) {
      goal['days'] = []
    }
    const { total, ticket, price } = valueStep

    if (total && ticket && price) {
      updateGoal({ mainGoals: { total, ticket, price } })
    }

    scroll.scrollToBottom()
  }

  const handleChangeDsrInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const dsrInputValue = event.target.value
    const isDsrBiggerThanDaysInMonth = Number(event.target.value) > daysInMonth
    const dsr = isDsrBiggerThanDaysInMonth
      ? daysInMonth - 1
      : parseInt(dsrInputValue)

    setValueStep({ ...valueStep, dsr })
  }

  const handleSubmitRemunerationInfo = () => {
    const base = valueStep.base ? valueStep.base : 0
    const complement = hasDirectCommission ? valueStep.complement : false

    updateGoal({
      salary: { ...salary, base, complement },
      dsr: valueStep.dsr ? Math.floor(valueStep.dsr) : 0
    })

    scroll.scrollToBottom()
  }

  const allSales = Math.ceil(
    safeDivision((valueStep.total ?? 0) / (valueStep.ticket ?? 0))
  )

  const itemsPerSale = Number(
    safeDivision((valueStep.ticket ?? 0) / (valueStep.price ?? 0)).toFixed(2)
  )

  const allItems = Math.ceil(
    safeDivision((valueStep.total ?? 0) / (valueStep.price ?? 0))
  )

  const hasFilledAllGoalInputs = Boolean(
    valueStep.total && valueStep.price && valueStep.ticket
  )

  const hasFilledAllMainGoalsInputs = Boolean(
    mainGoals?.total && mainGoals?.price && mainGoals?.ticket
  )

  const hasFilledAllInputs = Boolean(
    hasFilledAllMainGoalsInputs && hasSalaryBase
  )

  return (
    <Container>
      <Element name='remuneration'>
        <CardContainer isSelected={hasSalaryBase}>
          <Title>Informação sobre remuneração</Title>

          <CardContent>
            <InputCard
              id='wageFloor'
              hasError={valueStep.base ? valueStep.base <= 0 : false}
              dataCy={'wageFloorInput'}
              value={valueStep.base}
              onChange={(_, value) =>
                setValueStep({ ...valueStep, base: Number(value) })
              }
            />
            <CardCheck
              id='dsrCheckbox'
              inputDataCy='dsrCheckbox'
              setIsSelected={() =>
                setValueStep({ ...valueStep, dsr: valueStep.dsr === 0 ? 1 : 0 })
              }
              isSelected={valueStep.dsr > 0}
            >
              <TextField
                error={valueStep.dsr < 0}
                data-cy='dsrInput'
                value={valueStep.dsr || ''}
                disabled={valueStep.dsr === 0}
                label={'Número de dias inclusos'}
                onChange={handleChangeDsrInput}
                variant='outlined'
                fullWidth
              />
            </CardCheck>

            {hasDirectCommission && (
              <CardCheck
                id='salaryComplementCheckbox'
                inputDataCy='salaryComplementCheckbox'
                setIsSelected={() =>
                  setValueStep({
                    ...valueStep,
                    complement: !valueStep.complement
                  })
                }
                isSelected={salary?.complement || valueStep.complement}
              />
            )}
          </CardContent>

          <Link to='goal-value' offset={-150} smooth={true}>
            {valueStep.base && valueStep.base > 0 && !(salary?.base > 0) && (
              <NextStepButton
                data-cy='remunerationInfoNextButton'
                onClick={handleSubmitRemunerationInfo}
                disabled={!valueStep.base}
                className='stepper-button-next'
              >
                Avançar
              </NextStepButton>
            )}
          </Link>
        </CardContainer>

        <Link to='remuneration' offset={-170} smooth={true}>
          {hasSalaryBase && (
            <EditButton
              onClick={() => {
                setDeleteStep(['base', 'complement'])
              }}
            >
              Alterar
              <CreateIcon />
            </EditButton>
          )}
        </Link>
      </Element>

      {(hasSalaryBase || Boolean(mainGoals?.total)) && (
        <Element name='goal-value'>
          <PriceStep>
            <CardContainer isSelected={!!mainGoals?.total}>
              <h1>
                Quais são as metas da loja para{' '}
                {month
                  ? format(new Date(month), 'MMMM', { locale: ptBR })
                  : '...'}
                ?
              </h1>

              <PriceStepInfo>
                <PriceInput>
                  <InputCard
                    id='salesValue'
                    hasError={valueStep.total ? valueStep.total <= 0 : false}
                    value={valueStep.total}
                    onChange={(_, value) =>
                      setValueStep({ ...valueStep, total: Number(value) })
                    }
                    dataCy='salesValueInput'
                  />
                  <InputCard
                    id='averageTMValue'
                    hasError={valueStep.ticket ? valueStep.ticket <= 0 : false}
                    value={valueStep.ticket}
                    onChange={(_, value) =>
                      setValueStep({ ...valueStep, ticket: Number(value) })
                    }
                    dataCy='averageTicketValueInput'
                  />
                  <InputCard
                    id='averagePMValue'
                    hasError={valueStep.price ? valueStep.price <= 0 : false}
                    value={valueStep.price}
                    onChange={(_, value) =>
                      setValueStep({ ...valueStep, price: Number(value) })
                    }
                    dataCy='averagePriceValueInput'
                  />
                </PriceInput>

                <OrLineDivisor>
                  <WandIcon color={palette.primary.main} />
                </OrLineDivisor>

                <PriceOverview>
                  <span>Como alcançar a meta:</span>
                  <div>
                    <GoalIndicatorCard
                      cardTitle='Total de vendas'
                      tooltipId='goals-price-overview-tv'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={allSales}
                    />
                    <GoalIndicatorCard
                      cardTitle='Peças por venda'
                      tooltipId='goals-price-overview-pv'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={itemsPerSale}
                    />
                    <GoalIndicatorCard
                      cardTitle='Total de peças'
                      tooltipId='goals-price-overview-tp'
                      tooltipContent={<p>Indicador estimado para sua meta</p>}
                      cardValue={allItems}
                    />
                  </div>
                </PriceOverview>
              </PriceStepInfo>

              <Link to='next-step-button' smooth={true} offset={-200}>
                {!mainGoals?.total && hasFilledAllGoalInputs && (
                  <NextStepButton
                    data-cy='goalValuesNextButton'
                    style={{ margin: '40px auto 20px' }}
                    onClick={storedTotalGoal}
                    disabled={!hasFilledAllGoalInputs}
                  >
                    Avançar
                  </NextStepButton>
                )}
              </Link>
            </CardContainer>

            {hasFilledAllMainGoalsInputs && (
              <Link to='goal-value' offset={-170} smooth={true}>
                <EditButton
                  onClick={() => {
                    setDeleteStep(['total', 'ticket', 'price'])
                  }}
                >
                  Alterar
                  <CreateIcon />
                </EditButton>
              </Link>
            )}
          </PriceStep>
        </Element>
      )}

      {hasFilledAllInputs && (
        <Element name='next-step-button'>
          <NextStepButton onClick={handleNextStep} data-cy='nextStepButton'>
            Próxima etapa
            <ArrowForwardIosIcon className='next-button-icon' />
          </NextStepButton>
        </Element>
      )}
    </Container>
  )
}
