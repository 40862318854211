import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PriceStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  > div {
    > h1 {
      color: #263238;
      font-size: 22px;
      margin-bottom: 24px;
    }
  }
`

export const PriceStepInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`

export const PriceInput = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 22px;
  width: 60%;
`

export const PriceOverview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    margin-bottom: 24px;
    font-weight: 600;
    color: #263238;
    text-align: start;
  }

  > div {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 20px;
  }
`
