import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'

import Tooltip from 'components/Tooltip'
import { IGoal } from 'store/modules/goal/types'
import { Table } from 'views/Goal/styles'
import { formatPrice, formatToPercentage } from 'utils/format'
import { ErrorOutlineIcon, TableCell, GoalNumber } from './styles'
import { TooltipMonthGoalInfos } from 'components/TooltipMonthGoalInfos'

interface GoalsProps {
  goal: IGoal | undefined
}

export default function Goals({ goal }: GoalsProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Comissão</TableCell>
            <TableCell>Bônus</TableCell>
            <TableCell>Remuneração Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {goal &&
            goal.monthGoals.map((monthGoal, index) => {
              const isTheFirstMonthGoal = index === 0
              const goalNumber = index + 1

              return (
                <TableRow key={monthGoal._id} hover>
                  <TableCell>
                    {isTheFirstMonthGoal && (
                      <Tooltip
                        id={`current-goal-type-${monthGoal._id}`}
                        content={<p>Principal</p>}
                        place='bottom'
                      />
                    )}

                    <GoalNumber
                      data-tip
                      data-for={`current-goal-type-${monthGoal._id}`}
                      isTheFirstMonthGoal={isTheFirstMonthGoal}
                    >
                      {goalNumber}
                    </GoalNumber>
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    <b>{monthGoal.name}</b>
                  </TableCell>
                  <TableCell>{formatPrice(monthGoal.value)}</TableCell>
                  <TableCell>
                    {formatToPercentage(monthGoal.commission * 100)}
                  </TableCell>
                  <TableCell>
                    {monthGoal.bonusPercentage
                      ? formatToPercentage(monthGoal.bonus * 100)
                      : formatPrice(monthGoal.bonus)}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      id={`current-goal-remuneration-${monthGoal._id}`}
                      content={<TooltipMonthGoalInfos monthGoal={monthGoal} />}
                      place='bottom'
                    />

                    {formatPrice(
                      monthGoal.initialCommission.sumCommission ?? 0
                    )}

                    <ErrorOutlineIcon
                      data-tip
                      data-for={`current-goal-remuneration-${monthGoal._id}`}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
