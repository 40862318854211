import styled, { css } from 'styled-components'

interface AccWeightCounterProps {
  error: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentBox = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      align-items: center;
    }

    h1 {
      font-size: 16px;
      color: #56676f;
    }

    span {
      background-color: #eeeeee;
      color: #65747b;
      border-radius: 5px;
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      padding: 4px 7px;
    }
  }

  ul {
    list-style: none;
    border: 2px solid #eeeeee;
    max-width: 400px;
    width: 38vw;
    height: 250px;
    padding: 0;
    border-radius: 8px;
    background: #fff;
    display: flex;
    overflow-y: scroll;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    margin: 5px 0;

    -ms-overflow-style: none;
    scrollbar-width: 4px;
    scrollbar-color: #b5b5b5;

    > p {
      color: #888;
      font-size: 12px;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #b5b5b5;
      border-radius: 50px;
    }

    li + li {
      margin-top: 13px;
    }
  }
`

export const AccWeightCounter = styled.div<AccWeightCounterProps>`
  background: #f5f6f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #27ae60;
  padding: 4px 8px;
  border-radius: 30px;
  margin: 0px 6px;
  transition: 0.3s ease-in;

  ${({ error }) =>
    error &&
    css`
      color: red;
    `}

  div {
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-left: 2px;
      cursor: default;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2e4e9;
    border: none;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
    margin-left: 16px;
    width: 20px;
    height: 20px;
    transform: scale(1.2);
  }
`
