import { ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material'
import { CheckBoxRounded as CheckBoxRoundedIcon } from '@mui/icons-material'

import { SmallTitle } from './styles'

interface ItemProps {
  title: string
  text: string | number
}

export const Item = ({ text, title }: ItemProps) => {
  return (
    <ListItem alignItems='flex-start'>
      <ListItemIcon>
        <CheckBoxRoundedIcon />
      </ListItemIcon>
      <ListItemText
        secondary={
          <>
            <SmallTitle>{title}</SmallTitle>
            <Typography
              sx={{ fontSize: 16, fontWeight: 500 }}
              component='span'
              variant='body2'
              color='text.primary'
            >
              {text}
            </Typography>
          </>
        }
      />
    </ListItem>
  )
}
