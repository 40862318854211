import { ReactNode } from 'react'

import { Line } from './styles'

interface OrLineDivisorProps {
  children?: ReactNode
}

export const OrLineDivisor = ({ children }: OrLineDivisorProps) => {
  return (
    <Line>
      <div>{children ? children : <span>OU</span>}</div>
    </Line>
  )
}
