import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'

import { ReviewCard } from '../ReviewCard'
import { ReviewItem } from '../ReviewItem'
import { IGoal } from 'store/modules/goal/types'

interface MonthSectionProps {
  goal: IGoal
  setActiveStep: () => void
}

export const MonthSection = ({ goal, setActiveStep }: MonthSectionProps) => {
  return (
    <ReviewCard
      name='Mês'
      items={
        <ReviewItem
          title='Mês da meta'
          value={format(new Date(goal.month), 'MMMM/yyyy', { locale: ptBR })}
        />
      }
      setActiveStep={setActiveStep}
    />
  )
}
