import { formatPrice } from './format'
import { IInitialCommission } from 'store/modules/goal/types'

export const getMonthGoalTooltipInfos = (
  initialCommission: IInitialCommission
) => {
  const { complement, dsr, salary, bonus, fixedCommission, commission } =
    initialCommission

  const hasFixedCommission = !!fixedCommission?.value
  const hasCommission = !!commission?.value
  const hasDsr = !!dsr
  const hasSalary = !!salary
  const hasComplement = !!complement
  const hasBonus = !!bonus?.value

  const monthGoalTooltipInfos = {
    ...(hasFixedCommission && {
      'Comissão base': formatPrice(fixedCommission.value)
    }),
    ...(hasCommission && {
      Comissão: formatPrice(commission.value)
    }),
    ...(hasDsr && { DSR: formatPrice(dsr) }),
    ...(hasSalary && { Piso: formatPrice(salary) }),
    ...(hasComplement && {
      Complemento: formatPrice(complement)
    }),
    ...(hasBonus && { Bônus: formatPrice(bonus.value) })
  }

  return monthGoalTooltipInfos
}
