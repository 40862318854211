import styled from 'styled-components'
import palette from 'theme/palette'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;

  .calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
    }

    .calendar-month {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
      width: 70vw;
      margin: auto;
      position: relative;
    }

    .calendar-total {
      text-align: center;

      .weeks-cards {
        width: 150px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);

        .total-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fafafa;
          height: 75px;
          min-width: 100px;
          padding: 7px 20px;
          border-bottom: 2px solid #eeeeee;

          > div {
            display: flex;
            align-items: center;
          }

          span {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: #9e9e9e;
          }

          h4 {
            color: #9e9e9e;
            font-size: 12px;
            margin-top: 5px;
            font-weight: 700;
          }
        }
      }

      .days-cards {
        width: 70vw;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(7, 1fr);

        .total-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fafafa;
          height: 70px;
          border-right: 2px solid #eeeeee;

          span {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: #9e9e9e;
          }

          h4 {
            color: #9e9e9e;
            font-size: 12px;
            margin-top: 5px;
            font-weight: 700;
          }
        }
      }

      .total {
        width: 150px;

        .total-card {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          background-color: #fafafa;
          height: 75px;
          padding: 7px 10px;

          span {
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: 600;
            color: #9e9e9e;
          }

          .total-progress-container {
            display: flex;
            position: relative;
            width: 100%;
            margin: 5px auto;

            .bar {
              height: 6px;
              width: 100%;
              background-color: #e0e0e0;
              position: relative;
              border-radius: 20px;
              overflow: hidden;

              .progress {
                position: absolute;
                left: 0;
                height: 6px;
                background-color: ${palette.primary.main};
                border-radius: 20px;
              }
            }
          }
        }
      }
    }

    .editing-modal-backdrop {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 3;
    }
  }
`
