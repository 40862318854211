import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'

import { ISection } from 'store/modules/goal/types'
import { Table } from 'views/Goal/styles'
import { SectionRow } from './components/SectionRow'

interface SectionProps {
  sections: ISection[]
}

export const Sections = ({ sections }: SectionProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Data inicial</TableCell>
            <TableCell>Data final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sections.map(row => (
            <SectionRow section={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
