import styled from 'styled-components'
import { ArrowRight } from '@material-ui/icons'

interface CardValueProps {
  textColor?: string
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
`

export const LeftCard = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
    margin-top: 5px;
    font-size: 12px;
  }
`

export const ChildrenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const CardTitle = styled.span`
  color: #9e9e9e;
  font-size: 16px;
`

export const ArrowRightIcon = styled(ArrowRight)`
  color: #bdbdbd;
`

export const CardValue = styled.span<CardValueProps>`
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 3px;
  color: ${({ textColor }) => (textColor ? textColor : '#455A64')};
`

export const CardSubValue = styled.span`
  background-color: #e9e9e9;
  font-size: 12px;
  padding: 3px 5px;
  color: #78909c;
  font-weight: 600;
  border-radius: 5px;
  margin-left: 8px;
`
