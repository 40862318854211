import styled from 'styled-components'
import palette from 'theme/palette'

interface ButtonContainerProps {
  isSelected: boolean
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border: 2px solid #c4c4c4;
  border-radius: 8px;
  width: 300px;
  height: 320px;
  transition: border 0.2s;
  cursor: pointer;
  padding: 25px;
  font-family: 'Roboto', sans-serif;

  border-color: ${({ isSelected }) =>
    isSelected ? palette.primary.main : 'none'};

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    border: 2px solid #1e2fa9;
  }

  &:hover:disabled {
    border-color: #c4c4c4;
  }

  > p {
    font-size: 12px;
    color: #1e2fa9;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 14px;
    color: #37474f;
  }

  p {
    font-size: 12px;
    color: #546e7a;
    margin: 10px auto;
  }

  strong {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f2e4;
    color: #bfa182;
    font-size: 12px;
    width: 77px;
    padding: 5px;
    border-radius: 5px;
  }
`
