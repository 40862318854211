import { Dispatch, SetStateAction } from 'react'
import { Close, Container, Content } from './styles'

interface ModalHeaderProps {
  setIsCreatingSectionModalOpen: Dispatch<SetStateAction<boolean>>
}

export const ModalHeader = ({
  setIsCreatingSectionModalOpen
}: ModalHeaderProps): JSX.Element => {
  return (
    <Container className='top'>
      <Content className='title'>
        <h1>Períodos da meta</h1>
        <span>Adicione e distribua os períodos da meta.</span>
      </Content>
      <Close
        className='close-button'
        onClick={() => setIsCreatingSectionModalOpen(false)}
      />
    </Container>
  )
}
