import styled from 'styled-components'

import { ErrorOutline } from '@material-ui/icons'
import palette from 'theme/palette'

interface GoalIconProps {
  isFirstGoal: boolean
}

export const Container = styled.tr`
  background: #f5f6f8;
  border-radius: 10px;
  padding: 5px 15px;
  border-top: 10px solid white;

  td {
    padding: 10px 0 10px 10px;
    font-size: 12px;
    color: #263238;
    text-align: center;
  }

  button:nth-child(1) {
    margin-right: 8px;
  }

  td:nth-child(2) {
    text-align: left;
  }

  td.commission-cell {
    color: ${palette.primary.main};
    font-weight: bold;
  }

  button {
    border: 0;
    background-color: #e2e4e9;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      color: #37474f;
      font-size: 16px;
    }
  }
`

export const GoalPercentageCommission = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #546e7a;
    font-size: 18px;
    margin-left: 4px;
  }
`

export const GoalIcon = styled.div<GoalIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #e2e4e9;
  border-radius: 24px;
  border: ${({ isFirstGoal }) => isFirstGoal && '2px solid #FFC700'};

  span {
    font-size: 12px;
    font-weight: bold;
    color: #65747b;
  }
`

export const TooltipContent = styled.div`
  font-weight: normal;

  > strong {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }

  p:not(:last-child) {
    margin-bottom: 2px;
  }

  p:last-child {
    margin-top: 10px;
  }
`

export const ErrorOutlineIcon = styled(ErrorOutline)`
  color: #c4c4c4;
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: -5px;
`

export const TableRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  button:first-child:hover {
    background-color: #37474f;

    svg {
      color: white;
    }
  }

  button:last-child:hover {
    background-color: #e64c3d;

    svg {
      color: white;
    }
  }
`

export const PromptModalSpanContainer = styled.span`
  font-size: 18px;
  text-align: center;
  margin-top: -10px;

  > strong {
    text-decoration: underline;
  }
`
export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
`
