import { IGoal } from 'store/modules/goal/types'
import { ReviewCard } from '../ReviewCard'
import { ReviewItem } from '../ReviewItem'
import { formatPrice } from 'utils/format'

interface ValuesSectionProps {
  goal: IGoal
  setActiveStep: () => void
}

export const ValuesSection = ({ goal, setActiveStep }: ValuesSectionProps) => {
  const dsr = goal.dsr === 0 ? 'Inativo' : goal.dsr
  const minimumWage = formatPrice(goal.salary.base)

  const hasComplement = goal.salary.complement

  const storeGoal = formatPrice(goal.mainGoals.total)
  const averageTicketGoal = formatPrice(goal.mainGoals.ticket)
  const averagePriceGoal = formatPrice(goal.mainGoals.price)

  return (
    <ReviewCard
      name='Valores'
      items={
        <>
          <ReviewItem title='DSR' value={dsr} />
          <ReviewItem title='Piso Salarial' value={minimumWage} />
          {hasComplement && (
            <ReviewItem title='Complemento do salário' value='Ativo' />
          )}
          <ReviewItem
            title='Meta da loja'
            value={storeGoal}
            textColor='#27AE60'
          />
          <ReviewItem
            title='Meta de ticket médio'
            value={averageTicketGoal}
            textColor='#27AE60'
          />
          <ReviewItem
            title='Meta de preço médio'
            value={averagePriceGoal}
            textColor='#27AE60'
          />
        </>
      }
      setActiveStep={setActiveStep}
    />
  )
}
