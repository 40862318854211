import { useEffect, useState, useRef } from 'react'

import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import {
  getDate,
  getDay,
  getWeeksInMonth,
  isPast,
  startOfMonth
} from 'date-fns'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { TopTableDays } from './components/TopTableDays'
import Tooltip from 'components/Tooltip'
import CardDay from './components/CardDay'
import { ICurrentGoalProps } from '../..'
import {
  addDisabledDay,
  setDisabledDays
} from 'store/modules/calendarGoal/actions'
import {
  IDays,
  IDaysUsers,
  IDaysWithDayOff,
  IUserWorking,
  UserWorkingWithDayOff
} from 'store/modules/goal/types'
import { ICalendarGoalProps } from 'store/modules/calendarGoal/types'
import { IDwt } from '../CalendarSection'
import { formatPrice } from 'utils/format'
import { deepClone } from 'utils/deepClone'

import { Container } from './styles'

type UserWorkingAmount = {
  daysWorkingAmount: number
}

export type UsersDaysWorkingAmount = {
  [userId: string]: UserWorkingAmount
}

interface SelectorCalendarGoal {
  state: DefaultRootState
  calendarGoal: ICalendarGoalProps
}

interface CalendarProps {
  goal: ICurrentGoalProps
  dwt: IDwt
  setDwt: (dwt: IDwt) => void
  days: IDays[]
  setDays: (days: IDays[]) => void
  weekStartIndex: number
  month: Date
  mainGoalValue: number
  setTotalDaysValue: (daysValue: number) => void
  updateGoal: (goal: Partial<ICurrentGoalProps>) => void
  suggestion: boolean
  isCalendarLocked: boolean
  usersWorking: IUserWorking[]
}

export const Calendar = ({
  goal,
  dwt,
  setDwt,
  days,
  setDays,
  weekStartIndex,
  month,
  setTotalDaysValue,
  mainGoalValue,
  updateGoal,
  suggestion,
  isCalendarLocked,
  usersWorking
}: CalendarProps) => {
  const { disabledDays, days: initialDaysValue } = useSelector<
    SelectorCalendarGoal,
    ICalendarGoalProps
  >(store => store.calendarGoal)

  const dispatch = useDispatch()
  const [editableDays, setEditableDays] = useState<IDays[]>([])
  const [daysIndexWithFixedGoal, setDaysIndexWithFixedGoal] = useState<
    number[]
  >(() => {
    if (days.length > 0) {
      const lockedDaysIndex: number[] = []

      days.forEach((day, index) => {
        if (day.goalLocked) {
          lockedDaysIndex.push(index)
        }
      })

      return lockedDaysIndex
    }
    return []
  })

  const [isEditing, setIsEditing] = useState(-1)
  const [progressBar, setProgressBar] = useState(0)

  const nextInputRef = useRef<HTMLInputElement | null>(null)
  let newDisabledDays = [...disabledDays]

  useEffect(() => {
    setProgressBar(dwt.total / mainGoalValue)
    setTotalDaysValue(Number(dwt.total.toFixed(2)))
  }, [dwt.total])

  const generateDwt = (days: IDays[]) => {
    const generatedDwt = days.reduce(
      (acc, day, index) => {
        const weekStartIndex = getDay(startOfMonth(new Date(goal.month)))
        const i = weekStartIndex + (getDate(new Date(day.date)) - 1)
        const weekIndex = Math.floor(i / 7)
        acc.weeks[weekIndex] += day.goal
        acc.days[i - weekIndex * 7] += day.goal

        const disabledDaysContainsCurrentDay = newDisabledDays.includes(index)
        if (!disabledDaysContainsCurrentDay) {
          acc.total += day.goal
        }
        return acc
      },
      {
        weeks: new Array(getWeeksInMonth(new Date(goal.month))).fill(0),
        days: [0, 0, 0, 0, 0, 0, 0],
        total: 0
      }
    )

    setDwt(generatedDwt)
  }

  useEffect(() => {
    const hasNoEditableDays = !(editableDays?.length > 0)
    if (hasNoEditableDays && days.length > 0) {
      setEditableDays(deepClone(days))
    }
  }, [editableDays?.length, days])

  const newDate = new Date()
  const currentMonth = newDate.getMonth()
  const currentYear = newDate.getFullYear()

  const calendarMonth = month.getMonth()
  const calendarYear = month.getFullYear()
  const daysInCalendarMonth = new Date(
    calendarYear,
    calendarMonth + 1,
    0
  ).getDate()

  const today =
    calendarYear > currentYear
      ? 0
      : currentMonth === calendarMonth
      ? newDate.getDate()
      : currentMonth > calendarMonth
      ? daysInCalendarMonth
      : 0

  const oldDays: IDays[] = deepClone(days)

  const setWorkDay = (i: number) => (isWorking: boolean) => {
    if (suggestion) {
      if (!isWorking) {
        dispatch(addDisabledDay(i))
        newDisabledDays.push(i)
      } else if (newDisabledDays.includes(i)) {
        const dayIndex = newDisabledDays.indexOf(i)
        newDisabledDays.splice(dayIndex, 1)
        dispatch(setDisabledDays(newDisabledDays))
      }

      const newDaysIndexWithFixedGoal = [...daysIndexWithFixedGoal]

      if (newDaysIndexWithFixedGoal.includes(i)) {
        const dayIndex = newDaysIndexWithFixedGoal.indexOf(i)
        newDaysIndexWithFixedGoal.splice(dayIndex, 1)
        setDaysIndexWithFixedGoal(newDaysIndexWithFixedGoal)
      }

      const isPreviousDay = isPast(
        new Date(oldDays[i].date).setUTCHours(0, 0, 0, 0)
      )

      const disabledDaysWorking: number[] = []
      oldDays.forEach((day, index) => {
        // verifica se o dia nao esta sendo trabalhado
        // depois compara se o dia atual eh diferente do dia clicado
        // caso seja igual o dia clicado ele verifica se esta sendo ativado ou desativado
        const isWorkingDay = day.working
        const isDifferentDay = index !== i

        if (!isWorkingDay && (isDifferentDay || !isWorking)) {
          disabledDaysWorking.push(index)
        } else if (isWorkingDay && !isDifferentDay && !isWorking) {
          disabledDaysWorking.push(index)
        }
      })

      // verifica se esta alterando um calendário com dias previamente desabitados
      if (disabledDaysWorking.length && !disabledDays.length) {
        dispatch(setDisabledDays(disabledDaysWorking))
        newDisabledDays = disabledDaysWorking
      }

      const weights = calculateWeights(newDaysIndexWithFixedGoal, isWorking)

      const fixedDaysGoalValue = newDaysIndexWithFixedGoal.reduce(
        (acc, curr) => {
          return (acc += days[curr].goal)
        },
        0
      )

      const mainGoalValueWithoutFixedDaysGoal =
        mainGoalValue - fixedDaysGoalValue

      const calculateNewGoal = (index: number) => {
        return (
          ((goal.daysWeightSuggestion[index] +
            (goal.daysWeightSuggestion[index] * weights.notSelectedDaysWeight) /
              (weights.totalWeight -
                weights.disabledDaysWeight -
                weights.notSelectedDaysWeight)) *
            (mainGoalValueWithoutFixedDaysGoal - weights.disabledDaysValue)) /
          (weights.totalWeight - weights.disabledDaysWeight)
        )
      }

      const newDaysList: IDays[] = oldDays.map((day, index) => {
        const isWorkingDay = day.working
        const isEditingDay = index === i
        const isNotDisabledDay = !newDisabledDays.includes(index)
        const isADayWithFixedGoal = newDaysIndexWithFixedGoal.includes(index)
        const isNotALockedDay = index > (isCalendarLocked ? today - 1 : -1)

        if (
          isWorkingDay &&
          !isEditingDay &&
          isNotDisabledDay &&
          !isADayWithFixedGoal &&
          isNotALockedDay
        ) {
          return { ...day, goal: calculateNewGoal(index) }
        } else if (isEditingDay) {
          let goal = 0

          if (isPreviousDay && isCalendarLocked) {
            goal = isWorking ? initialDaysValue[i].goal : 0
          } else if (isWorking) {
            goal = calculateNewGoal(index)
          }
          if (day.goalLocked) {
            delete day.goalLocked
          }
          return { ...day, working: isWorking, goal }
        } else if (isADayWithFixedGoal) {
          return day
        } else if (isNotALockedDay) {
          return { ...day, goal: 0 }
        }
        return day
      })

      setDays(newDaysList)
      setEditableDays(newDaysList)
      generateDwt(newDaysList)
      updateGoal({ lastDaysBackup: newDaysList })
    } else {
      const newGoal = isWorking
        ? Number((mainGoalValue / days.length).toFixed(2))
        : 0

      oldDays[i].working = isWorking
      oldDays[i].goal = newGoal

      setDays(oldDays)
      setEditableDays(oldDays)
      generateDwt(oldDays)
    }
  }

  const handleEditUsersWorking = (updatedDay: IDays, date: Date) => {
    const formattedDays = editableDays.map(day => {
      const isSameMonth = new Date(day.date).getMonth() === date.getMonth()
      const isSameDay = new Date(day.date).getDate() === date.getDate()

      if (isSameMonth && isSameDay) {
        return updatedDay
      }
      return day
    })

    setDays(formattedDays)
    updateGoal({ lastDaysBackup: formattedDays })
  }

  const calculateWeights = (
    newDaysIndexWithFixedGoal: number[],
    isWorking = false
  ) => {
    const weights = oldDays.reduce(
      (acc, day, index) => {
        const isNotDayWithFixedGoal = !newDaysIndexWithFixedGoal.includes(index)
        if (isNotDayWithFixedGoal) {
          acc.totalWeight += goal.daysWeightSuggestion[index]

          const isDisabledDay = newDisabledDays.includes(index)
          const isWorkingDay = day.working || isWorking
          const isDayNotLocked = !(index > (isCalendarLocked ? today - 1 : -1))

          if (isDisabledDay) {
            acc.notSelectedDaysWeight += goal.daysWeightSuggestion[index]
          } else if (isWorkingDay && isDayNotLocked) {
            acc.disabledDaysWeight += goal.daysWeightSuggestion[index]
            acc.disabledDaysValue += initialDaysValue[index].goal
          }
        }
        return acc
      },
      {
        notSelectedDaysWeight: 0,
        disabledDaysWeight: 0,
        disabledDaysValue: 0,
        totalWeight: 0
      }
    )

    return weights
  }

  const setEditableUsersWorkingOnDay =
    (i: number) => (sellers: IDaysUsers[]) => {
      const newDays: IDays[] = editableDays.map((day, index) => {
        if (index === i) {
          return {
            ...day,
            users: sellers
          }
        }
        return day
      })

      setEditableDays(newDays)
    }

  const setValue = (i: number) => (e: { target: { inputValue: number } }) => {
    //Não recalcular se valor não mudar
    const oldDays: IDays[] = deepClone(days)
    const oldGoalValue = oldDays[i].goal
    const inputGoalValue = e.target.inputValue ?? 0

    const newGoalValueIsDifferentFromPrevious = inputGoalValue !== oldGoalValue

    if (newGoalValueIsDifferentFromPrevious) {
      const newDaysIndexWithFixedGoal = daysIndexWithFixedGoal.includes(i)
        ? daysIndexWithFixedGoal
        : [...daysIndexWithFixedGoal, i]

      if (suggestion) {
        const disabledDaysWorking: number[] = []
        oldDays.forEach((day, index) => {
          // verifica se o dia nao esta sendo trabalhado
          if (!day.working) {
            disabledDaysWorking.push(index)
          }
        })

        // verifica se esta alterando um calendário com dias previamente desabitados
        if (disabledDaysWorking.length && !disabledDays.length) {
          dispatch(setDisabledDays(disabledDaysWorking))
          newDisabledDays = disabledDaysWorking
        }

        const weights = calculateWeights(newDaysIndexWithFixedGoal)

        const fixedDaysGoalValue = newDaysIndexWithFixedGoal.reduce(
          (acc, curr) => {
            const isEditingDay = curr === i
            if (isEditingDay) {
              return (acc += inputGoalValue)
            }

            return (acc += oldDays[curr].goal)
          },
          0
        )
        const mainGoalValueWithoutFixedDaysGoal =
          mainGoalValue - fixedDaysGoalValue

        const calculateNewGoal = (index: number) => {
          return (
            ((goal.daysWeightSuggestion[index] +
              (goal.daysWeightSuggestion[index] *
                weights.notSelectedDaysWeight) /
                (weights.totalWeight -
                  weights.disabledDaysWeight -
                  weights.notSelectedDaysWeight)) *
              (mainGoalValueWithoutFixedDaysGoal - weights.disabledDaysValue)) /
            (weights.totalWeight - weights.disabledDaysWeight)
          )
        }

        const newDaysList: IDays[] = oldDays.map((day, index) => {
          const isWorkingDay = day.working
          const isEditingDay = index === i
          const isNotDisabledDay = !newDisabledDays.includes(index)
          const isADayWithFixedGoal = newDaysIndexWithFixedGoal.includes(index)
          const isNotALockedDay = index > (isCalendarLocked ? today - 1 : -1)

          if (
            isWorkingDay &&
            !isEditingDay &&
            isNotDisabledDay &&
            !isADayWithFixedGoal &&
            isNotALockedDay
          ) {
            return { ...day, goal: calculateNewGoal(index) }
          } else if (isEditingDay) {
            return {
              ...day,
              goal: inputGoalValue,
              ...(newDaysIndexWithFixedGoal.includes(i) && { goalLocked: true })
            }
          } else if (isADayWithFixedGoal) {
            return { ...day, goal: oldDays[index].goal, goalLocked: true }
          } else if (isNotALockedDay) {
            return { ...day, goal: 0 }
          }
          return day
        })

        setDays(newDaysList)
        setEditableDays(newDaysList)
        generateDwt(newDaysList)

        if (!daysIndexWithFixedGoal.includes(i)) {
          setDaysIndexWithFixedGoal(prev => [...prev, i])
        }
      } else {
        oldDays[i].goal = inputGoalValue

        const dwtWeek = [...dwt.weeks]
        const dwtDays = [...dwt.days]

        const weekIndex = Math.floor((i + weekStartIndex) / 7)

        dwtWeek[weekIndex] += inputGoalValue - oldGoalValue
        dwtDays[i + weekStartIndex - weekIndex * 7] +=
          inputGoalValue - oldGoalValue

        setDwt({
          ...dwt,
          weeks: dwtWeek,
          days: dwtDays,
          total: dwt.total + inputGoalValue - oldGoalValue
        })
        setEditableDays(oldDays)
        setDays(oldDays)
      }
    }
  }

  const getDayOutMonth = (day: number) => {
    const dayMS = 24 * 60 * 60 * 1000 * day
    const date = new Date(month)
    date.setTime(date.getTime() - dayMS)

    return date
  }

  const usersDaysWorkingAmount = editableDays?.reduce((acc, day) => {
    day.users.forEach(user => {
      if (acc[user.userId]) {
        acc[user.userId].daysWorkingAmount += 1
      } else {
        acc[user.userId] = {
          daysWorkingAmount: 1
        }
      }
    })
    return acc
  }, {} as UsersDaysWorkingAmount)

  const changeDayUsersType = (day: IDays): IDaysWithDayOff => {
    const usersWorkingWithDayOff: UserWorkingWithDayOff[] = []

    usersWorking.forEach(userW => {
      const userDayOffData = day.users.find(
        usr => usr.userId === userW.userId._id
      )

      if (userDayOffData) {
        const newItem = {
          ...userDayOffData,
          ...userW,
          dayOff: userDayOffData.dayOff
        }
        usersWorkingWithDayOff.push(newItem)
      } else {
        const missingUser = { dayOff: { enable: false }, notWorking: true }
        usersWorkingWithDayOff.push({ ...missingUser, ...userW })
      }
    })

    return { ...day, users: usersWorkingWithDayOff }
  }

  const weekDaysPtBr = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado'
  ]

  const isDwtTotalLessThanMainGoalValue =
    Number(dwt.total.toFixed(2)) < Number(mainGoalValue.toFixed(2))

  const isDwtTotalDifferentFromMainGoalValue =
    Number((dwt.total || 0).toFixed(2)) !==
    Number((mainGoalValue || 0).toFixed(2))

  const dwtTotalColor =
    Number((dwt.total || 0).toFixed(2)) !==
    Number((mainGoalValue || 0).toFixed(2))
      ? 'red'
      : '#27AE60'

  const remainingValue = formatPrice(
    isDwtTotalLessThanMainGoalValue
      ? Number(mainGoalValue.toFixed(2)) - Number(dwt.total.toFixed(2))
      : Number(dwt.total.toFixed(2)) - Number(mainGoalValue.toFixed(2))
  )

  const daysInMonth = new Array(7 * getWeeksInMonth(month)).fill(null)

  return (
    <Container>
      <div className='calendar'>
        <div>
          <div>
            <TopTableDays topTable={weekDaysPtBr} />
            <div className='calendar-month' data-clarity-unmask='true'>
              {days.length > 0 &&
                daysInMonth.map((_, i) => {
                  const monthIndex = i - weekStartIndex
                  // Índice do próximo dia util é o dia util mais próximo do dia que está sendo editado
                  // Primeiro verifica-se se está em modo de edição, então é feito um splice pra economizar
                  // o loop e busca-se o primeiro dia util, onde ao adicionar o índice do dia sendo editado é igual ao índice no mês
                  const nextWorkingDayIndex =
                    isEditing > 0
                      ? isEditing +
                        days
                          .slice(isEditing - weekStartIndex + 1)
                          .findIndex(day => day.working) +
                        1
                      : 1

                  const isADayFromLastMonth = i < weekStartIndex
                  const isADayFromNextMonth = monthIndex >= days.length

                  if (isADayFromLastMonth || isADayFromNextMonth) {
                    const date = getDayOutMonth(weekStartIndex - i)
                    return (
                      <CardDay
                        data={goal}
                        key={i}
                        date={date}
                        index={i}
                        disabled
                      />
                    )
                  } else {
                    const handleNext = () => {
                      //Mandar para o próximo dia útil
                      if (nextWorkingDayIndex > isEditing) {
                        nextInputRef.current?.focus()
                        setIsEditing(nextWorkingDayIndex)
                      } else {
                        setIsEditing(-1)
                      }
                    }

                    const isLocked =
                      suggestion &&
                      isCalendarLocked &&
                      i < today + weekStartIndex

                    const editableDay =
                      editableDays.length > 0
                        ? changeDayUsersType(editableDays[monthIndex])
                        : undefined

                    return (
                      <CardDay
                        key={i}
                        ref={
                          nextWorkingDayIndex === i ? nextInputRef : undefined
                        }
                        data={goal}
                        day={changeDayUsersType(days[monthIndex])}
                        date={new Date(days[monthIndex].date)}
                        setWorkDay={setWorkDay(monthIndex)}
                        setValue={setValue(monthIndex)}
                        handleNext={handleNext}
                        locked={isLocked}
                        index={i}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        suggestion={suggestion}
                        usersWorking={usersWorking}
                        updateGoal={updateGoal}
                        editableDay={editableDay}
                        editUsersWorking={handleEditUsersWorking}
                        setEditableUsersOnDays={setEditableUsersWorkingOnDay(
                          monthIndex
                        )}
                        usersDaysWorkingAmount={usersDaysWorkingAmount}
                      />
                    )
                  }
                })}
            </div>
          </div>
          <div className='calendar-total'>
            <TopTableDays topTable={['Total']} />
            <div className='weeks-cards'>
              {dwt.weeks?.map((week, i) => (
                <div key={i} className='total-card'>
                  <span>{formatPrice(week)}</span>
                  <h4>{Math.ceil((week * 100) / mainGoalValue)}%</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div style={{ width: '70vw' }} className='calendar-total'>
            <div className='days-cards'>
              {dwt.days?.map((day, i) => (
                <div key={i} className='total-card'>
                  <span>{formatPrice(day)}</span>
                  <h4>{Math.ceil((day * 100) / mainGoalValue)}%</h4>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{ ...(isEditing > 0 && { zIndex: 5 }) }}
            className='calendar-total'
          >
            <div className='total'>
              <div className='total-card'>
                <div>
                  <span>Soma dos dias</span>
                  <span data-tip data-for='tooltip-sum-days'>
                    {isDwtTotalDifferentFromMainGoalValue && (
                      <ErrorOutlineIcon
                        fontSize='small'
                        style={{ color: '#9E9E9E', marginLeft: 5 }}
                      />
                    )}
                  </span>
                  <Tooltip
                    place='top'
                    content={
                      <p>
                        Iguale a soma dos dias com {'\n'}a meta principal da
                        loja ({formatPrice(mainGoalValue)})
                      </p>
                    }
                    id='tooltip-sum-days'
                  />
                </div>
                <span style={{ color: dwtTotalColor, fontSize: 14 }}>
                  {formatPrice(dwt.total)}
                </span>
                <div className='total-progress-container'>
                  <div className='bar'>
                    <div
                      className='progress'
                      style={{ width: `${progressBar * 100}%` }}
                    />
                  </div>
                </div>
                {isDwtTotalLessThanMainGoalValue ? (
                  <span style={{ marginLeft: 'auto' }}>
                    Faltam {remainingValue}
                  </span>
                ) : (
                  isDwtTotalDifferentFromMainGoalValue && (
                    <span style={{ marginLeft: 'auto' }}>
                      Retire {remainingValue}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
