import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SellerCard = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 5px;
`

export const SellerCardInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  background-color: #f0f2f4;
  padding: 10px;
  margin-bottom: 5px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    text-align: right;

    h3 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: 600;
      color: #424242;
      font-size: 14px;
    }

    span {
      color: #757575;
      font-size: 11px;
    }
  }

  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
`

export const SellerCardGoalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
`

export const SellerCardGoal = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-bottom: 6px solid #f0f2f4;
  padding: 10px;

  & {
    border-radius: 0px 0px 8px 8px;
  }

  h3 {
    color: #424242;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      color: #757575;
      font-size: 12px;
    }
  }
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    display: block;
    margin-top: 6px;
    margin-bottom: 4px;
    font-size: 14px;
  }

  span {
    p {
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 2px;
      line-height: 1.5;

      &:last-child {
        margin-top: 10px;
        font-size: 1em;
      }
    }
  }
`
