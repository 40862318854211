import styled from 'styled-components'
import { Delete as DeleteMUI } from '@material-ui/icons'

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 150px 80px 30px;
  align-items: center;
  text-align: center;
  background-color: #f5f6f8;
  padding: 5px;
  margin: 5px auto;
  color: #65747b;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
`

export const Index = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 5px;
  background-color: #e2e4e9;
  font-size: 12px;
  font-weight: 600;
`

export const GoalValue = styled.span``

export const DeleteButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  padding: 5px;
  background-color: #e2e4e9;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #e64c3d;

    svg {
      color: white;
    }
  }
`

export const Delete = styled(DeleteMUI)`
  font-size: 17px;
  color: red;
  transition: 0.2s ease-in-out;
`
