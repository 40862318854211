import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
`

export const Main = styled.main`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    color: #9e9e9e;
  }
`
