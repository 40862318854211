import styled from 'styled-components'
import { Close as CloseMUI } from '@material-ui/icons'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 20px;
    margin-bottom: 10px;
  }

  span {
    font-size: 12px;
    color: #546e7a;
  }
`

export const Close = styled(CloseMUI)`
  color: lightgray;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`
