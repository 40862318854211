import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  h1 {
    color: #263238;
    font-size: 22px;
    margin-bottom: 24px;
  }
`

export const LoadingGoalContainer = styled.div`
  span {
    font-size: 22px;
    margin-left: 10px;
  }
`
