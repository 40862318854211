import { useEffect, useState, FocusEvent, ChangeEvent } from 'react'

import { DefaultRootState, useSelector } from 'react-redux'
import { getDaysInMonth } from 'date-fns/esm'
import { MenuItem, Select, Modal, Backdrop } from '@material-ui/core'
import {
  Close,
  Info,
  Storefront as StorefrontIcon,
  Person as PersonIcon
} from '@material-ui/icons'

import CurrencyTextField from '../../../../../../../../../../../components/CurrencyTextField'
import { NextStepButton } from 'views/Goals/components/AddGoal/style'
import {
  IGoal,
  IGoalProps,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'
import { formatPrice } from '../../../../../../../../../../../utils/format'

import { Container, CustomSelect } from './styles'

interface GoalModalProps {
  goal: IGoal
  usersWorking: IUserWorking[]
  isCreatingNewGoal: boolean
  isEditingGoal: boolean
  currentGoal: IMonthGoal | undefined
  monthGoals: IMonthGoal[]
  handleSetIsCreatingGoal: (value: boolean) => void
  handleSetIsEditingGoal: (value: boolean) => void
  createNewGoal: (goal: Omit<IMonthGoal, 'initialCommission' | '_id'>) => void
  editGoal: (goal: Omit<IMonthGoal, 'initialCommission' | '_id'>) => void
}

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}
interface SellerGoalProps {
  name: string
  value: number
  bonusPercentage: boolean | 'no-bonus'
  commission: number | string
  bonus: number | string
  type: 'store' | 'user'
  typeBonus: 'store' | 'user'
}

export const GoalModal = ({
  goal,
  usersWorking,
  isCreatingNewGoal,
  isEditingGoal,
  currentGoal,
  monthGoals,
  handleSetIsCreatingGoal,
  handleSetIsEditingGoal,
  createNewGoal,
  editGoal
}: GoalModalProps) => {
  const [sellerGoal, setSellerGoal] = useState<SellerGoalProps>({
    // Estado para controlar input de dados
    name: '1a meta',
    value: 0,
    bonusPercentage: false,
    commission: 1,
    bonus: 0,
    type: goal.config?.commission?.storeSold ? 'store' : 'user',
    typeBonus: 'user'
  })

  const [totalCommission, setTotalCommission] = useState(0)
  const [isBonusEmpty, setIsBonusEmpty] = useState(true)

  // Lista das metas para realizar o calculo da porcentagem
  const { goalList } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )

  const bonusValueWithNoCommas = Number(
    String(sellerGoal.bonus).replace(',', '.')
  )
  const commissionValueWithNoCommas = Number(
    String(sellerGoal.commission).replace(',', '.')
  )

  const isBonusPercentageABooleanType =
    typeof sellerGoal.bonusPercentage === 'boolean'
  useEffect(() => {
    const hasUsersWorking = usersWorking?.length > 0
    const hasNoMonthGoals = monthGoals?.length === 0

    if (hasUsersWorking && hasNoMonthGoals) {
      setSellerGoal({
        ...sellerGoal,
        name: '1a meta',
        value: goal.mainGoals.total / usersWorking.length
      })
    }
  }, [monthGoals, usersWorking])

  useEffect(() => {
    // Sempre que algo for re-preenchido, ele recalcula para mostrar em tempo real
    const salary = goal.salary.directCommission ? 0 : goal.salary.base
    const commission = sellerGoal.value * (commissionValueWithNoCommas / 100)
    const bonus =
      sellerGoal.bonusPercentage === true
        ? Number(((bonusValueWithNoCommas / 100) * sellerGoal.value).toFixed(2))
        : bonusValueWithNoCommas

    const daysAvailableInTheCurrentGoalMonth = getDaysInMonth(
      new Date(goal.month)
    )

    const dsr =
      goal.dsr > 0
        ? (commission / (daysAvailableInTheCurrentGoalMonth - goal.dsr)) *
          goal.dsr
        : 0

    const complementValue = goal.salary.base - commission - dsr
    const positiveComplement = complementValue >= 0 ? complementValue : 0
    const complement =
      goal.salary.complement && goal.salary.directCommission
        ? positiveComplement
        : 0

    const newTotalCommission =
      salary +
      bonus +
      (commissionValueWithNoCommas / 100) * sellerGoal.value +
      dsr +
      complement

    setTotalCommission(newTotalCommission)
  }, [sellerGoal, goal])

  useEffect(() => {
    // UseEffect que ve se o cara ta editando a meta e modifica os valores % para virarem inteiros novamente
    if (currentGoal && isEditingGoal) {
      const bonus = Number(
        (currentGoal.bonus * (currentGoal.bonusPercentage ? 100 : 1)).toFixed(2)
      )
      const bonusPercentage =
        currentGoal.bonus === 0 ? 'no-bonus' : currentGoal.bonusPercentage

      setSellerGoal({
        ...currentGoal,
        bonus,
        commission: currentGoal.commission * 100,
        bonusPercentage
      })

      const currentGoalHasNoBonus = currentGoal.bonus > 0
      if (currentGoalHasNoBonus) {
        setIsBonusEmpty(false)
      }
    }
  }, [currentGoal, isEditingGoal])

  useEffect(() => {
    // UseEffect que monitora a criação de metas p fazer as próximas virem preenchidas com os dados da anterior + alguns %

    const hasMonthGoal = monthGoals.length > 0
    if (hasMonthGoal) {
      const lastActiveGoal = goalList.find(
        goal => goal.config.active && goal.monthGoals.length > 1
      )

      let goalValueMultiplier: number | undefined = undefined

      const lastActiveGoalHasMoreThanTwoMonthGoals =
        lastActiveGoal && lastActiveGoal.monthGoals.length > 1

      if (lastActiveGoalHasMoreThanTwoMonthGoals) {
        const lasGoalValue =
          lastActiveGoal?.monthGoals[lastActiveGoal.monthGoals.length - 1].value

        const secondToLastGoalValue =
          lastActiveGoal?.monthGoals[lastActiveGoal.monthGoals.length - 2].value

        goalValueMultiplier = lasGoalValue / secondToLastGoalValue
      }

      const lastGoal = monthGoals[monthGoals.length - 1]

      const bonus = Number(
        (lastGoal.bonus * (lastGoal.bonusPercentage ? 100 : 1)).toFixed(2)
      )
      const bonusPercentage =
        lastGoal.bonus === 0 ? 'no-bonus' : lastGoal.bonusPercentage

      setSellerGoal({
        name: `${monthGoals.length + 1}a meta`,
        value: (goalValueMultiplier || 1.1) * lastGoal.value,
        commission: lastGoal.commission * 100,
        bonus: bonus,
        bonusPercentage: bonusPercentage,
        type: lastGoal.type,
        typeBonus: lastGoal.typeBonus
      })
    }
  }, [monthGoals, isCreatingNewGoal, goalList])

  const handleCloseModal = () => {
    handleSetIsCreatingGoal(false)
    handleSetIsEditingGoal(false)
  }

  const handleChangeGoalValue = (
    _: ChangeEvent<HTMLInputElement>,
    value: number
  ) => {
    setSellerGoal({ ...sellerGoal, value: value })
  }

  const isInvalidNumber = (inputValue: string) => {
    const inputValueWithNoCommas = Number(inputValue.replace(',', '.'))

    return Number(inputValue) < 0 || isNaN(inputValueWithNoCommas)
  }

  const handleChangeSellerCommissionPercentage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newCommissionValue = isInvalidNumber(event.target.value)
      ? sellerGoal.commission
      : event.target.value

    setSellerGoal({
      ...sellerGoal,
      commission: Number(newCommissionValue) > 100 ? 100 : newCommissionValue
    })
  }

  const handleChangeFixedBonusPercentageValue = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newBonusValue = isInvalidNumber(event.target.value)
      ? sellerGoal.bonus
      : event.target.value

    return setSellerGoal({
      ...sellerGoal,
      bonus: Number(newBonusValue) > 100 ? 100 : newBonusValue
    })
  }

  const handleChangeBonusType = (
    event: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const value = event?.target.value as 'no-bonus' | 'true' | 'false'

    setSellerGoal({
      ...sellerGoal,
      bonusPercentage: value === 'no-bonus' ? 'no-bonus' : value === 'true',
      bonus: 0
    })

    setIsBonusEmpty(false)
  }

  const handleSubmit = () => {
    if (isCreatingNewGoal) {
      const newGoal = {
        ...sellerGoal,
        value: Number(sellerGoal.value.toFixed(2)),
        commission: commissionValueWithNoCommas / 100,
        bonusPercentage:
          isBonusPercentageABooleanType && !sellerGoal.bonus
            ? 'no-bonus'
            : sellerGoal.bonusPercentage,
        bonus: sellerGoal.bonusPercentage
          ? bonusValueWithNoCommas / 100
          : bonusValueWithNoCommas,
        typeBonus: sellerGoal.typeBonus || 'user'
      }

      createNewGoal(newGoal)
    } else if (isEditingGoal) {
      const goalType = sellerGoal.type
        ? sellerGoal.type
        : goal.config.commission.storeSold
        ? 'store'
        : 'user'

      const newGoal = {
        name: sellerGoal.name || 'Meta sem nome',
        value: sellerGoal.value || 0,
        commission: sellerGoal.commission
          ? commissionValueWithNoCommas / 100
          : 0,
        type: goalType,
        bonusPercentage:
          isBonusPercentageABooleanType && !sellerGoal.bonus
            ? 'no-bonus'
            : sellerGoal.bonusPercentage,
        bonus: sellerGoal.bonusPercentage
          ? bonusValueWithNoCommas / 100
          : bonusValueWithNoCommas
          ? bonusValueWithNoCommas
          : 0,
        typeBonus: sellerGoal.typeBonus || 'user'
      }

      editGoal(newGoal)
    }

    handleCloseModal()
  }

  const notEveryFieldIsFilled =
    !sellerGoal.value ||
    (isCreatingNewGoal &&
      isBonusPercentageABooleanType &&
      Number(sellerGoal.bonus) < 0) ||
    !sellerGoal.commission ||
    !sellerGoal.name ||
    !sellerGoal.type ||
    (sellerGoal.bonusPercentage === true &&
      Number(sellerGoal.bonus) < 0 &&
      !sellerGoal.typeBonus)

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        open={isCreatingNewGoal || isEditingGoal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Container>
          <div data-clarity-unmask='true' className='goal-modal-content'>
            <div className='close' onClick={handleCloseModal}>
              <Close />
            </div>
            <div className='goal-modal-header'>
              <div className='goal-modal-title'>
                {isCreatingNewGoal ? (
                  <h1> Nova meta para o vendedor </h1>
                ) : (
                  <h1> Editando meta para o vendedor </h1>
                )}
                <span>
                  Indique o valor da meta, valor e referência da comissão e
                  bonificação.
                </span>
              </div>
            </div>

            <div className='goal-modal-form'>
              <div className='goal-modal-form-row'>
                <div>
                  Para a meta
                  <input
                    value={sellerGoal.name ?? ''}
                    placeholder='1a meta'
                    data-cy='goalNameInputModal'
                    style={{ width: 150 }}
                    onChange={e =>
                      setSellerGoal({ ...sellerGoal, name: e.target.value })
                    }
                  />
                  , quando o vendedor vender
                  <CurrencyTextField
                    error={sellerGoal.value < 0}
                    fullWidth
                    onFocus={(event: FocusEvent<HTMLInputElement>) =>
                      event.target.select()
                    }
                    value={sellerGoal.value ?? ''}
                    currencySymbol='R$'
                    decimalCharacter=','
                    placeholder='20000'
                    pattern='[0-9]+'
                    digitGroupSeparator='.'
                    inputProps={{
                      style: { textAlign: 'left' },
                      'data-cy': 'sellerGoalInputModal'
                    }}
                    onChange={handleChangeGoalValue}
                  />
                </div>

                <div className='goal-modal-form-row'>
                  receberá
                  <span>
                    <input
                      type='text'
                      pattern='[0-9]+'
                      maxLength={5}
                      value={sellerGoal.commission ?? ''}
                      style={{ width: 80, marginRight: 0 }}
                      onChange={handleChangeSellerCommissionPercentage}
                      placeholder='0.6'
                      data-cy='sellerCommissionPercentageInputModal'
                    />
                    <span className='percentage'>%</span>
                  </span>
                  de <strong>comissão</strong> de quanto
                  <Select
                    inputProps={{
                      'data-cy': 'selectEntityInputModal'
                    }}
                    value={sellerGoal.type ?? ''}
                    onChange={e => {
                      const value = e.target.value as 'user' | 'store'
                      setSellerGoal({ ...sellerGoal, type: value })
                    }}
                  >
                    <MenuItem value='store'>
                      <Option store />
                    </MenuItem>
                    <MenuItem value='user'>
                      <Option />
                    </MenuItem>
                  </Select>
                  vender
                </div>

                <div className='goal-modal-form-row'>
                  e {isBonusPercentageABooleanType && 'terá '}
                  {!sellerGoal.bonusPercentage && (
                    <span>
                      de <strong>bônus</strong>
                    </span>
                  )}
                  {!isBonusEmpty &&
                    (sellerGoal.bonusPercentage === true ? (
                      <span className='bonus-input-percent'>
                        <input
                          data-cy='fixedBonusPercentValueInputModal'
                          type='text'
                          pattern='[0-9]+'
                          maxLength={5}
                          placeholder='0.6'
                          value={sellerGoal.bonus ?? ''}
                          style={{ width: 80, marginRight: 0 }}
                          onChange={handleChangeFixedBonusPercentageValue}
                        />
                        <span className='suffix'>%</span>
                      </span>
                    ) : (
                      sellerGoal.bonusPercentage === false && (
                        <CurrencyTextField
                          error={Number(sellerGoal.bonus) < 0}
                          fullWidth
                          value={sellerGoal.bonus ?? ''}
                          onFocus={(event: FocusEvent<HTMLInputElement>) =>
                            event.target.select()
                          }
                          currencySymbol='R$'
                          decimalCharacter=','
                          digitGroupSeparator='.'
                          placeholder='2000'
                          inputProps={{
                            style: { textAlign: 'left' },
                            'data-cy': 'fixedBonusCashValueInputModal'
                          }}
                          onChange={(
                            _: ChangeEvent<HTMLInputElement>,
                            value: number
                          ) => setSellerGoal({ ...sellerGoal, bonus: value })}
                        />
                      )
                    ))}
                  <CustomSelect
                    labelId='goal-bonus-label'
                    id='goal-bonus'
                    label='de bonificação'
                    inputProps={{
                      'data-cy': 'selectBonusInputModal'
                    }}
                    className='bonus-select'
                    value={String(sellerGoal.bonusPercentage) ?? ''}
                    $hasNoBonus={!isBonusPercentageABooleanType}
                    hasBonusPercentage={sellerGoal.bonusPercentage}
                    onChange={handleChangeBonusType}
                  >
                    <MenuItem id='bonus-zero' value='no-bonus'>
                      <h5 style={{ fontSize: 14 }}>Sem Bonificação</h5>
                    </MenuItem>
                    <MenuItem id='bonus-percent' value='true'>
                      <h4>% - Porcentagem</h4>
                    </MenuItem>
                    <MenuItem id='bonus-value' value='false'>
                      <h4>R$ - Valor Fechado</h4>
                    </MenuItem>
                  </CustomSelect>
                  {sellerGoal.bonusPercentage === true && ( // ta === true pq se for sem bonificacao ele recebe uma string e passa caso tenha algo
                    <>
                      de <strong>bônus</strong> de quanto
                      <Select
                        value={sellerGoal.typeBonus ?? ''}
                        onChange={event => {
                          const value = event.target.value as 'store' | 'user'
                          setSellerGoal({
                            ...sellerGoal,
                            typeBonus: value
                          })
                        }}
                      >
                        <MenuItem id='bonus-store' value='store'>
                          <Option store />
                        </MenuItem>
                        <MenuItem id='bonus-user' value='user'>
                          <Option />
                        </MenuItem>
                      </Select>
                      vender
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='goal-modal-footer'>
              <div className='sellers-count-card'>
                <Info />
                <div>
                  <span>
                    Essa é uma previsão estimada! (aconselhamos você consultar o
                    valor de comissão individual na etapa de revisão)
                  </span>
                </div>
              </div>
              <div className='goal-infos'>
                <div>
                  <span className='total-commission'>
                    {formatPrice(totalCommission)}
                  </span>
                  <span>{`${
                    !goal.salary.directCommission && 'Piso salarial + '
                  } Comissão + Bonificação ${goal.dsr > 0 && ' + DSR'}`}</span>
                </div>
                <NextStepButton
                  data-cy='sellerGoalModalButton'
                  disabled={notEveryFieldIsFilled}
                  onClick={handleSubmit}
                >
                  {isCreatingNewGoal ? 'Adicionar' : 'Editar'}
                </NextStepButton>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </>
  )
}

const Option = ({ store = false }) => {
  return (
    <>
      {store ? <StorefrontIcon /> : <PersonIcon fontSize='small' />}
      <span style={{ marginLeft: 5, fontSize: 16 }}>
        {store ? 'a loja' : 'o vendedor'}
      </span>
    </>
  )
}
