import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useParams, useHistory } from 'react-router'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'
import { endOfMonth, set, startOfMonth } from 'date-fns'

import { monthRequest } from '../../../../../../../store/modules/report/actions'
import { inputListRequest } from '../../../../../../../store/modules/input/actions'
import { clearCalendarGoalRedux } from 'store/modules/calendarGoal/actions'
import { goalAddSuccess, goalListRequest } from 'store/modules/goal/actions'
import CreateGoalIcon from 'assets/Icons/CreateGoal'

import { timeConfiguration } from '../../../timeConfiguration'
import { MonthSection } from './components/MonthSection'
import { CommissionSection } from './components/CommissionSection'
import { ValuesSection } from './components/ValuesSection'

import { Container } from './styles'
import { GoalSection } from './components/GoalSection'
import { NextStepButton } from '../../../style'
import { IGoal } from 'store/modules/goal/types'
import { IMonthReport, IReportProps } from 'store/modules/report/types'
import { IStoreProps } from 'store/modules/store/types'

interface ParamsProps {
  storeId: string
  goalId: string
}

interface SelectorReport {
  state: DefaultRootState
  report: IReportProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface ReviewStepProps {
  goal: IGoal
  setActiveStep: Dispatch<SetStateAction<number>>
  updateGoal: (goal: Partial<IGoal>) => void
  storeGoal: (goal: IGoal) => void
}

const MONTH_SECTION_INDEX = 0
const COMMISSION_SECTION_INDEX = 1
const VALUES_SECTION_INDEX = 2
const GOAL_SECTION_INDEX = 3

const ReviewStep = ({
  goal,
  setActiveStep,
  storeGoal,
  updateGoal
}: ReviewStepProps) => {
  const [reports, setReports] = useState<IMonthReport | null>(null)

  const { storeId, goalId } = useParams<ParamsProps>()
  const history = useHistory()
  const dispatch = useDispatch()

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { reportsList, monthReport } = useSelector<
    SelectorReport,
    IReportProps
  >(state => state.report)

  const token = localStorage.getItem('@NeoPro:token')

  const month = new Date(goal.month)
  month.setMonth(month.getMonth() + 1)
  month.setDate(0)

  // Ao inicializar, se não tiver exibindo nenhum report, solicita os do mês
  useEffect(() => {
    if (!monthReport && !reportsList && !reports) {
      dispatch(monthRequest(storeId, token))
    } else {
      dispatch(inputListRequest(storeId, token))
    }
  }, [])

  useEffect(() => {
    // apresenta reports do intervalo selecionado
    reportsList ? setReports(reportsList) : setReports(monthReport)
  }, [monthReport, reportsList])

  useEffect(() => {
    const hasNoSections = goal.sections.length === 0
    if (hasNoSections) {
      const startGoal = set(
        startOfMonth(new Date(goal.month)),
        timeConfiguration
      )
      const endGoal = set(endOfMonth(new Date(goal.month)), timeConfiguration)

      const isoStart = startGoal.toISOString()
      const isoEnd = endGoal.toISOString()

      updateGoal({ sections: [{ _id: '', start: isoStart, end: isoEnd }] })
    }
  }, [goal.sections])

  useEffect(() => scroll.scrollToTop(), [])

  const handleCreateGoal = () => {
    storeGoal({ ...goal, storeId })
    dispatch(goalAddSuccess(goal))
    dispatch(goalListRequest(storeId, token))
    dispatch(clearCalendarGoalRedux())

    history.push(`/${storeId}/goals/${goalId}`)
  }

  return (
    <Container>
      <h1>Revise a meta para: {store?.name}</h1>

      <MonthSection
        goal={goal}
        setActiveStep={() => setActiveStep(MONTH_SECTION_INDEX)}
      />

      <CommissionSection
        goal={goal}
        setActiveStep={() => setActiveStep(COMMISSION_SECTION_INDEX)}
      />

      <ValuesSection
        goal={goal}
        setActiveStep={() => setActiveStep(VALUES_SECTION_INDEX)}
      />

      <GoalSection
        goal={goal}
        setActiveStep={() => setActiveStep(GOAL_SECTION_INDEX)}
      />

      <NextStepButton
        data-cy='finishRegisterGoalButton'
        onClick={handleCreateGoal}
      >
        <span>Finalizar e voltar para o painel</span>
        <CreateGoalIcon />
      </NextStepButton>
    </Container>
  )
}

export default ReviewStep
