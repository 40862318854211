import styled from 'styled-components'

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.strong`
  display: block;
  margin-top: 6px;
  margin-bottom: 4px;
  font-size: 14px;
`

export const TooltipContent = styled.span`
  p {
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 2px;
    line-height: 1.5;

    &:last-child {
      margin-top: 10px;
      font-size: 1em;
    }
  }
`
