export const commissionInfo = {
  pureCommission: {
    title: 'Comissionista puro',
    description:
      'A remuneração do vendedor refere-se à comissão das suas vendas.',
    tooltipContent: (
      <p>
        João vendeu <span>R$ 50.000</span>
        <br />
        Sua comissão é de <span>8%</span>.
        <br />
        <br />
        50.000 * 0,8 = 4.000
        <br />
        <br />
        Sua remuneração será de <span>R$ 4.000</span>.
      </p>
    ),
    buttonText: 'Escolher Comissionista puro'
  },
  salaryPlusCommission: {
    title: 'Salário + Comissão',
    description:
      'O vendedor recebe um salário base mais a comissão das suas vendas.',
    tooltipContent: (
      <p>
        João vendeu <span>R$ 50.000</span>
        <br />
        Sua comissão é de <span> 5%</span>.
        <br />O Piso Salarial é de <span>R$ 1.500</span>.
        <br />
        <br />
        50.000 * 0,05 = R$ 2.500
        <br />
        2.500 + 1.500 = R$ 4.000
        <br />
        <br />
        Sua remuneração será de <span>R$ 4.000</span>.
      </p>
    ),
    buttonText: 'Escolher Salário + Comissão'
  },
  uniquePeriod: {
    title: 'Período Único',
    description: 'A meta abrange todos os dias do mês como um único período.',
    tooltipContent: (
      <p>
        <span>Janeiro - 31 dias</span>
        <br />
        <br />
        Período único - dia 1 a 31
      </p>
    ),
    buttonText: 'Escolher Período Único'
  },
  multiplePeriods: {
    title: 'Vários Períodos',
    description:
      'Os dias do mês podem ser divididos em vários períodos para a meta.',
    tooltipContent: (
      <p>
        <span>Janeiro - 31 dias</span>
        <br />
        <br />
        Período 1: dia 1 a 8 <br />
        Período 2: dia 9 a 15 <br />
        Período 3: dia 16 a 22 <br />
        Período 4: dia 23 a 31
      </p>
    ),
    buttonText: 'Escolher Vários Períodos'
  },

  monthSales: {
    title: 'Vendas do mês',
    description: 'A comissão é paga somente se o vendedor bater a meta do mês.',
    tooltipContent: (
      <p>
        Resultado de João no mês:
        <br />
        <br />
        Meta do mês: <span>R$ 30.000</span> | Vendas João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 27.000</span> <br />
        Período 1: dia 01 a 10 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 9.000</span> <br />
        Período 2: dia 11 a 20 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#27AE60' }}>R$ 11.000</span> <br />
        Período 3: dia 21 a 31 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 9.000</span>
        <br />
        <br />
        João não recebe comissão, pois não bateu a meta do mês
      </p>
    ),
    buttonText: 'Escolher Vendas do mês'
  },
  salesByPeriod: {
    title: 'Vendas por período',
    description:
      'A comissão é garantida conforme o vendedor bate a meta de cada período.',
    tooltipContent: (
      <p>
        Resultado de João no mês:
        <br />
        <br />
        Meta do mês: <span>R$ 30.000</span> | Vendas João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 27.000</span> <br />
        Período 1: dia 01 a 10 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 9.000</span> <br />
        Período 2: dia 11 a 20 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#27AE60' }}>R$ 11.000</span> <br />
        Período 3: dia 21 a 31 - Meta: <span>R$ 10.000</span> | João:{' '}
        <span style={{ color: '#FFC700' }}>R$ 9.000</span>
        <br />
        <br />
        João recebe apenas comissão referente ao Período 2, no qual a meta foi
        batida.
      </p>
    ),
    buttonText: 'Escolher Vendas por período'
  },
  doNotRedistributeGoal: {
    title: 'Não redistribuir',
    description:
      'A meta da equipe não será alterada quando um vendedor tirar folga ou for desligado.',
    tooltipContent: null,
    buttonText: 'Escolher Não redistribuir'
  },
  redistributeGoal: {
    title: 'Redistribuir a meta',
    description:
      'Caso um vendedor se ausente ou seja desligado, sua meta será redistribuída entre os seus colegas.',
    tooltipContent: null,

    buttonText: 'Escolher Redistribuir a meta'
  },
  storeGoal: {
    title: 'Meta da loja',
    description:
      'A comissão dos vendedores depende da conquista da meta principal da loja.',
    tooltipContent: (
      <p>
        A meta da loja é <span>R$ 87.000</span>.
        <br />A meta de cada vendedor é de <span>R$ 29.000</span>.
        <br />
        <br />
        João vendeu <span>R$ 31.000</span>. <br />
        Maria vendeu <span>R$ 26.000</span>. <br />
        Carla vendeu <span>R$ 29.000</span>.
        <br />
        <br />
        Apesar de João e Carla terem batido suas metas individuais,
        <span> a meta da loja não foi atingida</span>, já que o total de vendas
        foi de R$ 86.000.
        <br />
        Logo, <span>nenhum vendedor receberá comissão</span>.
      </p>
    ),
    buttonText: 'Escolher Meta da Loja'
  },
  sellerGoal: {
    title: 'Meta individual',
    description:
      'O vendedor receberá sua comissão caso atinja sua meta individual de vendas.',
    tooltipContent: (
      <p>
        A meta da loja é de <span>R$ 87.000</span>.
        <br />A meta de cada vendedor é de <span>R$ 29.000</span>.
        <br />
        <br />
        João vendeu <span>R$ 31.000</span>.
        <br />
        Maria vendeu <span>R$ 26.000</span>.
        <br />
        Carla vendeu <span>R$ 29.000</span>.
        <br />
        <br />
        João e Carla bateram suas metas e receberão comissão. Já Maria não será
        comissionada, pois não atingiu o valor da sua meta.
      </p>
    ),
    buttonText: 'Escolher Meta individual'
  }
} as const
