import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { Link } from 'react-scroll'
import { CircularProgress } from '@material-ui/core'

import { GoalsTable } from '../GoalsTable'
import { ToggleItem } from '../GoalsTable/components/ToggleItem'
import {
  IConfig,
  IGoal,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'
import {
  CreateIcon,
  EditButton,
  NextStepButton
} from 'views/Goals/components/AddGoal/style'

import { Content } from '../../styles'
import { ICurrentGoalProps } from '../..'
import { Container, LoadingGoalContainer } from './styles'

interface MonthGoalsSectionProps {
  goal: ICurrentGoalProps
  basicCommissionView: boolean
  monthGoalLoading: boolean
  suggestionAnimationModal: boolean
  isLoadingSuggestionRequest: boolean
  monthGoals: IMonthGoal[]
  goalList: IGoal[]
  usersWorking: IUserWorking[]
  setMonthGoals: Dispatch<SetStateAction<IMonthGoal[]>>
  setDeleteStep: (steps: string[]) => void
  updateCurrentGoal: (goal: Partial<IGoal>) => void
}

export const MonthGoalsSection = ({
  goal,
  monthGoals,
  goalList,
  usersWorking,
  basicCommissionView,
  isLoadingSuggestionRequest,
  monthGoalLoading,
  suggestionAnimationModal,
  setMonthGoals,
  setDeleteStep,
  updateCurrentGoal
}: MonthGoalsSectionProps) => {
  const [hasFixedCommission, setHasFixedCommission] = useState(false)
  const [fixedCommission, setFixedCommission] = useState<
    number | null | string
  >(null)

  const initialCommissionValue = useRef<null | number>(null)

  const getFixedCommission = (commission: number) =>
    Number((commission * 100).toFixed(2))

  const applyFixedCommission = (
    commission: number,
    setCommission: Dispatch<SetStateAction<string | number | null>>
  ) => {
    const fixedCommission = getFixedCommission(commission)
    setHasFixedCommission(true)
    setCommission(fixedCommission)
    initialCommissionValue.current = fixedCommission
  }

  const getLastActiveGoal = (goalList: IGoal[]) => {
    return goalList.find(goal => goal.config.active)
  }

  const getLastActiveMonthGoal = (goalList: IGoal[], month: string) => {
    return goalList.find(goal => goal.config.active && goal.month === month)
  }

  useEffect(() => {
    const lastGoalMonthActive = getLastActiveMonthGoal(goalList, goal.month)
    const lastGoalActive = getLastActiveGoal(goalList)
    const hasCommission = !!initialCommissionValue.current
    const hasFixedCommission = !!goal?.config?.commission?.fixed?.commission
    const fixedCommissionIsZero =
      goal?.config?.commission?.fixed?.commission === 0

    if (hasCommission) {
      setFixedCommission(initialCommissionValue.current)
    } else if (hasFixedCommission) {
      applyFixedCommission(
        goal.config.commission.fixed.commission,
        setFixedCommission
      )
    } else if (fixedCommissionIsZero) {
      setHasFixedCommission(false)
    } else if (lastGoalMonthActive) {
      const lastGoalMonthCommission =
        lastGoalMonthActive?.config?.commission?.fixed?.commission

      if (!!lastGoalMonthCommission) {
        applyFixedCommission(lastGoalMonthCommission, setFixedCommission)
      }
    } else if (lastGoalActive) {
      const lastGoalActiveCommission =
        lastGoalActive?.config?.commission?.fixed?.commission
      if (lastGoalActiveCommission) {
        applyFixedCommission(lastGoalActiveCommission, setFixedCommission)
      }
    }
  }, [goalList, goal?.config?.commission?.fixed?.commission, goal.month])

  const handleToggleCommissionBase = () => {
    const newFixedCommissionValue = !hasFixedCommission
      ? initialCommissionValue.current
      : null

    setHasFixedCommission(prevStatus => !prevStatus)
    setFixedCommission(newFixedCommissionValue)
  }

  const formatMonthGoal = (monthGoal: IMonthGoal) => {
    if (monthGoal.bonusPercentage === 'no-bonus' && monthGoal.bonus === 0) {
      return {
        ...monthGoal,
        bonusPercentage: false,
        bonus: 0
      }
    }
    return monthGoal
  }

  const formatMonthGoals = (monthGoals: IMonthGoal[]) =>
    monthGoals.map(formatMonthGoal)

  const formatCommission = (commission: string | number | null) => {
    const commissionWithTwoDecimals = Number(Number(commission).toFixed(2))
    initialCommissionValue.current = commissionWithTwoDecimals
    return commissionWithTwoDecimals ? commissionWithTwoDecimals / 100 : 0
  }

  const buildGoalConfig = (
    currentGoalConfig: IConfig,
    fixedCommission: string | number | null
  ): IConfig => ({
    ...currentGoalConfig,
    commission: {
      ...currentGoalConfig.commission,
      fixed: {
        commission: formatCommission(fixedCommission),
        type: 'user'
      }
    }
  })

  const handleSubmitMonthGoals = () => {
    const monthGoalsBackendFormat = formatMonthGoals(monthGoals)
    const goalConfig = buildGoalConfig(goal.config, fixedCommission)

    updateCurrentGoal({
      monthGoals: monthGoalsBackendFormat,
      config: goalConfig
    })
  }

  const hasMonthGoalsAndFixedCommission =
    goal.config.commission?.fixed?.commission >= 0 && monthGoals.length > 0

  const canShowMonthGoalsSection =
    basicCommissionView &&
    !monthGoalLoading &&
    (!isLoadingSuggestionRequest || suggestionAnimationModal) &&
    (goal.usersWorking.length > 0 || monthGoals.length > 0)

  return canShowMonthGoalsSection ? (
    <Container>
      <Content
        isFilled={
          goal.config?.commission?.fixed?.commission >= 0 &&
          goal.monthGoals?.length > 0
        }
      >
        <h1>Meta dos vendedores</h1>
        <div>
          <ToggleItem
            checked={hasFixedCommission}
            commissionValue={fixedCommission}
            toggleCommissionBase={handleToggleCommissionBase}
            setFixedCommission={setFixedCommission}
            dataCy='commissionBaseGoalSwitch'
          />
          <GoalsTable
            goal={goal}
            monthGoals={monthGoals}
            setMonthGoals={setMonthGoals}
            updateCurrentGoal={updateCurrentGoal}
            usersWorking={usersWorking}
          />
        </div>
      </Content>

      {hasMonthGoalsAndFixedCommission ? (
        <Link to='monthGoals' offset={-170} smooth={true}>
          <EditButton onClick={() => setDeleteStep(['monthGoals', 'fixed'])}>
            Alterar
            <CreateIcon />
          </EditButton>
        </Link>
      ) : (
        monthGoals.length > 0 && (
          <Link to='calendar' offset={-100} smooth={true}>
            <NextStepButton
              data-cy='sellersGoalNextButton'
              onClick={handleSubmitMonthGoals}
            >
              Avançar
            </NextStepButton>
          </Link>
        )
      )}
    </Container>
  ) : (
    (basicCommissionView || monthGoalLoading) && (
      <LoadingGoalContainer>
        <CircularProgress size={20} />
        <span>Calculando dados da Meta...</span>
      </LoadingGoalContainer>
    )
  )
}
