// React
import { useState, useEffect, ChangeEvent } from 'react'
// Redux
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'

// Libs
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker'

// Components
import {
  InputContainer,
  SliderContainer,
  WeightSelection,
  WeightSelectionItem
} from 'components/UsersGoal/components/GoalSliderModal/styles'
import Tooltip from 'components/Tooltip'

// MaterialUI
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { makeStyles } from '@material-ui/core/styles'
import {
  Lock,
  CheckCircle,
  RadioButtonUnchecked,
  ErrorOutline as ErrorOutlineIcon,
  Speed as SpeedIcon
} from '@material-ui/icons'
import {
  PersonRemove as PersonRemoveIcon,
  TripOrigin as TripOriginIcon,
  Lens as LensIcon
} from '@mui/icons-material'

import {
  Checkbox,
  Slider,
  CircularProgress,
  Box,
  Fade,
  Tooltip as TooltipMUI,
  circularProgressClasses
} from '@mui/material'
import { formatPrice } from '../../../../utils/format'
import {
  ActionButtons,
  Container,
  DSRCheckbox,
  GoalCard,
  Item,
  ItemTitle,
  Picker
} from './styles'
import dayPickerBR from '../../../../common/DayPicker-pt-BR'
import {
  IDayOff,
  IDays,
  IDaysUsers,
  IGoalProps,
  IInitialCommission,
  IUserWorking
} from 'store/modules/goal/types'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { goalUpdateUsers } from 'store/modules/goal/actions'
import { IStoreProps } from 'store/modules/store/types'
import api from 'repositories/api'
import { DayPickerDescription } from 'components/UsersGoal/components/EditUserGoalModal/styles'
import { getMonthGoalTooltipInfos } from 'utils/getMonthGoalTooltipInfos'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}))

interface SelectorGoal {
  state: DefaultRootState
  goal: IGoalProps
}

interface IUsersWorkingWithDays extends IUserWorking {
  days?: IDays[]
}

interface SellerProps {
  dsr: boolean
  goalWeight: number | string
  weightLocked: boolean
  edited: boolean
}

interface IUsersFromGoalInfo {
  working: IUserWorking[]
  notWorking: IUserWorking[]
  weights: 0
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface UserDays {
  date: string
  dayOff: IDayOff
}

interface IAccGoal {
  _id: number
  value: number
  commission: IInitialCommission
}

interface EditUserGoalModalProps {
  isOpen: boolean
  user: IUserWorking | undefined
  onClose: () => void
  onSubmit: () => void
}

export default function AdjustWorkingDaysCalendar({
  isOpen,
  user,
  onClose,
  onSubmit
}: EditUserGoalModalProps) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const confirm = useConfirm()
  const token = window.localStorage.getItem('@NeoPro:token')

  const { goalList } = useSelector<SelectorGoal, IGoalProps>(
    state => state.goal
  )
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isUsersWeightLocked, setIsUsersWeightLocked] = useState(false)
  const [sellersOutsideGoal, setSellersOutsideGoal] = useState<{
    list: IUserWorking[]
    requested: boolean
  }>({
    list: [],
    requested: false
  })
  const [days, setDays] = useState<IDays[]>([])
  const [seller, setSeller] = useState<SellerProps>({
    dsr: true,
    goalWeight: 100,
    weightLocked: false,
    edited: false
  })
  const [usersFromGoalInfo, setUsersFromGoalInfo] =
    useState<IUsersFromGoalInfo>({
      working: [],
      notWorking: [],
      weights: 0
    })

  const usersWorkingLength = usersFromGoalInfo.working.length

  const [tab, setTab] = useState(0)
  const tabColorOptions = tab === 0 ? '#FFC700' : '#263238'

  const [accGoal, setAccGoal] = useState<IAccGoal[]>()

  const date = new Date()
  const currentMonth = date.getMonth()
  const currentYear = date.getFullYear()
  const currentDay = date.getDate()

  const goal = goalList.find(goal => {
    const isSameMonth = new Date(goal.month).getMonth() === currentMonth
    const isSameYear = new Date(goal.month).getFullYear() === currentYear
    const isCurrentGoal = isSameMonth && isSameYear

    return isCurrentGoal
  })

  //Verifica se o usuário está vinculado a loja e se o tipo dele é vendedor, assim filtrando os usuários que estão trabalhando na meta.
  const filteredUsersWorking = goal?.usersWorking.filter(userWorking =>
    store?.users.find(
      user =>
        user._id === userWorking.userId?._id &&
        user.stores.find(store => store.type === 'seller')
    )
  )

  useEffect(() => {
    if (!!goal && !!user) {
      const workingDays = goal.days.filter(day => {
        const isUserWorking = day.users.find(
          usr => usr.userId === user.userId._id
        )

        return user.active && isUserWorking && day.working
      })

      const filteredDays = (user.active ? workingDays : goal.days).filter(
        day => new Date(day.date).getDate() <= currentDay
      )

      setDays(filteredDays)

      const selectedUser = goal.usersWorking.find(
        userW => userW.userId?._id === user.userId?._id
      )

      setSeller(prev => ({
        ...prev,
        dsr: user.active && selectedUser ? selectedUser.dsr : goal.dsr > 0,
        goalWeight:
          (user.active && selectedUser ? selectedUser.goalWeight : 1) * 100
      }))
      setTab(selectedUser?.weightLocked ? 1 : 0)
    }
  }, [goal, user])

  useEffect(() => {
    if (!!goal && !!user && !user.active) {
      const inactiveUserDays = goal.days.map(day => {
        const newUser = {
          userId: user.userId._id,
          dayOff: {
            enable: false
          }
        }
        const users = [...day.users, newUser]

        return {
          ...day,
          users
        }
      })

      const filteredDays = inactiveUserDays.filter(
        day => new Date(day.date).getDate() <= currentDay
      )

      setDays(filteredDays)
    }
  }, [goal, user])

  const isoStringDays = days.map(day => new Date(day.date).toISOString())
  const daysNotWorked =
    user?.userId?._id && goal?.days
      ? goal.days
          .filter(day => {
            return !isoStringDays.includes(day.date)
          })
          .map(day => day.date)
      : []

  const daysWithStoreClosed =
    user?.userId?._id && goal?.days
      ? goal.days
          .filter(day => {
            return typeof day.working === 'boolean' && !day.working
          })
          .map(day => day.date)
      : []

  const getMonthGoals = async () => {
    try {
      if (!!user && !!goal && days.length > 0) {
        setIsLoading(true)
        const formattedDays: { dayOff: IDayOff; date: string }[] = []
        days.forEach(day => {
          const userId = user?.userId?._id ?? ''
          const isUserWorkingOnThisDay = day.users.find(
            usr => usr.userId === userId
          )

          if (!!isUserWorkingOnThisDay) {
            formattedDays.push({
              dayOff: isUserWorkingOnThisDay.dayOff,
              date: day.date
            })
          }
        })

        const data = {
          dsr: seller.dsr,
          goalWeight: Number(seller.goalWeight) / 100,
          userId: user.userId._id,
          days: formattedDays
        }

        const response = await api.axios.post<IAccGoal[]>(
          `/report/goal/${goal._id}/user-goal-preview`,
          data
        )

        const goalsWithId = response.data.map((goal, index) => {
          return {
            ...goal,
            _id: index
          }
        })

        setAccGoal(goalsWithId)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getMonthGoals()
    }, 500)

    return () => clearTimeout(getData)
  }, [days, seller?.goalWeight])

  useEffect(() => {
    if (goal?.usersWorking) {
      if (!sellersOutsideGoal.requested) {
        //Request users not working from
        try {
          api.axios
            .get(`/goal/${goal._id}/usersNotWorking`)
            .then(({ data }) => {
              const usersTranslated = data.map((user: IUserWorking) => ({
                userId: user
              }))
              setSellersOutsideGoal({ list: usersTranslated, requested: true })
            })
        } catch (error) {
          setSellersOutsideGoal({ list: [], requested: true })
          console.log(error)
        }
      }

      if (filteredUsersWorking) {
        const usersResults = filteredUsersWorking.reduce(
          (acc: IUsersFromGoalInfo, user) => {
            if (user.active) {
              acc.working = [...acc.working, user]
              acc.weights += user.goalWeight
            } else acc.notWorking = [...acc.notWorking, user]

            if (user.weightLocked && !isUsersWeightLocked)
              setIsUsersWeightLocked(true)
            return acc
          },
          { weights: 0, working: [], notWorking: [] }
        )

        setUsersFromGoalInfo(usersResults)

        //Se tiver alguém que está dentro de working ou notWorking, e ainda está dentro de sellersOutsideGoal, tem que tirar
        if (
          usersResults.working.length > 0 ||
          usersResults.notWorking.length > 0
        ) {
          const newSellersOutsideGoal = {
            ...sellersOutsideGoal,
            list: sellersOutsideGoal.list.filter(
              userW =>
                !usersResults.working.find(
                  user => user.userId?._id === userW.userId?._id
                ) ||
                usersResults.notWorking.find(
                  user => user.userId?._id === userW.userId?._id
                )
            )
          }
          setSellersOutsideGoal(newSellersOutsideGoal)
        }
      }
    }
  }, [goal])

  const remainWeight = goal
    ? goal.usersWorking.reduce(
        (acc, currUser, i) => {
          if (currUser.active) {
            acc.weightToDistribute += 1
            if (
              currUser.weightLocked &&
              !(currUser?.userId?._id === user?.userId?._id)
            ) {
              acc.weightToDistribute -= currUser.goalWeight
            } else {
              acc.usersToDistribute += 1
            }
          }
          if (i === goal?.usersWorking.length - 1) {
            if (!user?.active) {
              acc.weightToDistribute += 1
              acc.usersToDistribute += 1
            }
            const weightDistributed =
              (acc.weightToDistribute / acc.usersToDistribute) * 100
            acc.weightToDistribute =
              weightDistributed < 0 ? 0 : weightDistributed
          }
          return acc
        },
        { weightToDistribute: 0, usersToDistribute: 0 }
      )
    : { weightToDistribute: 0, usersToDistribute: 0 }

  const indexDaysWithDayOff: number[] = []
  days.forEach((day, index) => {
    const userProps = day.users.find(usr => usr.userId === user?.userId._id)
    const isDayOff = userProps?.dayOff.enable

    if (isDayOff) {
      indexDaysWithDayOff.push(index)
    }
  })

  const workingDays: IDays[] = []
  const dayOffDays: IDays[] = []

  days.forEach(day => {
    const userStatus = day.users.find(usr => usr.userId === user?.userId._id)

    if (userStatus) {
      userStatus.dayOff.enable ? dayOffDays.push(day) : workingDays.push(day)
    }
  })

  const handleClickOnDay = (day: Date, { selected }: DayModifiers) => {
    const currDayIndex = days.findIndex(
      prevDay => new Date(prevDay.date).getUTCDate() === day.getUTCDate()
    )
    if (
      user &&
      goal &&
      goal.days
        .filter(day => day.working)
        .map(dayM => new Date(dayM.date).getUTCDate())
        .includes(day.getUTCDate())
    ) {
      // Antiga estrutura (WORKING -> DAY OFF -> NON WORKING)
      //     const setDayAsWorkingDay = () => {
      //       const clickedDay = goal.days.find(
      //         eaDay => new Date(eaDay.date).getUTCDate() === day.getUTCDate()
      //       )
      //       if (clickedDay) {
      //         const isUserOnArray = clickedDay.users.find(
      //           usr => usr.userId === user.userId._id
      //         )
      //         if (isUserOnArray) {
      //           const formattedUsersDay = clickedDay.users.map(usr => {
      //             if (usr.userId === user.userId._id) {
      //               return {
      //                 userId: usr.userId,
      //                 dayOff: {
      //                   enable: false
      //                 }
      //               }
      //             }
      //             return usr
      //           })
      //           const formattedDay = {
      //             ...clickedDay,
      //             users: formattedUsersDay
      //           }
      //           const newDays = [...days, formattedDay].sort(
      //             (a, b) =>
      //               new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
      //           )
      //           setDays(newDays)
      //         } else {
      //           const newUsers: IDaysUsers[] = [
      //             ...clickedDay.users,
      //             {
      //               userId: user.userId._id,
      //               dayOff: {
      //                 enable: false
      //               }
      //             }
      //           ]
      //           const formattedDay = {
      //             ...clickedDay,
      //             users: newUsers
      //           }
      //           const newDays = [...days, formattedDay].sort(
      //             (a, b) =>
      //               new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
      //           )
      //           setDays(newDays)
      //         }
      //       }
      //     }
      //     const setDayAsDayOff = () => {
      //       // des-selecionar
      //       const newDays = days.map(eachDay => {
      //         const currDay = new Date(eachDay.date).getUTCDate()
      //         if (currDay === day.getUTCDate()) {
      //           const isUserOnArray = eachDay.users.find(
      //             usr => usr.userId === user.userId._id
      //           )
      //           const newUsers: IDaysUsers[] = []
      //           if (isUserOnArray) {
      //             const updatedUsers = eachDay.users.map(usr => {
      //               if (usr.userId === user.userId._id) {
      //                 return {
      //                   ...usr,
      //                   dayOff: {
      //                     enable: true
      //                   }
      //                 }
      //               }
      //               return usr
      //             })
      //             newUsers.push(...updatedUsers)
      //           } else {
      //             const newUser = {
      //               userId: user.userId._id,
      //               dayOff: {
      //                 enable: true
      //               }
      //             }
      //             const updatedUsers = [...eachDay.users, newUser]
      //             newUsers.push(...updatedUsers)
      //           }
      //           return {
      //             ...eachDay,
      //             users: newUsers
      //           }
      //         }
      //         return eachDay
      //       })
      //       setDays(newDays)
      //     }
      //     const setDayAsNoNWorkingDay = () => {
      //       const newDays = days.filter(
      //         eachDay => !DateUtils.isSameDay(new Date(eachDay.date), day)
      //       )
      //       setDays(newDays)
      //     }
      //   }
      //   if (!selected) {
      //     setDayAsWorkingDay()
      //   } else if (selected && !indexDaysWithDayOff.includes(currDayIndex)) {
      //     setDayAsDayOff()
      //   } else {
      //     setDayAsNoNWorkingDay()
      //  }

      // Estrutura nova (WORKING -> NON WORKING -> DAY OFF)
      const setDayAsWorkingDay = () => {
        const newDays = days.map(eachDay => {
          const currDay = new Date(eachDay.date).getUTCDate()

          if (currDay === day.getUTCDate()) {
            const isUserOnArray = eachDay.users.find(
              usr => usr.userId === user.userId._id
            )
            const newUsers: IDaysUsers[] = []

            if (isUserOnArray) {
              const updatedUsers = eachDay.users.map(usr => {
                if (usr.userId === user.userId._id) {
                  return {
                    ...usr,
                    dayOff: {
                      enable: false
                    }
                  }
                }

                return usr
              })

              newUsers.push(...updatedUsers)
            } else {
              const newUser = {
                userId: user.userId._id,
                dayOff: {
                  enable: false
                }
              }
              const updatedUsers = [...eachDay.users, newUser]

              newUsers.push(...updatedUsers)
            }

            return {
              ...eachDay,
              users: newUsers
            }
          }

          return eachDay
        })

        setDays(newDays)
      }

      const setDayAsDayOff = () => {
        const clickedDay = goal.days.find(
          eaDay => new Date(eaDay.date).getUTCDate() === day.getUTCDate()
        )
        if (clickedDay) {
          const isUserOnArray = clickedDay.users.find(
            usr => usr.userId === user.userId._id
          )
          if (isUserOnArray) {
            const formattedUsersDay = clickedDay.users.map(usr => {
              if (usr.userId === user.userId._id) {
                return {
                  userId: usr.userId,
                  dayOff: {
                    enable: true
                  }
                }
              }
              return usr
            })
            const formattedDay = {
              ...clickedDay,
              users: formattedUsersDay
            }
            const newDays = [...days, formattedDay].sort(
              (a, b) =>
                new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
            )
            setDays(newDays)
          } else {
            const newUsers: IDaysUsers[] = [
              ...clickedDay.users,
              {
                userId: user.userId._id,
                dayOff: {
                  enable: true
                }
              }
            ]
            const formattedDay = {
              ...clickedDay,
              users: newUsers
            }
            const newDays = [...days, formattedDay].sort(
              (a, b) =>
                new Date(a.date).getUTCDate() - new Date(b.date).getUTCDate()
            )
            setDays(newDays)
          }
        }
      }

      const setDayAsNonWorkingDay = () => {
        const newDays = days.filter(
          eachDay => !DateUtils.isSameDay(new Date(eachDay.date), day)
        )

        setDays(newDays)
      }

      if (!selected) {
        setDayAsDayOff()
      } else if (!indexDaysWithDayOff.includes(currDayIndex)) {
        setDayAsNonWorkingDay()
      } else {
        setDayAsWorkingDay()
      }
    }
  }

  const distributeWeights = (users: IUsersWorkingWithDays[]) => {
    const usersResults = users.reduce(
      (acc, user, i) => {
        if (user.active && user.goalWeight > 0) {
          // Passa por cada usuário somando o seu peso em 100%
          acc.accWeights += 1
          // Caso ele for fixado diminui o valor
          if (user.weightLocked) acc.accWeights -= user.goalWeight
          // Caso ele não for locado não vai entrar nos usuários a ser distribuidos
          if (!user.weightLocked) acc.usersUnlocked += 1
        }
        if (i === users.length - 1) {
          // No ultimo vai ver o acumulado que já subtraiu os fixados e dividir pelos usuários não fixados
          acc.accWeights = acc.accWeights / acc.usersUnlocked
        }
        return acc
      },
      { accWeights: 0, usersUnlocked: 0 }
    )

    users.forEach(user => {
      if (user.active && !user.weightLocked && goal?.distribute)
        user.goalWeight =
          usersResults.accWeights < 0 ? 0 : usersResults.accWeights
      return user
    })
    return users
  }

  const handleUpdateUserWeight = (
    days: IDays[],
    user: IUserWorking,
    type: 'auto' | 'fixed' = 'auto',
    weight = 1,
    goalId: string = '',
    isRemoving = false
  ) => {
    try {
      const seen = new Set()

      let newGoalSellersList: IUsersWorkingWithDays[] = [
        ...usersFromGoalInfo.notWorking,
        ...usersFromGoalInfo.working,
        user
      ].filter(el => {
        const duplicate = seen.has(el.userId?._id)
        seen.add(el.userId?._id)
        return !duplicate
      })

      //Todos os vendedores da meta (Ativos e inativos)
      const currentUserIndexInsideGoal = newGoalSellersList.findIndex(
        el => el.userId?._id === user.userId?._id
      )

      const newSellerEnteringGoal = {
        ...user,
        dsr: (goal?.dsr ?? 0) > 0,
        goalWeight: weight,
        days,
        weightLocked: type === 'fixed',
        active: days.length === 0 ? false : !isRemoving
      }

      if (currentUserIndexInsideGoal < 0) {
        newGoalSellersList.push(newSellerEnteringGoal) // Vendedor novo entrando
      } else {
        newGoalSellersList[currentUserIndexInsideGoal] = newSellerEnteringGoal // Vendedor já existente
      }

      newGoalSellersList = newGoalSellersList.map(userM => ({
        // Coloca todos os vendedores no padrão de ter days p/ auxiliar nos cálculos abaixo
        days: goal?.days.filter(day =>
          day.users.find(usr => usr.userId === userM.userId?._id)
        ),
        ...userM // Sobrescreve em seguida pq pode ter caso de o vendedor já vir com days montado
      }))

      const distributedUsers = distributeWeights(newGoalSellersList)

      if (!isUsersWeightLocked) setIsUsersWeightLocked(true) // se nenhum vendedor tiver sido alterado ou se ja clicou no cadeado uma vez, bloqueia para poder clicar no cadeado dnv

      if (isRemoving) {
        const newSellersOutSideGoal = {
          ...sellersOutsideGoal,
          list: sellersOutsideGoal.list.filter(
            userW => userW.userId?._id !== user.userId?._id
          )
        }
        setSellersOutsideGoal(newSellersOutSideGoal)
      }

      const users = distributedUsers.map(userM => {
        const userDays: UserDays[] = []
        userM.days?.forEach(day => {
          const formattedUser = day.users.find(
            usr => usr.userId === userM.userId._id
          )

          if (formattedUser && !!formattedUser.dayOff) {
            userDays.push({
              dayOff: {
                enable: formattedUser.dayOff.enable
              },
              date: day.date
            })
          }
        })

        return {
          dsr: userM.dsr,
          goalWeight: Number(userM.goalWeight) ?? 1,
          userId: userM.userId?._id,
          weightLocked: userM.weightLocked || false, // VERIFICAR SE PRECISA DISSO,
          days: userM.active ? userDays ?? [] : []
        }
      })

      dispatch(goalUpdateUsers(users, goalId, token))
    } catch (error) {
      console.log(error)
    }
  }

  const dayPickerRenderDay = (day: Date) => {
    const currentDay = goal?.days.find(
      currDay =>
        new Date(currDay.date).getUTCDate() === new Date(day).getUTCDate()
    )

    const isStoreClosedOnThisDay = !currentDay || !currentDay.working

    const currentDayAux = days.find(
      currDay =>
        new Date(currDay.date).getUTCDate() === new Date(day).getUTCDate()
    )

    const currentUser = currentDayAux?.users.find(
      eachUser => eachUser.userId === user!.userId._id
    )

    const isNonWorkingDay = daysNotWorked.includes(day.toISOString())

    const tooltipText = isStoreClosedOnThisDay
      ? 'Loja fechada'
      : isNonWorkingDay
      ? 'Folga sem meta'
      : currentUser?.dayOff.enable
      ? 'Folga com meta'
      : 'Irá trabalhar'

    return (
      <TooltipMUI
        title={
          <Typography style={{ color: '#fff', fontSize: 14 }}>
            {tooltipText}
          </Typography>
        }
      >
        <p>{new Date(day).getDate()}</p>
      </TooltipMUI>
    )
  }

  const dayPickerModifiers = {
    working: (day: Date) => {
      const stringDays = days
        .filter((elDay, index) => {
          const currentDay = days.find(
            currDay =>
              new Date(currDay.date).getUTCDate() ===
              new Date(elDay.date).getUTCDate()
          )

          const isStoreClosedOnThisDay = !currentDay || !currentDay.working

          return !isStoreClosedOnThisDay && !indexDaysWithDayOff.includes(index)
        })
        .map(day => new Date(day.date).getUTCDate())

      const isSameMonth = new Date(goal!.month).getMonth() === day.getMonth()

      return isSameMonth && stringDays.includes(day.getUTCDate())
    },
    notWorked: (day: Date) => daysNotWorked.includes(day.toISOString()),
    dayOff: (day: Date) => {
      const isADayFromDifferentMonth =
        new Date(day).getMonth() !== new Date(goal!.month).getMonth()

      const stringDays = days
        .filter((day, index) => indexDaysWithDayOff.includes(index))
        .map(day => new Date(day.date).getUTCDate())

      if (!isADayFromDifferentMonth && stringDays.includes(day.getUTCDate())) {
      }
      return !isADayFromDifferentMonth && stringDays.includes(day.getUTCDate())
    },
    // pastDay: day => {
    //   const isSameMonth =
    //     new Date(goal.month).getMonth() === day.getMonth()

    //   const isPreviousDay =
    //     new Date(day).getMonth() <
    //       new Date().getMonth() ||
    //     new Date(day).getDate() < new Date().getDate()
    //   return false
    // },
    storeClosed: (day: Date) => daysWithStoreClosed.includes(day.toISOString()),
    daysFromAnotherMonth: (day: Date) => {
      const isSameMonth = new Date(goal!.month).getMonth() === day.getMonth()

      return !isSameMonth
    }
  }

  const handleClickAutoWeight = () => {
    setTab(0)
    setSeller(state => ({
      ...state,
      edited: true,
      weightLocked: false,
      goalWeight: goal?.distribute
        ? remainWeight.weightToDistribute.toFixed(2)
        : 100
    }))
  }

  const handleClickFixedWeight = () => {
    setSeller(state => ({
      ...state,
      goalWeight: user!.goalWeight * 100,
      edited: true,
      weightLocked: true
    }))
    setTab(1)
  }

  const handleRemoveUserFromGoal = (user: IUserWorking) => {
    confirm({
      title: 'Remover vendedor da meta e da loja',
      description: `Você tem certeza que deseja remover ${user.userId.name.first} da meta e da loja?`,
      confirmationText: 'Sim',
      cancellationText: 'Não'
    })
      .then(() => {
        handleUpdateUserWeight(
          days,
          user,
          tab === 1 ? 'fixed' : 'auto',
          Number(seller.goalWeight) / 100,
          goal?._id,
          false
        )
        onSubmit()
        onClose()
      })
      .catch(() => {})
  }

  const handleChangeUserWeightInputValue = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    setSeller(state => ({
      ...state,
      edited: true,
      weightLocked: true,
      goalWeight: Number(value) > 300 ? 300 : value.replace(',', '.')
    }))
    setTab(1)
  }

  const handleChangeUserWeightSliderValue = (
    _: Event,
    value: number | number[]
  ) => {
    setSeller(state => ({
      ...state,
      goalWeight: value as number,
      edited: true,
      weightLocked: true
    }))
    setTab(1)
  }

  const hasTwoOrMoreSellers = usersWorkingLength > 1

  const userWeightInputValue =
    !user?.active && !seller.edited
      ? remainWeight.weightToDistribute.toFixed(3)
      : typeof seller.goalWeight === 'number'
      ? seller.goalWeight.toFixed(3)
      : seller.goalWeight

  const goalWeightSliderValue = Math.round(
    !user?.active && !seller.edited
      ? Number(remainWeight.weightToDistribute.toFixed(2))
      : Number(seller.goalWeight)
  )

  const canShowIndividualGoalOrRemuneration =
    days && Array.isArray(accGoal) && accGoal.length > 0 && !!user

  const sliderMarks = [
    { value: 0 },
    { value: 100 },
    { value: 200 },
    { value: 300 }
  ]

  const sliderStyles = {
    '& .MuiSlider-track': {
      color: tabColorOptions,
      transition: '0.2s ease-in'
    },
    '& .MuiSlider-thumb': {
      borderColor: tabColorOptions,
      transition: '0.2s ease-in'
    },
    '& .MuiSlider-mark': {
      backgroundColor: tabColorOptions,
      transition: '0.2s ease-in'
    }
  }

  const boxSxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    height: 40,
    display: isLoading ? 'block' : 'none'
  }

  const circularProgressSxStyles = {
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round'
    },
    color: '#308fe8'
  }

  return (
    <Container className={classes.root}>
      {!!user && (
        <Dialog open={isOpen} onClose={onClose}>
          <Box sx={boxSxStyles}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant='determinate'
                sx={theme => ({
                  color: theme.palette.grey[200]
                })}
                size={40}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant='indeterminate'
                disableShrink
                sx={circularProgressSxStyles}
                size={40}
                thickness={4}
              />
            </Box>
          </Box>

          <DialogTitle id='form-dialog-title'>
            Remover {user?.userId?.name.complete || '...'} da loja.
          </DialogTitle>
          <DialogContent>
            <Typography>
              Defina o peso e os dias que
              <strong> {user?.userId?.name.first || '...'}</strong> trabalhou
              este mês. <br />A meta do vendedor será proporcional a esses
              parâmetros.
            </Typography>
            {!!user && !!goal ? (
              <div style={{ marginTop: 20 }}>
                <Grid container className={classes.margin}>
                  <div
                    style={{
                      borderRight: '2px solid #EEEEEE',
                      paddingRight: 20
                    }}
                  >
                    <Picker>
                      <DayPicker
                        month={new Date(goal.month)}
                        canChangeMonth={false}
                        months={dayPickerBR.months}
                        renderDay={dayPickerRenderDay}
                        weekdaysLong={dayPickerBR.weekdaysLong}
                        weekdaysShort={dayPickerBR.weekdaysShort}
                        firstDayOfWeek={0}
                        labels={dayPickerBR.labels}
                        modifiers={dayPickerModifiers}
                        selectedDays={days
                          .filter(day => day.working)
                          .map(day => new Date(day.date))}
                        onDayClick={handleClickOnDay}
                      />
                    </Picker>

                    <DayPickerDescription>
                      <TooltipMUI
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            O vendedor terá meta para o dia
                          </Typography>
                        }
                      >
                        <div data-for='days-to-work'>
                          Irá trabalhar
                          <span>
                            <LensIcon
                              fontSize='small'
                              style={{
                                color: '#93C5FD'
                              }}
                            />
                            {workingDays.length} dias
                          </span>
                        </div>
                      </TooltipMUI>

                      <TooltipMUI
                        color='#000'
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            A meta do vendedor destes dias, será retirada dele.
                            Clique 1 vez para acionar
                          </Typography>
                        }
                      >
                        <div>
                          Folga s/ meta
                          <span>
                            <LensIcon
                              fontSize='small'
                              style={{
                                color: '#EEEEF1'
                              }}
                            />
                            {daysNotWorked.length} dias
                          </span>
                        </div>
                      </TooltipMUI>

                      <TooltipMUI
                        title={
                          <Typography style={{ color: '#fff', fontSize: 14 }}>
                            A meta do vendedor destes dias, é distribuída nos
                            demais dias em que ele for trabalhar. Clique 2 vezes
                            para acionar.
                          </Typography>
                        }
                      >
                        <div data-for='weight-selection-left'>
                          Folga c/ meta
                          <span>
                            {/* <TripOriginIcon
                              fontSize='small'
                              style={{
                                color: '#93C5FD',
                                backgroundColor: '#f2eb70'
                              }}
                            /> */}
                            <p
                              style={{
                                width: 16,
                                height: 16,
                                backgroundColor: '#f2eb70',
                                border: '2px solid #93C5FD',
                                borderRadius: '50%',
                                margin: 2
                              }}
                            />
                            {dayOffDays.length} dias
                          </span>
                        </div>
                      </TooltipMUI>
                    </DayPickerDescription>
                  </div>
                  <Grid style={{ paddingLeft: 20 }} item xs>
                    <Item>
                      <ItemTitle>Vendedor recebeu DSR?</ItemTitle>
                      <DSRCheckbox
                        onChange={(_, checked) => {
                          setSeller({ ...seller, dsr: checked })
                        }}
                        label={seller.dsr ? 'Recebeu' : 'Não recebeu'}
                        dsr={String(seller.dsr)}
                        control={
                          <Checkbox
                            disableRipple
                            color='primary'
                            checked={seller.dsr}
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<CheckCircle color='primary' />}
                          />
                        }
                      />
                    </Item>
                    <Item>
                      <ItemTitle>Peso do vendedor</ItemTitle>
                      <WeightSelection>
                        <Tooltip
                          fixed
                          id='weight-selection-left'
                          content={
                            <p>
                              Peso ajustado automaticamente em referência aos
                              outros vendedores
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-left'
                          onClick={handleClickAutoWeight}
                          selected={tab === 0}
                        >
                          <SpeedIcon fontSize='small' />
                          <span>Auto</span>
                        </WeightSelectionItem>

                        <Tooltip
                          fixed
                          id='weight-selection-right'
                          content={
                            <p>
                              O peso do vendedor é fixado no valor inserido
                              abaixo
                            </p>
                          }
                        />
                        <WeightSelectionItem
                          data-tip
                          data-for='weight-selection-right'
                          onClick={handleClickFixedWeight}
                          right
                          selected={tab === 1}
                        >
                          <Lock fontSize='small' />
                          <span>Fixado</span>
                        </WeightSelectionItem>
                      </WeightSelection>
                      <InputContainer>
                        <input
                          maxLength={7}
                          value={userWeightInputValue}
                          style={{ color: tab === 0 ? '#FFC700' : '#000' }}
                          pattern='[0-9]*'
                          onChange={handleChangeUserWeightInputValue}
                          data-clarity-unmask='true'
                        />
                        <label>%</label>
                      </InputContainer>

                      <SliderContainer>
                        <span>0%</span>
                        <Slider
                          className='slider'
                          defaultValue={100}
                          step={5}
                          min={0}
                          max={300}
                          onChange={handleChangeUserWeightSliderValue}
                          value={goalWeightSliderValue}
                          sx={sliderStyles}
                          marks={sliderMarks}
                        />
                        <span>300%</span>
                      </SliderContainer>
                    </Item>
                    <Item>
                      <ItemTitle>Meta individual / Remuneração</ItemTitle>
                      {canShowIndividualGoalOrRemuneration &&
                        accGoal.map((currAccGoal, index) => {
                          const {
                            goalName,
                            sumCommission
                          }: IInitialCommission = currAccGoal.commission

                          const userGoalTooltipInfos = getMonthGoalTooltipInfos(
                            currAccGoal.commission
                          )

                          const remuneration = sumCommission || 0

                          return (
                            <GoalCard key={goalName}>
                              <h1>{goalName}</h1>
                              <h2>
                                <strong
                                  {...(index === 0 && {
                                    'data-cy': 'mainGoalValueGoalUserModal'
                                  })}
                                >
                                  {formatPrice(
                                    currAccGoal.value < 0
                                      ? 0
                                      : currAccGoal.value
                                  )}
                                </strong>{' '}
                                <span
                                  {...(index === 0 && {
                                    'data-cy':
                                      'mainCommissionValueGoalUserModal'
                                  })}
                                >
                                  /{' '}
                                  <Tooltip
                                    id={`user-goal-commission-${currAccGoal._id}`}
                                    content={
                                      <div>
                                        <strong
                                          style={{
                                            display: 'block',
                                            marginBottom: '10px',
                                            fontSize: '14px'
                                          }}
                                        >
                                          Remuneração Total <br />
                                        </strong>
                                        {Object.entries(
                                          userGoalTooltipInfos
                                        ).map(([key, value]) => (
                                          <p
                                            key={key}
                                            style={{ marginBottom: '2px' }}
                                          >
                                            <strong>{key}: </strong> {value}
                                            <br />
                                          </p>
                                        ))}
                                        <p style={{ marginTop: '10px' }}>
                                          <strong>Total: </strong>
                                          {formatPrice(remuneration)}
                                        </p>
                                      </div>
                                    }
                                    place='bottom'
                                  />
                                  {formatPrice(
                                    remuneration < 0 ? 0 : remuneration
                                  )}
                                  <ErrorOutlineIcon
                                    data-tip
                                    data-for={`user-goal-commission-${currAccGoal._id}`}
                                    style={{
                                      color: '#C4C4C4',
                                      fontSize: 20,
                                      marginLeft: 5,
                                      marginBottom: -5
                                    }}
                                  />
                                </span>
                              </h2>
                            </GoalCard>
                          )
                        })}
                    </Item>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <DialogContentText>Carregando...</DialogContentText>
            )}
          </DialogContent>
          <ActionButtons>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                data-cy='cancelGoalUserModalButton'
                style={{ margin: '0 5px' }}
                onClick={onClose}
              >
                Cancelar
              </Button>

              {user?.active &&
                (hasTwoOrMoreSellers ? (
                  <Button
                    onClick={() => handleRemoveUserFromGoal(user)}
                    style={{
                      backgroundColor: '#e74c3c',
                      color: '#fff'
                    }}
                  >
                    Remover da meta e da loja
                  </Button>
                ) : (
                  <BootstrapTooltip title='A meta deve ter pelo menos um vendedor'>
                    <Button
                      style={{
                        color: '#e74c3c',
                        cursor: 'not-allowed',
                        opacity: 0.6
                      }}
                    >
                      <PersonRemoveIcon style={{ marginRight: 4 }} />
                      Remover da meta e da loja
                    </Button>
                  </BootstrapTooltip>
                ))}
            </div>
          </ActionButtons>
        </Dialog>
      )}
    </Container>
  )
}
