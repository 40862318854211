import styled, { css } from 'styled-components'

interface GoalRowProps {
  showWarningModal: boolean
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const SalesPerSellerHeader = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  column-gap: 5px;

  h2 {
    font-size: 16px;
    text-align: center;
    margin: auto;
    color: #263238;
    font-weight: 600;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
  }
`

export const GoalRow = styled.div<GoalRowProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: #f0f2f4;
  padding: 15px 10px;
  width: 140px;

  h1 {
    margin: 0px;
    color: #455a64;
    font-size: 16px;
  }

  span {
    color: ${({ showWarningModal }) =>
      showWarningModal ? '#700c0c' : '#607d8b'};
    font-size: 14px;
    ${({ showWarningModal }) =>
      showWarningModal &&
      css`
        font-weight: bold;
      `}
  }
`

export const SellersGoalTable = styled.div`
  margin: 20px 0px;
  max-width: 800px;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: 4px;
  scrollbar-color: #b5b5b5;

  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 50px;
  }
`

export const WarningModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin: 20px 0px;
  padding: 15px;
  width: 70%;
  border-radius: 7px;
  background-color: #ffc7c7;
  //border: 1px solid #ff8f8f;

  p {
    margin-left: 10px;
    font-size: 12px;
    color: #700c0c;
  }
`
