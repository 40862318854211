import { DefaultRootState, useSelector } from 'react-redux'

import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { TableCell, TableRow } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  NotInterested as NotInterestedIcon
} from '@material-ui/icons'

import { IDays } from 'store/modules/goal/types'
import { formatPrice } from '../../../../../../utils/format'
import { IStoreProps } from 'store/modules/store/types'

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface DayProps {
  day: IDays
}

export const DayRow = ({ day }: DayProps) => {
  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )

  const dayLongFormat = format(new Date(day.date), 'dd - eeee', {
    locale: ptBR
  })

  // Filtra apenas os vendedores que estão vinculados a loja
  const availableUsers = day.users.filter(user =>
    store?.users.some(userFromStore => userFromStore._id === user.userId)
  )

  const isWorkingDay = day.working

  return (
    <TableRow>
      <TableCell>
        {isWorkingDay ? (
          <CheckCircleIcon style={{ color: '#2ecc71' }} />
        ) : (
          <NotInterestedIcon />
        )}
      </TableCell>
      <TableCell>{dayLongFormat}</TableCell>
      <TableCell>
        <b>{isWorkingDay ? formatPrice(day.goal) : '-'}</b>
      </TableCell>
      <TableCell>{isWorkingDay ? availableUsers.length : '-'}</TableCell>
    </TableRow>
  )
}
