import styled, { css } from 'styled-components'
import palette from 'theme/palette'

interface SuggestionButtonProps {
  suggestionActive: boolean
}

interface LockButtonProps {
  isCalendarLocked: boolean
}

interface OpenSectionsButtonProps {
  isCompleted: boolean
  needReview: boolean
}

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  position: relative;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;

  h1 {
    font-size: 22px;
    color: #263238;
    text-align: center;
    width: 100%;
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding: 5px 10px;
      border-radius: 5px;
      border: none;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      transition: background-color 0.2s;
    }
  }
`

export const SuggestionAnimation = styled.div`
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 100px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  span {
    color: #263238;
  }

  strong {
    font-size: 20px;
  }
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  margin-left: auto;

  button {
    font-family: 'Roboto', sans-serif;
  }

  .stepper-button-next {
    margin-left: 10px;
    margin-top: 0;

    &:disabled {
      opacity: 1;
      background-color: lightgray;
      color: #a1a1a1;
    }
  }
`

export const LoadingCalendar = styled.div`
  span {
    font-size: 22px;
    margin-left: 10px;
  }
`

export const SuggestionContainer = styled.div`
  position: absolute;
  right: 0;

  button {
    font-family: 'Roboto', sans-serif;
  }
`

export const LockButton = styled.button<LockButtonProps>`
  background: ${({ isCalendarLocked }) =>
    isCalendarLocked ? '#2b2b2a' : '#9E9E9E'};
`

export const SuggestionButton = styled.button<SuggestionButtonProps>`
  background: ${props => (props.suggestionActive ? '#FAB300' : '#65747B')};

  border-bottom: ${props =>
    props.suggestionActive ? 'none' : '2px solid #37474F'};
  border-top: ${props =>
    props.suggestionActive ? '2px solid #d19602' : 'none'};

  span {
    margin-left: 7px;
  }
`

export const WarningTurnOffSuggestionText = styled.span`
  color: #546e7a;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
`

export const OpenSectionsButton = styled.button<OpenSectionsButtonProps>`
  padding: 8px 10px;
  background: none;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${palette.primary.main};
  border: none;
  cursor: pointer;
  margin-right: 10px;

  span {
    background: #eeeeee;
    padding: 2px 7px;
    border-radius: 50%;
    color: ${palette.primary.main};
    margin-right: 5px;
  }

  ${({ needReview }) =>
    needReview &&
    css`
      background-color: ${palette.primary.main};
      color: white;
    `}

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      background-color: #e5e5e5;
      color: ${palette.primary.main};

      span {
        background: #00b362;
        color: white;
      }
    `}
`
