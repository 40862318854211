import { ChangeEvent } from 'react'

import Switch from 'react-switch'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons/'

import Tooltip from 'components/Tooltip'
import palette from 'theme/palette'

import { Container } from './styles'

interface ToggleItem {
  commissionValue: number | null | string
  checked: boolean
  toggleCommissionBase: () => void
  setFixedCommission: (value: number | string) => void
  dataCy: string
}

export const ToggleItem = ({
  commissionValue,
  checked,
  toggleCommissionBase,
  setFixedCommission,
  dataCy
}: ToggleItem) => {
  const commission = commissionValue ?? ''

  const onChangeCommissionInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = event.target.value.replace(',', '.') || ''
    const currentNumberAlreadyHasADot = formattedNumber
      .substring(0, formattedNumber.length - 1)
      .includes('.')
    const isTheLastCharacterADot = formattedNumber.slice(-1) === '.'
    const alreadyHasTwoDecimals = formattedNumber.split('.')[1]?.length > 2
    if (
      (isTheLastCharacterADot && !currentNumberAlreadyHasADot) ||
      (!isTheLastCharacterADot && !alreadyHasTwoDecimals)
    ) {
      const formattedCommissionValue = Number(formattedNumber)

      const newFixedCommissionValue =
        formattedCommissionValue > 100
          ? 100
          : isTheLastCharacterADot
          ? `${formattedCommissionValue}.`
          : formattedCommissionValue

      setFixedCommission(newFixedCommissionValue)
    }
  }
  return (
    <Container>
      <div>
        <Switch
          onChange={toggleCommissionBase}
          checked={checked}
          checkedIcon={false}
          uncheckedIcon={true}
          handleDiameter={8}
          height={12}
          width={25}
          offColor='#37474F'
          data-cy={dataCy}
          onColor={palette.primary.main}
        />

        <strong>Comissão base</strong>

        <ErrorOutlineIcon
          data-tip
          data-for='commission-tooltip'
          style={{ fontSize: 18, marginLeft: 5 }}
          color='disabled'
          fontSize='small'
        />

        <Tooltip
          id='commission-tooltip'
          content={
            <p>
              Ative caso seu vendedor receba uma comissão base sobre suas vendas
              até atingir a primeira meta.
            </p>
          }
        />
      </div>
      <div>
        <input
          type='text'
          pattern='[0-9]+([\.][0-9]+)?'
          maxLength={6}
          max={100}
          id='commission-input'
          value={commission}
          onChange={onChangeCommissionInput}
          disabled={!checked}
          placeholder='0,0'
          data-clarity-unmask='true'
        />
        <label>%</label>
      </div>
    </Container>
  )
}
