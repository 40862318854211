import styled from 'styled-components'
import palette from 'theme/palette'
import UTurnLeftSharpIcon from '@mui/icons-material/UTurnLeftSharp'
import { Person } from '@material-ui/icons'

interface ContainerProps {
  isModal: boolean
}

interface SellersAmountButton {
  isLocked: boolean
  areAllSellersWorking: boolean
}

export const Container = styled.div<ContainerProps>`
  border-bottom: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 7px;
  min-height: 75px;
  max-height: 75px;
  position: relative;

  border: ${({ isModal }) => isModal && `2px solid ${palette.primary.main}`};

  background-color: ${({ isModal }) => (isModal ? '#F0ECF4' : 'white')};

  box-shadow: ${({ isModal }) => isModal && '0 10px 20px rgba(0,0,0,0.2)'};

  z-index: ${({ isModal }) => isModal && '4'};

  .calendar-card-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .calendar-card-top-checkbox {
      cursor: pointer;

      &:hover {
        span {
          color: ${palette.primary.main};
        }
      }
      span {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #546e7a;
        transition: 0.2s ease-in-out;
      }

      .checkbox {
        color: #78909c;
        padding: 0;
        margin-right: 2px;
      }
    }

    .calendar-card-top-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0;

      button {
        background-color: #eceff1;
        padding: 1px 3px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 2px;
        display: flex;
        align-items: center;
        transition: 0.2s ease-in-out;

        &:hover {
          background: #cfd8dc;
          color: ${palette.primary.main};
        }

        span {
          color: #37474f;
          font-weight: 600;
          font-family: 'Roboto', sans-serif;
          font-size: 11px;
        }
      }
    }
  }

  .proft-suggestion {
    border-bottom: 2px solid #fab300;
  }

  .calendar-card-input {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    margin-top: 5px;
  }

  .disable-day {
    opacity: 0.5;
  }

  .modal-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 5;

    button {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      padding: 2px 5px;
      background-color: #37474f;
      border: none;
      border-radius: 5px;
      margin: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: 'Roboto';
      color: white;

      &:last-child {
        background-color: '#27AE60';
      }

      svg {
        font-size: 14px;
        color: #fff;
        margin-right: 3px;
      }
    }
  }

  .MuiInputBase-input {
    border: none !important;
  }

  .MuiFormControl-root {
    border: none !important;
  }
`

export const SellersContainer = styled.div`
  width: 80%;

  .sellers-top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #263238;
      font-size: 12px;
      font-weight: 500;
    }

    .sellers-counter {
      background-color: #eeeeee;
      padding: 5px;
      border-radius: 5px;
      font-weight: 600;
      color: #546e7a;
    }
  }

  .sellers-cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
    margin-top: 15px;

    .sellers-user-card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f6f8;
      padding: 5px 7px;
      border-radius: 200px;
      cursor: pointer;

      .sellers-user-card-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > img {
          border-radius: 50%;
          width: 25px;
          height: 25px;
          background-size: cover;
        }

        span {
          margin-left: 8px;
          color: #263238;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .seller-user-card-checkbox {
        color: #37474f;
        padding: 0;
      }
    }
  }
`

export const ArrowBackIcon = styled(UTurnLeftSharpIcon)`
  transform: rotate(90deg);
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 50%;
  opacity: 0.5;
`

export const PersonIcon = styled(Person)`
  margin-right: 2px;
  font-size: 16px;
`
export const SellersAmountButton = styled.button<SellersAmountButton>`
  opacity: 1;
  cursor: ${({ isLocked }) => (isLocked ? 'default' : 'pointer')};

  svg {
    color: ${({ areAllSellersWorking }) =>
      areAllSellersWorking ? palette.primary.main : '#37474f'};
  }

  span {
    color: ${({ areAllSellersWorking }) =>
      areAllSellersWorking ? palette.primary.main : '#37474f'};
  }
`
