import styled from 'styled-components'
import palette from 'theme/palette'

export const FooterContainer = styled.footer`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const SectionsPickerInfo = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;

  span {
    margin-left: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
  }
`

export const SubmitSectionButton = styled.button`
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: ${palette.primary.main};
  color: white;

  &:disabled {
    cursor: not-allowed;
    color: grey;
    background-color: lightgrey;
  }
`
