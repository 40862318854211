import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { getWeeksInMonth, startOfMonth, format, getDate } from 'date-fns'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { Link, animateScroll as scroll } from 'react-scroll'
import Lottie from 'react-lottie'
import ptBR from 'date-fns/locale/pt-BR'
import { CircularProgress, Modal } from '@material-ui/core'

import {
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  LockOpen as LockOpenIcon,
  LockOutlined as LockIcon
} from '@material-ui/icons'

import { ICurrentGoalProps } from '../..'
import { Content } from '../../styles'
import PromptModal from '../../../../PromptModal'
import { Calendar } from '../Calendar'
import {
  clearDisabledDays,
  setInitialCalendarValues
} from 'store/modules/calendarGoal/actions'
import palette from 'theme/palette'
import Tooltip from 'components/Tooltip'
import { IDays, IGoal, ISection, IUserWorking } from 'store/modules/goal/types'
import { getDay } from 'date-fns/esm'
import { ICalendarGoalProps } from 'store/modules/calendarGoal/types'
import { toast } from 'react-toastify'
import api from 'repositories/api'

import wandAnimation from 'assets/wandAnim.json'
import WandIcon from 'assets/Icons/WandIcon'

import {
  CreateIcon,
  EditButton,
  NextStepButton
} from 'views/Goals/components/AddGoal/style'

import {
  CalendarContainer,
  Footer,
  LoadingCalendar,
  LockButton,
  OpenSectionsButton,
  SuggestionAnimation,
  SuggestionButton,
  SuggestionContainer,
  TitleContainer,
  WarningTurnOffSuggestionText
} from './styles'

interface SelectorCalendarGoal {
  state: DefaultRootState
  calendarGoal: ICalendarGoalProps
}

interface IGoalDaysWeightSuggestion {
  days: number[]
  metadata: {
    fromStore: boolean
    soldExpected: number
    amountOfInputsConsidered: number
    month: string
  }
}

export interface IDwt {
  days: number[]
  weeks: number[]
  total: number
}

interface CalendarSectionProps {
  goal: ICurrentGoalProps
  days: IDays[]
  goalId: string
  defaultDays: IDays[]
  usersWorking: IUserWorking[]
  suggestionAnimationModal: boolean
  isLoadingSuggestionRequest: boolean
  basicCommissionView: boolean
  sectionsOpened: boolean
  loadingUpload: boolean
  sections: ISection[]

  updateGoal: (goal: Partial<ICurrentGoalProps>) => void
  updateCurrentGoal: (goal: Partial<ICurrentGoalProps>) => void
  setDeleteStep: (steps: string[]) => void

  setDays: Dispatch<SetStateAction<IDays[]>>
  setSections: Dispatch<SetStateAction<ISection[]>>
  setIsLoadingSuggestionRequest: Dispatch<SetStateAction<boolean>>
  setSuggestionAnimationModal: Dispatch<SetStateAction<boolean>>
  setIsCreatingSectionModalOpen: Dispatch<SetStateAction<boolean>>
}

export const CalendarSection = ({
  goal,
  days,
  goalId,
  defaultDays,
  usersWorking,
  suggestionAnimationModal,
  isLoadingSuggestionRequest,
  basicCommissionView,
  sectionsOpened,
  sections,
  loadingUpload,
  updateGoal,
  updateCurrentGoal,
  setDays,
  setSections,
  setIsLoadingSuggestionRequest,
  setSuggestionAnimationModal,
  setIsCreatingSectionModalOpen,
  setDeleteStep
}: CalendarSectionProps) => {
  const [suggestionModal, setSuggestionModal] = useState(false)
  const [totalDaysValue, setTotalDaysValue] = useState(0)
  const [isCalendarLocked, setIsCalendarLocked] = useState(true)

  // DWT => Day, Week, Total
  const [dwt, setDwt] = useState<IDwt>({
    days: new Array(7).fill(0),
    weeks: new Array(getWeeksInMonth(new Date(goal?.month))).fill(0),
    total: 0
  })

  const { days: calendarGoal } = useSelector<
    SelectorCalendarGoal,
    ICalendarGoalProps
  >(state => state.calendarGoal)

  const dispatch = useDispatch()

  const animationOptions = {
    // Animação da varinha de sugestão NeoPro
    loop: true,
    autoplay: true,
    animationData: wandAnimation
  }

  const getSuggestionWeights = async (): Promise<number[]> => {
    const isUsingSuggestion = goal?.daysWeightSuggestion.length > 0
    if (isUsingSuggestion) {
      return goal?.daysWeightSuggestion
    }
    try {
      setIsLoadingSuggestionRequest(true)
      const { data } = await api.axios.get<IGoalDaysWeightSuggestion>(
        `/goal/${goalId}/suggestion`
      )
      if (data) {
        updateGoal({ daysWeightSuggestion: { ...data.days } })
        return data.days
      }
      return []
    } catch (error) {
      return []
    } finally {
      setIsLoadingSuggestionRequest(false)
    }
  }

  const handleSuggestion = async () => {
    // Callback de preencher dias com a sugestão
    try {
      const suggestionDays = await getSuggestionWeights()
      if (suggestionDays) {
        const weights = suggestionDays ?? []

        if (weights.length === 0) {
          throw new Error()
        }

        const daysClone = [...days]

        // Auxiliares
        const lastWorkingDay = [...daysClone]
          .map(day => day.working)
          .lastIndexOf(true)

        let notWorkingGoalAmount = daysClone.reduce((acc, day, i) => {
          if (!day.working) acc += goal?.mainGoals.total * weights[i]
          return acc
        }, 0)

        const daysWorking = daysClone.filter(day => day.working).length
        const goalAmountToAdd = notWorkingGoalAmount / daysWorking

        // Faz a soma do próprio valor de cada dia mais a soma dos desabilitados/numero de dias
        const newDays = daysClone.map((day, i) => {
          if (day.working) {
            const goalValue =
              goal?.mainGoals.total * weights[i] +
              (i === lastWorkingDay ? notWorkingGoalAmount : goalAmountToAdd)

            const newDay = {
              ...day,
              goal: goalValue
            }
            notWorkingGoalAmount -= goalAmountToAdd
            return newDay
          } else {
            return day
          }
        })

        dispatch(setInitialCalendarValues(newDays))
        setDays(newDays)
        setDwt(generateDwt(newDays))
        updateGoal({
          lastDaysBackup: newDays,
          daysWeightSuggestion: suggestionDays,
          config: { ...goal?.config, suggestion: true }
        })
      }
    } catch (error) {
      console.log(error)
      if (error)
        toast.error('Não foi possível gerar uma sugestão para esse mês')
    } finally {
      setSuggestionAnimationModal(false)
    }
  }

  const hasUsersWorking = goal?.usersWorking.length > 0
  const hasMonthGoals = goal?.monthGoals.length > 0
  const hasFixedCommission = goal?.config?.commission?.fixed?.commission >= 0
  const hasCalendarDays = goal?.days.length > 0
  const hasSuggestion = goal?.config.suggestion
  const hasDaysWeightSuggestion = goal?.daysWeightSuggestion.length > 0
  const hasGoalDays = goal?.days.length > 0
  const hasDays = days.length > 0

  useEffect(() => {
    const everyDayHasTheSameGoal = days.every(day => day.goal === days[0].goal)
    const everyLastDaysBackupHasTheSameGoal = goal?.lastDaysBackup?.every(
      day => day.goal === days[0].goal
    )

    if (
      hasSuggestion &&
      hasDaysWeightSuggestion &&
      hasDays &&
      everyDayHasTheSameGoal &&
      everyLastDaysBackupHasTheSameGoal
    ) {
      const notEveryDaysWeightSuggestionAreTheSame =
        !goal?.daysWeightSuggestion?.every(
          weightSuggestion => weightSuggestion === goal?.daysWeightSuggestion[0]
        )

      if (notEveryDaysWeightSuggestionAreTheSame) {
        const newDays = days.map((day, i) => {
          return {
            ...day,
            goal: goal?.mainGoals.total * goal?.daysWeightSuggestion[i]
          }
        })

        dispatch(setInitialCalendarValues(newDays))
        setDays(newDays)
        setDwt(generateDwt(newDays))
        updateGoal({ lastDaysBackup: newDays })
      }
    }
  }, [
    goal?.daysWeightSuggestion,
    days,
    goal?.lastDaysBackup,
    goal?.config.suggestion
  ])

  useEffect(() => {
    if (hasSuggestion && hasDays) {
      handleSuggestion()
    }
  }, [hasSuggestion, days.length])

  // DEFINE OS DIAS QUE SERÃO RENDERIZADOS NO CALENDÁRIO
  useEffect(() => {
    const hasGoalMonth = !!goal?.month
    const hasGoalUsersWorking = goal?.usersWorking.length > 0
    if (hasGoalMonth && hasGoalUsersWorking) {
      const inCreatingGoal = goal?.days.length === 0
      if (inCreatingGoal) {
        // Caso esteja criando pega o default devolvido pelo backend
        dispatch(setInitialCalendarValues(defaultDays))
        setDwt(generateDwt(defaultDays))
        updateGoal({ lastDaysBackup: defaultDays })
        setDays(defaultDays)
      } else {
        // Caso esteja editando pega o valor antigo já existente
        dispatch(setInitialCalendarValues(goal?.days))
        setDwt(generateDwt(goal?.days))
        setDays(goal?.days)
        updateGoal({ lastDaysBackup: goal?.days })
      }
    }
  }, [goal?.month, goal?.usersWorking, defaultDays])

  const generateDwt = (days: IDays[]) =>
    days.reduce(
      (acc, day) => {
        const weekStartIndex = getDay(startOfMonth(new Date(goal?.month)))
        const dayIndex = weekStartIndex + (getDate(new Date(day.date)) - 1)
        const weekIndex = Math.floor(dayIndex / 7)

        acc.weeks[weekIndex] += day.goal
        acc.days[dayIndex - weekIndex * 7] += day.goal
        acc.total += day.goal

        return acc
      },
      {
        days: new Array(7).fill(0) as number[],
        weeks: new Array(getWeeksInMonth(new Date(goal?.month))).fill(
          0
        ) as number[],
        total: 0
      }
    )

  const differenceBetweenMonths =
    new Date(goal?.month).getFullYear() > new Date().getFullYear()
      ? -1
      : new Date().getMonth() - new Date(goal?.month).getMonth()

  const handleOffSuggestion = (newDays?: IDays[]) => {
    const daysWorking = [...(newDays ? newDays : days)].filter(
      day => day.working
    )

    let amount = goal?.mainGoals.total
    const part = parseFloat((amount / daysWorking.length).toFixed(2))

    const redistributedDays = [...days].map((day, i) => {
      if (day.working) {
        if (
          i !==
          days.findIndex(
            day =>
              JSON.stringify(day) ===
              JSON.stringify(daysWorking[daysWorking.length - 1])
          )
        ) {
          amount -= part
          return { ...day, goal: part }
        } else return { ...day, goal: amount }
      } else {
        return { ...day, goal: 0 }
      }
    })

    setDays(redistributedDays)
    setDwt(generateDwt(redistributedDays))
    updateGoal({
      lastDaysBackup: redistributedDays,
      config: { ...goal?.config, suggestion: false }
    })
  }

  const handleOpenSections = () => {
    // Callback de abertura do modal de sections
    setIsCreatingSectionModalOpen(true)
    if (goal?.sections) {
      setSections(goal.sections)
    }
  }

  const handleToggleLockedCalendar = () => {
    if (!isCalendarLocked) {
      // verifica se o calendarGoal esta populado, caso nao esteja ele chama a função
      // baseado no goal?.config.suggestion e popula o calendarGoal
      const hasNoCalendarGoal = !(calendarGoal.length > 0)
      if (hasNoCalendarGoal) {
        goal?.config.suggestion ? handleSuggestion() : handleOffSuggestion()
      } else {
        setDwt(generateDwt(calendarGoal))
        setDays([...calendarGoal])
        dispatch(clearDisabledDays())
      }
    }
    setIsCalendarLocked(prev => !prev)
  }

  const handleToggleNeoProSuggestion = () => {
    if (goal?.config.suggestion) {
      handleOffSuggestion()
    } else {
      setSuggestionModal(prev => !prev)
    }
  }

  const handleSubmitCalendar = () => {
    const newSections = goal.sections ? goal.sections : sections

    updateCurrentGoal({
      lastDaysBackup: days,
      days: days,
      sections: newSections
    })
    scroll.scrollToBottom()
  }

  const handleTurnOnSuggestion = () => {
    setSuggestionModal(false)
    setSuggestionAnimationModal(true)

    handleSuggestion()
  }

  const canShowCalendarSection =
    (hasUsersWorking && hasMonthGoals && hasFixedCommission) || hasCalendarDays

  const isAFutureMonth = differenceBetweenMonths > -1

  const hasFilledDaysAndSections =
    goal?.days?.length > 0 && goal?.sections.length > 0

  const monthLongFormat = goal?.month
    ? format(new Date(goal?.month), 'MMMM', {
        locale: ptBR
      })
    : '...'

  const daysGoalSumIsDifferentFromStoreGoal =
    Number(totalDaysValue).toFixed(2) !==
    Number(goal?.mainGoals?.total).toFixed(2)

  const isEditingSection = goal?.sections.length >= 2 && !sectionsOpened

  const isNextStepButtonDisabled =
    daysGoalSumIsDifferentFromStoreGoal || isEditingSection

  return (
    <>
      {canShowCalendarSection && (
        <Content isFilled={goal?.days?.length > 0 && goal?.sections.length > 0}>
          {(isLoadingSuggestionRequest && !suggestionAnimationModal) ||
          loadingUpload ? (
            !basicCommissionView && (
              <LoadingCalendar>
                <CircularProgress size={20} />
                <span>Calculando dados do Calendário...</span>
              </LoadingCalendar>
            )
          ) : (
            <CalendarContainer>
              <TitleContainer>
                <h1>Meta da loja para {monthLongFormat}</h1>

                <SuggestionContainer>
                  {hasSuggestion && isAFutureMonth && (
                    <LockButton
                      onClick={handleToggleLockedCalendar}
                      isCalendarLocked={isCalendarLocked}
                    >
                      {isCalendarLocked ? <LockIcon /> : <LockOpenIcon />}
                    </LockButton>
                  )}

                  <SuggestionButton
                    suggestionActive={goal?.config.suggestion}
                    data-cy='toggleNeoProSuggestionButton'
                    onClick={handleToggleNeoProSuggestion}
                  >
                    <WandIcon color={'#fff'} size={10} />
                    <span>
                      Sugestão NeoPro
                      {hasSuggestion ? ' Ativada' : ' Desativada'}
                    </span>
                  </SuggestionButton>

                  <ErrorOutlineOutlinedIcon
                    fontSize='small'
                    style={{ color: '#9E9E9E', marginLeft: 7 }}
                    data-tip
                    data-for='tooltip-neopro-suggestion'
                  />

                  <Tooltip
                    content={
                      <p>
                        As metas dos dias serão ajustadas para a sua loja pela
                        nossa Inteligência Artificial.
                      </p>
                    }
                    id='tooltip-neopro-suggestion'
                  />

                  <Modal open={suggestionModal}>
                    <PromptModal
                      icon={<WandIcon color={palette.primary.main} size={20} />}
                      onClose={() => setSuggestionModal(false)}
                      title='Aplicar a Sugestão NeoPro?'
                      description='
            Nossa Inteligência Artificial analisa sua loja e mercado em vários aspectos.
            A partir desses dados, é calculada uma Sugestão NeoPro de meta do mês.'
                      onLeft={() => setSuggestionModal(false)}
                      onRight={handleTurnOnSuggestion}
                      rightTitle='Fazer a mágica'
                    >
                      <WarningTurnOffSuggestionText>
                        Atenção: os valores já inseridos nos dias serão
                        perdidos!
                      </WarningTurnOffSuggestionText>
                    </PromptModal>
                  </Modal>
                  <Modal open={suggestionAnimationModal}>
                    <SuggestionAnimation>
                      <Lottie
                        options={animationOptions}
                        width={200}
                        height={200}
                      />
                      <span>
                        <strong>Fazendo a mágica...</strong> <br />
                        Calculando sugestão
                      </span>
                    </SuggestionAnimation>
                  </Modal>
                </SuggestionContainer>
              </TitleContainer>

              <Calendar
                goal={goal}
                dwt={dwt}
                setDwt={setDwt}
                days={days}
                setDays={setDays}
                weekStartIndex={getDay(new Date(goal?.month))}
                month={new Date(goal?.month)}
                mainGoalValue={goal?.mainGoals?.total}
                setTotalDaysValue={setTotalDaysValue}
                updateGoal={updateGoal}
                suggestion={goal?.config.suggestion}
                isCalendarLocked={isCalendarLocked}
                usersWorking={usersWorking}
              />

              <Footer>
                {!hasGoalDays && (
                  <>
                    {goal?.sections.length > 1 && (
                      <OpenSectionsButton
                        needReview={!sectionsOpened}
                        isCompleted={
                          sectionsOpened && goal?.sections.length > 0
                        }
                        onClick={handleOpenSections}
                      >
                        <span>{goal?.sections?.length || 0}</span>
                        {goal?.sections?.length === 1 ? 'Período' : 'Períodos'}
                      </OpenSectionsButton>
                    )}

                    <div data-tip data-for={'tooltip-distribute-issue'}>
                      <NextStepButton
                        data-cy='calendarNextButton'
                        disabled={isNextStepButtonDisabled}
                        onClick={handleSubmitCalendar}
                        style={{ margin: 0 }}
                      >
                        Avançar
                      </NextStepButton>
                    </div>

                    {(isEditingSection ||
                      daysGoalSumIsDifferentFromStoreGoal) && (
                      <Tooltip
                        id='tooltip-distribute-issue'
                        content={
                          <p>
                            {daysGoalSumIsDifferentFromStoreGoal
                              ? 'É necessário que a soma das metas diárias resultem exatamente no total definido para a meta da loja!'
                              : !sectionsOpened &&
                                'Você precisa revisar a criação de períodos para prosseguir!'}
                          </p>
                        }
                      />
                    )}
                  </>
                )}
              </Footer>
            </CalendarContainer>
          )}
        </Content>
      )}

      {hasFilledDaysAndSections && !isLoadingSuggestionRequest && (
        <Link to='calendar' offset={-170} smooth={true}>
          <EditButton onClick={() => setDeleteStep(['days'])}>
            Alterar
            <CreateIcon />
          </EditButton>
        </Link>
      )}
    </>
  )
}
