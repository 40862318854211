import { Dispatch, SetStateAction } from 'react'

import { IDays, ISection } from 'store/modules/goal/types'

import { NoTable, SectionsContainer, Table, TitleContainer } from './styles'
import { Section } from '../Section'

export interface SectionProps {
  days: number
  goal: number
  start: Date
  end: Date
}

interface SectionTableProps {
  sections: ISection[]
  setSections: Dispatch<SetStateAction<ISection[]>>
  days: IDays[]
}

export const SectionsTable = ({
  sections,
  setSections,
  days
}: SectionTableProps): JSX.Element => {
  const handleDeleteSections = (index: number) => () => {
    const hasMoreThanOneSection = index > 0
    if (hasMoreThanOneSection) {
      const updatedSections = [...sections]
      updatedSections.splice(index + 1, sections.length - index)
      updatedSections[updatedSections.length - 1].end = null

      setSections(updatedSections)
    } else {
      setSections([{ start: new Date(days[0].date).toISOString(), end: null }])
    }
  }

  const initializeSections = (sections: ISection[]) => {
    return sections.reduce((acc: SectionProps[], { start, end }) => {
      if (end) {
        acc.push({
          start: typeof start === 'string' ? new Date(start) : start,
          end: typeof end === 'string' ? new Date(end) : end,
          goal: 0,
          days: 1
        })
      }
      return acc
    }, [])
  }

  const updateSectionGoals = (sections: SectionProps[], days: IDays[]) => {
    return days.reduce((updatedSections: SectionProps[], day) => {
      if (day.working) {
        updatedSections = updatedSections.map(section => {
          const dayDate = +new Date(day.date)
          const startDate = +new Date(section.start)
          const endDate = section.end ? +new Date(section.end) : null

          if (dayDate >= startDate && endDate && dayDate <= endDate) {
            section.goal += day.goal
            section.days++
          }
          return section
        })
      }
      return updatedSections
    }, sections)
  }

  return (
    <SectionsContainer>
      <TitleContainer>
        <span>#</span>
        <span>Meta do Período</span>
        <span>Dias úteis</span>
      </TitleContainer>

      {sections[0]?.end ? (
        <Table>
          {updateSectionGoals(initializeSections(sections), days).map(
            (section, index) => (
              <Section
                key={section.start.toString()}
                handleDeleteSections={handleDeleteSections}
                index={index}
                section={section}
              />
            )
          )}
        </Table>
      ) : (
        <NoTable>Insira os períodos no calendário ao lado!</NoTable>
      )}
    </SectionsContainer>
  )
}
