import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '@material-ui/core'

import { SectionPicker } from '../SectionPicker'
import { IDays, ISection } from 'store/modules/goal/types'
import { ICurrentGoalProps, SectionList } from '../..'
import { CalendarContainer, Container, Main } from './styles'
import { ModalHeader } from './components/ModalHeader'
import { SectionsTable } from './components/SectionsTable'
import { ModalFooter } from './components/ModalFooter'

interface IFormattedSections {
  start: Date
  end: Date | null
  goal: number
  daysWorking: number
}

interface SectionPickerModal {
  isOpen: boolean
  goal: ICurrentGoalProps
  sections: ISection[]
  days: IDays[]

  updateGoal: (goal: any) => void
  setSections: Dispatch<SetStateAction<ISection[]>>
  setSectionsOpened: Dispatch<SetStateAction<boolean>>
  setIsCreatingSectionModalOpen: Dispatch<SetStateAction<boolean>>
}

export const SectionPickerModal = ({
  isOpen,
  goal,
  days,
  sections,
  setSections,
  setIsCreatingSectionModalOpen,
  setSectionsOpened,
  updateGoal
}: SectionPickerModal) => {
  const [sectionsList, setSectionsList] = useState<SectionList[]>([]) // Sections com valores dos períodos e qtd de dias

  //  DEFINE VALORES NO DWT E A LISTAGEM DE PERÍODOS DENTRO DO MODAL DE SECTION PICKER
  useEffect(() => {
    const hasNoGoalSections = !(goal.sections.length > 0)

    const getSectionsWithStartAndEnd = () => {
      return sections.filter(section => section.start && section.end)
    }

    const formatSection = (section: ISection) => ({
      start: new Date(section.start),
      end: section.end ? new Date(section.end) : null,
      goal: 0,
      daysWorking: 0
    })

    const updateFormattedSections = (
      formattedSectionsList: IFormattedSections[],
      day: any
    ) => {
      if (day.working) {
        formattedSectionsList.forEach(section => {
          const isNotTheLastSectionNorTheFirst =
            new Date(day.date) >= section.start &&
            section.end &&
            new Date(day.date) <= section.end

          if (isNotTheLastSectionNorTheFirst) {
            section.goal += day.goal
            section.daysWorking++
          }
        })
      }
    }

    if (hasNoGoalSections && sections && days.length > 0) {
      const sectionsWithStartAndEnd = getSectionsWithStartAndEnd()
      const formattedSectionsList: IFormattedSections[] =
        sectionsWithStartAndEnd.map(formatSection)

      days.forEach(day => updateFormattedSections(formattedSectionsList, day))

      updateGoal({ sectionsList: formattedSectionsList, days })
      setSectionsList(formattedSectionsList)
    }
  }, [days, sections])

  useEffect(() => {
    const hasSections = goal.sections.length >= 1

    const formatGoalSections = () => {
      return goal?.sections?.map(section => ({
        start: new Date(section?.start).toISOString(),
        end: section?.end ? new Date(section.end).toISOString() : null
      }))
    }

    if (hasSections) {
      const formattedSections = formatGoalSections()
      setSections(formattedSections)
    } else {
      setSections([{ start: new Date(days[0]?.date).toISOString(), end: null }])
    }
  }, [goal.sections])

  return (
    <Modal open={isOpen}>
      <Container>
        <ModalHeader
          setIsCreatingSectionModalOpen={setIsCreatingSectionModalOpen}
        />

        <Main>
          <CalendarContainer>
            <SectionPicker
              days={days}
              sections={sections}
              setSections={setSections}
            />
          </CalendarContainer>

          <SectionsTable
            days={days}
            sections={sections}
            setSections={setSections}
          />
        </Main>

        <ModalFooter
          goal={goal}
          sections={sections}
          sectionsList={sectionsList}
          setIsCreatingSectionModalOpen={setIsCreatingSectionModalOpen}
          setSectionsOpened={setSectionsOpened}
          updateGoal={updateGoal}
        />
      </Container>
    </Modal>
  )
}
