import { TableCell as TableCellMUI } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import styled, { css } from 'styled-components'

interface GoalNumberProps {
  isTheFirstMonthGoal: boolean
}

export const ErrorOutlineIcon = styled(ErrorOutline)`
  color: #c4c4c4;
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: -5px;
`

export const TableCell = styled(TableCellMUI)`
  max-width: 30px;
`

export const GoalNumber = styled.p<GoalNumberProps>`
  background: #e2e4e9;
  color: #65747b;
  display: flex;
  font-weight: bold;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: default;
  border-radius: 50%;

  ${({ isTheFirstMonthGoal }) =>
    isTheFirstMonthGoal &&
    css`
      border: 2px solid #ffc700;
    `}
`
