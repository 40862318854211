import styled from 'styled-components'

export const Container = styled.div`
  background: #f5f6f8;
  border-radius: 10px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-size: 14px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-right: 20px;

    strong {
      margin-left: 10px;
      font-size: 12px;
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    input {
      width: 80px;
      outline: none;
      text-align: center;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 5px 15px 5px 10px;
      font-weight: 600;
      font-family: 'Roboto', sans-serif;

      &:disabled {
        background-color: #e2e4e9;
        border-color: transparent;
        color: #263238;
        cursor: not-allowed;
      }
    }

    label {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 10px;
      font-weight: 600;
      font-size: 14px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  strong {
    color: #263238;
  }
`
