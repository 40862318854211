import { useEffect, useState } from 'react'

import { DefaultRootState, useSelector } from 'react-redux'
import { Edit, Warning } from '@material-ui/icons'
import { formatPrice, formatToPercentage } from 'utils/format'
import moment from 'moment'
import api from 'repositories/api'

import { ReviewCard } from '../ReviewCard'
import { ReviewItem } from '../ReviewItem'
import { Goal } from '../Goal'
import {
  IDays,
  IGoal,
  IInitialCommission,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'
import { GoalPeriodsSection } from '../GoalPeriodsSection'
import { IInputProps } from 'store/modules/input/types'
import { ISeller, IStoreProps } from 'store/modules/store/types'

import {
  Container,
  GoalRow,
  SalesPerSellerHeader,
  SellersGoalTable,
  WarningModalContainer
} from './styles'

interface SelectorInput {
  state: DefaultRootState
  input: IInputProps
}

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface AccGoalProps {
  seller: IUserWorking
  goals: IMonthGoal[]
}

interface IUwwSellerGoalProps {
  remainingGoal: number
  sold: number
  lastInput: string
  accGoalAfterExit?: number
}

interface IDaysWithWeight extends IDays {
  dayWeight: number
}

export interface IInitialCommissionProps {
  _id: string
  active: boolean
  dsr: boolean
  goalWeight: number
  userId: string
  weightLocked: boolean
  goals: {
    _id: string
    bonus: number
    bonusPercentage: number
    commission: number
    full: number
    initialCommission: IInitialCommission
    name: string
    type: string
    typeBonus: string
    value: number
  }[]
}

interface GoalSectionProps {
  goal: IGoal
  setActiveStep: () => void
}

export const GoalSection = ({ goal, setActiveStep }: GoalSectionProps) => {
  const [accGoal, setAccGoal] = useState<AccGoalProps[]>([])
  const [usersCommissionInfo, setUsersCommissionInfo] = useState<
    IInitialCommissionProps[]
  >([])
  const [users, setUsers] = useState<ISeller[]>([])

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { inputList } = useSelector<SelectorInput, IInputProps>(
    state => state.input
  )

  // CurrentMonthGoal filtrado
  const currMonth = {
    days: goal.days,
    usersWorking: goal.usersWorking,
    updatedAt: goal.updatedAt,
    mainGoals: goal.mainGoals,
    monthGoals: goal.monthGoals,
    distribute: goal.distribute,
    month: goal.month,
    activeUsers: goal.usersWorking.filter(user => user.active)?.length
  }

  const daysWithWeight = currMonth?.days.map(day => ({
    ...day,
    dayWeight:
      day.users?.length > 0
        ? day.users.reduce(
            (acc, seller) =>
              (acc +=
                currMonth.usersWorking.find(
                  user => user.userId?._id === seller.userId
                )?.goalWeight || 0),
            0
          )
        : 1 // Se não tiver nenhum vendedor, o peso do dia é 1
  }))

  const goalSeller = (
    actualSeller: IUserWorking,
    uwSellersGoal?: IUwwSellerGoalProps[]
  ): AccGoalProps | number => {
    // Filtra os dias que o vendedor está ativo
    const currUserDays =
      daysWithWeight.length > 0
        ? daysWithWeight.filter(day => {
            const usersId = day.users.map(usr => usr.userId)
            return usersId.includes(actualSeller.userId?._id)
          })
        : []

    // Essa função tem dupla funcionalidade:
    // Vendedor Inativo => Ela calcula a meta remanescente dele até o ultimo dia de venda
    // Vendedor Ativo => É o calculo da meta para o vendedor ativo, conforme cada vendedor inativo após a data de seus ultimos lançamentos.
    const addAccGoal = (lastInput?: string) => {
      return (
        (currUserDays.length > 0 &&
          currUserDays?.reduce((acc, day) => {
            acc +=
              ((!uwSellersGoal &&
                day.users.some(
                  seller => seller.userId === actualSeller.userId?._id
                )) ||
                (uwSellersGoal &&
                  currUserDays.some(modalDay => modalDay.date === day.date))) &&
              // Se o vendedor não tiver lastInput (ultimo dia de venda), significa que é um vendedor inativo, então irá calcular sua meta remanescente a partir do ultimo dia de venda
              // Se tiver ultimo dia de venda e se a data do ultimo dia de venda for menor que a data do dia atual, significa que o vendedor não vendeu nada, então a meta remanescente é a meta do vendedor
              (!lastInput ||
                (lastInput && new Date(day.date) > new Date(lastInput)))
                ? day.goal *
                  (uwSellersGoal ? 1 : actualSeller.goalWeight / day.dayWeight)
                : 0
            return acc
          }, 0)) ||
        0
      )
    }

    // Verificação é pra saber se a função ta sendo utilizada para o vendedor ativo ou inativo
    // Caso não tenha essa variavel (uwSellersGoal) é porque o vendedor ainda é um inativo.
    // uwSellersGoal => É a meta remanescente dos vendedores inativos
    if (uwSellersGoal) {
      const uwSellersGoalAfterExit =
        uwSellersGoal.length > 0 &&
        uwSellersGoal.map(uwSeller => {
          uwSeller.accGoalAfterExit = addAccGoal(uwSeller?.lastInput)
          return uwSeller
        })

      // Calcula todas as metas com suas respectivas distribuições (se for o modelo de meta distribuida) e também a distribuição dos vendedores inativos (complemento)
      const daysGoalAcc = (
        mainGoal: IMonthGoal,
        currGoal: IMonthGoal,
        i = 0,
        recalculatedGoal = 0
      ): number => {
        if (currUserDays && currUserDays.length > 0) {
          const complementSum = (
            day: IDaysWithWeight,
            dayWeight: number,
            i: number
          ) => {
            if (
              (uwSellersGoalAfterExit &&
                !uwSellersGoalAfterExit[i]?.lastInput) ||
              (uwSellersGoalAfterExit &&
                day.date > uwSellersGoalAfterExit[i]?.lastInput)
            ) {
            }
            return uwSellersGoalAfterExit &&
              uwSellersGoalAfterExit.length > 0 &&
              (!uwSellersGoalAfterExit[i]?.lastInput ||
                day.date > uwSellersGoalAfterExit[i]?.lastInput) &&
              typeof uwSellersGoalAfterExit[i].accGoalAfterExit !== 'undefined'
              ? // (((Residual Inicial * (Metal indivudal atual / Meta individual principal)) - Venda total removido) * Novo peso do dia * Peso do vendedor no dia)
                (uwSellersGoalAfterExit[i].remainingGoal *
                  (currGoal.value / mainGoal.value) -
                  uwSellersGoalAfterExit[i].sold) *
                  dayWeight *
                  (day.goal / uwSellersGoalAfterExit[i].accGoalAfterExit!)
              : 0
          }

          // Se não possuir usuário ativo, o peso será 1
          const dayWeight =
            currMonth.activeUsers === 0
              ? 1
              : actualSeller.goalWeight / currMonth.activeUsers

          if (i === 0) {
            recalculatedGoal += currUserDays.reduce((acc, day) => {
              acc += currMonth.distribute
                ? day.goal *
                    (currGoal.value / mainGoal.value) *
                    (actualSeller.goalWeight / day.dayWeight) +
                  complementSum(day, dayWeight, i)
                : ((currGoal.value * day.goal) / currMonth.mainGoals.total) *
                  actualSeller.goalWeight

              return acc
            }, 0)
          } else {
            recalculatedGoal += currUserDays.reduce((acc, day) => {
              return (acc += complementSum(day, dayWeight, i))
            }, 0)
          }

          return uwSellersGoalAfterExit &&
            uwSellersGoalAfterExit.length > 0 &&
            uwSellersGoalAfterExit.length - 1 !== i
            ? daysGoalAcc(mainGoal, currGoal, i + 1, recalculatedGoal)
            : recalculatedGoal
        } else {
          return 0
        }
      }

      return {
        seller: actualSeller,
        goals: currMonth.monthGoals.map(currGoal => ({
          ...currGoal,
          value: daysGoalAcc(currMonth.monthGoals[0], currGoal)
        }))
      }
    } else {
      // Meta acumulada do inativo
      return addAccGoal()
    }
  }

  const getMonthGoals = () => {
    const sellerInactiveWithSold: IUwwSellerGoalProps[] = []
    const result = currMonth.usersWorking // Ordena os vendedores por ativo ou inativo
      .sort((a, b) => Number(a.active) - Number(b.active))
      .reduce((acc, curr) => {
        // Informações do vendedor INATIVO
        if (!curr.active) {
          // Se for um vendedor inativo, irei calcular o quanto ele vendeu e o seu remanescente
          let sellerSold = 0
          const inputsFromUwSeller = inputList.filter(input => {
            // Conferir se estão por ordem decrescente de data
            const actualMonth =
              moment(input.date).month() === moment(currMonth.month).month()

            // Vendedor atual do input
            const currSeller = input.sellers.find(
              seller =>
                seller.sellerId?._id === curr.userId?._id && seller.sold > 0
            )

            // Caso tenha mês atual e vendedor do input, é acrescido ao total de vendas do vendedor
            if (actualMonth && currSeller) {
              sellerSold += currSeller.sold
              return input
            }
          })

          // Meta remanescente do vendedor (meta do vendedor menos o total de vendas do vendedor)
          const remainingGoal = goalSeller(curr) as number

          //  Calcular a meta restante para cada vendedor, caso a remanescente seja maior do que o que foi vendido por ele
          if (sellerSold < remainingGoal) {
            sellerInactiveWithSold.push({
              remainingGoal,
              sold: sellerSold,
              lastInput: inputsFromUwSeller[0]?.date
            })
          }
        } else {
          const goal = goalSeller(curr, sellerInactiveWithSold) as AccGoalProps
          acc.push(goal)
        }
        return acc
      }, [] as AccGoalProps[])

    if (result) {
      setAccGoal(result)
      return result
    }
  }

  useEffect(() => {
    if (store) {
      // Usuários ativos no mês e que estão vinculados a loja
      setUsers(
        store.users.filter(({ _id }) =>
          goal.usersWorking.some(
            user => user.active && user.userId?._id === _id
          )
        )
      )
    }
  }, [store, goal.usersWorking])

  useEffect(() => {
    if (inputList) {
      getMonthGoals()
    }
  }, [inputList])

  const hasFixedCommission = goal.config?.commission?.fixed?.commission > 0
  const commissionValuePercentage = formatToPercentage(
    goal.config?.commission?.fixed?.commission * 100
  )

  const workingDays = goal.days?.reduce((acc, curr) => {
    return (acc += Number(curr.working))
  }, 0)

  const recommendedSellerGoalValue = `${goal.monthGoals[0].name} ${formatPrice(
    goal.monthGoals[0].value
  )}`

  const storeGoal = formatPrice(goal.mainGoals.total)

  useEffect(() => {
    const getUsersCommissionGoalInfo = async () => {
      const response = await api.axios.get(
        `report/goal/${goal._id}/users-commission-goals`
      )

      if (!!response.data) {
        setUsersCommissionInfo(response.data)
      }
    }

    getUsersCommissionGoalInfo()
  }, [goal._id])

  const goalMultiplier = goal.monthGoals[0].value * users.length
  const totalGoalValue = goal.mainGoals.total

  const showWarningModal =
    goalMultiplier < totalGoalValue - 0.03 ||
    goalMultiplier > totalGoalValue + 0.03

  const goalPerSeller = formatPrice(goal.mainGoals.total / users.length)

  const userCommissionActive = usersCommissionInfo?.find(
    userCommission => userCommission.active
  )
  const goals = userCommissionActive?.goals?.map(goal => ({
    name: goal.name,
    value: goal.value
  }))

  return (
    <ReviewCard
      name='Meta'
      items={
        <>
          {hasFixedCommission && (
            <ReviewItem
              title='Comissão base'
              spanDataCy='commissionBaseSpanReview'
              value={commissionValuePercentage}
            />
          )}

          <ReviewItem title='Dias de loja aberta' value={workingDays} />

          {/* Verifica se o valor da meta multiplicado pela quantidade de
            usuários ativos está entre a margem de erro (0.03) do valor total da
            meta. */}
          {showWarningModal && (
            <WarningModalContainer>
              <Warning style={{ color: '#700c0c' }} />
              <p>
                Aconselhamos que o valor da Meta Principal para os vendedores (
                <strong>{recommendedSellerGoalValue}</strong>) seja o valor da
                Meta da loja dividida pelo número de vendedores que participarão
                da meta. Ex.:
                <strong>
                  {`${storeGoal} / ${users.length} = ${goalPerSeller}`}
                </strong>
                <br />
                <br />
                <strong>
                  Edite clicando no <Edit style={{ fontSize: 13 }} /> de Meta
                  para alterar as Metas individuais dos vendedores.
                </strong>
              </p>
            </WarningModalContainer>
          )}

          <Container>
            <SellersGoalTable>
              <SalesPerSellerHeader>
                <h2>Meta por vendedor</h2>
                <div>
                  {goals &&
                    goals.map((goal, index) => {
                      const goalName =
                        goal.name.length > 15
                          ? goal.name.substring(0, 15 - 3) + '...'
                          : goal.name

                      return (
                        <GoalRow
                          showWarningModal={showWarningModal && index === 0}
                          key={index}
                        >
                          <h1>{goalName}</h1>
                          <span>{formatPrice(goal.value)}</span>
                        </GoalRow>
                      )
                    })}
                </div>
              </SalesPerSellerHeader>

              <Goal
                accGoal={accGoal}
                goal={goal}
                store={store}
                usersCommissionInfo={usersCommissionInfo}
              />
            </SellersGoalTable>
          </Container>

          <GoalPeriodsSection goal={goal} />
        </>
      }
      setActiveStep={setActiveStep}
    />
  )
}
