import styled from 'styled-components'
import { Select } from '@material-ui/core'

import palette from 'theme/palette'

interface CustomSelectProps {
  $hasNoBonus: boolean
  hasBonusPercentage: string | boolean
}

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 20px;
  width: 60vw;
  max-width: 800px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;

  margin: auto;
  transform: translate(-50%, -50%);
  z-index: 99;

  .goal-modal-content {
    width: 100%;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    color: #c4c4c4;
    cursor: pointer;
    z-index: 10;
  }

  .goal-modal-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;

    .goal-modal-title {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;

      h1 {
        margin: 0;
        font-size: 18px;
        color: #263238;
      }

      span {
        font-size: 12px;
        color: #546e7a;
        margin: 10px;
      }
    }
  }

  .goal-modal-form {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    .goal-modal-form-row {
      margin: 30px auto;

      .percentage {
        margin-right: 10px;
        padding-bottom: 7px;
        border-bottom: 1px solid #c4c4c4;
        color: ${palette.primary.main};
      }
    }
    .suffix {
      padding-bottom: 7px;
      padding-right: 5px;
      border-bottom: 1px solid #c4c4c4;
      margin-right: -12px;
      color: ${palette.primary.main};
    }

    div {
      color: #546e7a;
      text-align: center;

      .bonus-input {
        position: relative;
        display: flex;

        .bonus-select {
          position: absolute;
          right: 0;
          bottom: 0;
          padding-bottom: 11px;
          margin-left: 3px;
        }

        .bonus-input-percent {
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
        }
      }

      select {
        width: 120px !important;
      }

      input,
      select {
        background-color: white;
        border: none;
        width: 120px;
        border-bottom: 1px solid #c4c4c4;
        margin: 0 10px;
        font-weight: 500;
        padding-bottom: 7px;
        outline: none;
        color: ${palette.primary.main};
        font-size: 16px;
        text-align: center;
        font-family: 'Roboto', sans-serif;

        &::placeholder {
          color: #e3e3e3;
        }

        option {
          background-color: none;
        }

        &:focus {
          border-bottom: 1px solid ${palette.primary.main};
        }
      }

      input:hover {
        border-width: 2px;
      }
    }

    .MuiFormControl-root {
      width: 120px;
      color: white;
      vertical-align: middle !important;
    }

    .MuiSelect-select h4 {
      display: none;
    }

    .MuiSelect-select h5 {
      font-size: 14px;
    }

    .MuiSelect-select span {
      color: ${palette.primary.main} !important;
    }

    .MuiSelect-root {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiInputBase-input {
      width: 100%;
      margin: 0px !important;
      padding-bottom: 10px;
    }

    .MuiInput-root {
      margin: 0 10px;
      width: 120px;
      color: ${palette.primary.main};
    }

    .MuiInput-underline::before {
      border-color: #c4c4c4 !important;
    }

    .MuiSelect-root {
      background: none !important;
    }
  }

  .goal-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    .sellers-count-card {
      padding: 10px;
      border-radius: 8px;
      background: #f1f1f1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #7a94a1;
      max-width: 50%;

      div {
        margin-left: 5px;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }

    .goal-infos {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-weight: 500;
      font-size: 12px;
      color: #7a94a1;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 10px;
      }

      span {
        margin: 0;
      }

      .total-commission {
        color: #546e7a;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 5px;
      }
    }

    button {
      margin-top: 0px;
      margin-left: 20px;
      padding: 10px 12px;
      background-color: ${palette.primary.main};
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Roboto', sans-serif;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
`

export const CustomSelect = styled(Select)<CustomSelectProps>`
  max-width: ${({ $hasNoBonus }) => ($hasNoBonus ? '150px' : '20px')};
  min-width: ${({ $hasNoBonus }) => ($hasNoBonus ? '150px' : '20px')};
  top: ${({ hasBonusPercentage }) =>
    typeof hasBonusPercentage !== 'string' &&
    (hasBonusPercentage ? '12px' : '14px')};
`
