import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { getDate } from 'date-fns/esm'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import { IDays, ISection } from 'store/modules/goal/types'

import { CurrentSectionLengthContainer, Picker } from './styles'

interface IDataSections {
  end: Date | null
  start: Date
}

interface SectionPickerProps {
  days: IDays[]
  sections: ISection[]
  setSections: Dispatch<SetStateAction<ISection[]>>
}

export const SectionPicker = ({
  days,
  sections,
  setSections
}: SectionPickerProps) => {
  const [disabled, setDisabled] = useState(false)
  const [hoverDay, setHoverDay] = useState<ISection | null>(null)

  const lastDayInMonth = new Date(
    new Date(days[days.length - 1].date).setHours(12)
  )
  const lastSection = sections[sections.length - 1]

  const handleDayClick = (day: Date) => {
    day.setHours(12)
    const newSections = [...sections]
    const isClickedDayAfterStartDay =
      +new Date(day) > +new Date(lastSection.start)

    if (isClickedDayAfterStartDay) {
      newSections[newSections.length - 1].end = day.toISOString()

      const isEndDateSameAsStartDate =
        +new Date(day) === +new Date(lastDayInMonth)
      const isNotLastDayInMonth = +new Date(day) < +new Date(lastDayInMonth)

      if (isEndDateSameAsStartDate) {
        setDisabled(true)
      }

      if (isNotLastDayInMonth) {
        const startDate = new Date(new Date(day).setDate(day.getDate() + 1))
        newSections.push({
          start: startDate.toISOString(),
          end: null
        })
      }

      setSections(newSections)
    }
  }

  useEffect(() => {
    setHoverDay({
      start: new Date(lastSection.start).toISOString(),
      end: lastSection.end ? new Date(lastSection.end).toISOString() : null
    })

    if (!lastSection.end) {
      setDisabled(false)
    }

    const hasPickedAllDaysInCalendar =
      lastSection.end &&
      +new Date(lastSection.end) === +new Date(lastDayInMonth)

    if (hasPickedAllDaysInCalendar) {
      setDisabled(true)
    }
  }, [sections])

  const onMouseEnter = (day: Date) => {
    const isHoveredDayAfterLastStartDay = day > new Date(lastSection.start)
    if (isHoveredDayAfterLastStartDay) {
      setHoverDay(prevState => {
        if (!prevState?.start) {
          return { start: lastSection.start, end: day.toISOString() }
        }
        return { ...prevState, end: day.toISOString() }
      })
    }
  }

  const selectedDays = [
    ...sections
      .filter(section => section.start && section.end)
      .map(section => ({
        from: new Date(section.start),
        to: section.end ? new Date(section.end) : null
      })),
    {
      from: hoverDay?.start ? new Date(hoverDay?.start) : null,
      to: hoverDay?.end ? new Date(hoverDay?.end) : null
    }
  ]

  const daysNotWorkedUtils = [...days].filter(day => !day.working)

  const modifiers = {
    start: [...sections].map(section => new Date(section.start)),
    end: [...sections].map(section => new Date(section.end ?? 0)),
    unUtilDay: daysNotWorkedUtils.map(unUtil => new Date(unUtil.date)),
    hoverDay: hoverDay?.end ? new Date(hoverDay?.end) : undefined
  }

  const currentMonth = new Date(days[0].date)

  const hasUnselectedDaysInCalendar = !sections[sections.length - 1].end

  const currentSectionStartDay = getDate(new Date(hoverDay?.end ?? 0)) + 1
  const currentSectionEndDay = getDate(new Date(hoverDay?.start ?? 0))

  const currentSectionRange = currentSectionEndDay - currentSectionStartDay

  const weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

  return (
    <Picker disabled={disabled}>
      <DayPicker
        showOutsideDays
        className='Range'
        month={currentMonth}
        weekdaysShort={weekdays}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={onMouseEnter}
      />

      {hasUnselectedDaysInCalendar && (
        <CurrentSectionLengthContainer>
          Tamanho do período: {currentSectionRange} dias
        </CurrentSectionLengthContainer>
      )}
    </Picker>
  )
}
