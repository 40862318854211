import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'

import { IGoal } from 'store/modules/goal/types'
import { Table } from 'views/Goal/styles'
import { DayRow } from './components/DayRow'

interface DaysProps {
  goal: IGoal | undefined
}

const Days = ({ goal }: DaysProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table' id='goalDaysTable'>
        <TableHead>
          <TableRow>
            <TableCell>Dia útil</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Meta</TableCell>
            <TableCell>Vendedores</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!!goal && goal.days.map(day => <DayRow day={day} key={day._id} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Days
