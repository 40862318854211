import CheckIcon from '@material-ui/icons/Check'
import CreateIcon from '@material-ui/icons/Create'
import { ReactNode } from 'react'

import palette from 'theme/palette'

import {
  Container,
  Content,
  InformationContainer,
  TitleContainer
} from './styles'

interface ReviewCardProps {
  name: string
  items: JSX.Element
  setActiveStep: () => void
}

export const ReviewCard = ({ setActiveStep, name, items }: ReviewCardProps) => {
  return (
    <Container>
      <CheckIcon style={{ color: palette.primary.main }} />
      <InformationContainer>
        <TitleContainer onClick={setActiveStep}>
          <h1>{name}</h1>
          <CreateIcon
            style={{ color: '#37474F', fontSize: 15, cursor: 'pointer' }}
          />
        </TitleContainer>
        <Content>{items}</Content>
      </InformationContainer>
    </Container>
  )
}
