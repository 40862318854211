import { ReactNode } from 'react'

import boltIcon from 'assets/bolt.png'
import Tooltip from 'components/Tooltip'
import { commissionInfo } from '../../commissionInfo'

import { ButtonContainer, ButtonContent } from './styles'

type CardsInfoKeys = keyof typeof commissionInfo

interface CardButtonProps {
  id: CardsInfoKeys
  children: ReactNode
  dataCy?: string
  isSelected: boolean
  buttonAction: () => void
}

export const CardButton = ({
  id,
  isSelected,
  children,
  buttonAction,
  dataCy = undefined
}: CardButtonProps) => {
  const currentCard = commissionInfo[id]

  return (
    <ButtonContainer
      isSelected={isSelected}
      onClick={buttonAction}
      data-cy={dataCy}
    >
      {children}
      <ButtonContent>
        <h1>{currentCard.title}</h1>
        <p>{currentCard.description}</p>
        {currentCard.tooltipContent && (
          <>
            <strong data-tip data-for={currentCard.title}>
              Exemplo <img src={boltIcon} alt='Icon' />
            </strong>
            <Tooltip
              id={currentCard.title}
              content={currentCard.tooltipContent}
            />
          </>
        )}
      </ButtonContent>
      <p>{currentCard.buttonText}</p>
    </ButtonContainer>
  )
}
