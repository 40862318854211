import { IMonthGoal } from 'store/modules/goal/types'
import { getMonthGoalTooltipInfos } from 'utils/getMonthGoalTooltipInfos'
import { formatPrice } from 'utils/format'

import { Title, TooltipContainer, TooltipContent } from './styles'

interface MonTooltipMonthGoalInfosProps {
  monthGoal: IMonthGoal
}
export const TooltipMonthGoalInfos = ({
  monthGoal
}: MonTooltipMonthGoalInfosProps) => {
  const { sumCommission } = monthGoal.initialCommission

  const monthGoalTooltipInfos = getMonthGoalTooltipInfos(
    monthGoal.initialCommission
  )

  return (
    <TooltipContainer>
      <Title>Remuneração Total</Title>

      <TooltipContent>
        {Object.entries(monthGoalTooltipInfos).map(([key, value]) => (
          <p key={key}>
            <strong>{key}: </strong> {value}
          </p>
        ))}

        <p>
          <strong>Total: </strong>
          {formatPrice(sumCommission ?? 0)}
        </p>
      </TooltipContent>
    </TooltipContainer>
  )
}
