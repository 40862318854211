import styled from 'styled-components'

export const SectionsContainer = styled.div`
  margin-top: 7px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 150px 80px 30px;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 7px;
  color: #546e7a;
  font-weight: 500;

  span {
    text-align: center;
    font-size: 14px;
  }
`

export const Table = styled.div`
  height: 230px;
  min-height: 230px;
  max-height: 230px;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  -ms-overflow-style: none;
  scrollbar-width: 4px;
  scrollbar-color: #b5b5b5;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 50px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export const NoTable = styled.span`
  background-color: #fafafa;
  padding: 10px 30px;
  text-align: center;
  color: #546e7a;
  font-weight: 500;
  font-size: 12px;
  margin: 10px auto;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`
