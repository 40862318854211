import styled from 'styled-components'
import Tab from '@mui/material/Tab'
import { Table as TableMUI } from '@material-ui/core'

export const TabOption = styled(Tab)`
  transition: 0.3s ease-in-out;
  &:hover {
    background: #e5e5e5 !important;
  }
`

export const Table = styled(TableMUI)`
  min-width: 650px;
`
