/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { storeRequest } from 'store/modules/store/actions'
import api from '../../../../repositories/api'
import moment from 'moment'
import { toast } from 'react-toastify'
import { getDate, getYear, set } from 'date-fns'
import endOfMonth from 'date-fns/endOfMonth'

import { scroller } from 'react-scroll'

// Components
import Header from './components/Header'
import SideBar from './components/SideBar'
import MonthStep from './components/StepperContainer/MonthStep'
import CommissionStep from './components/StepperContainer/CommissionStep'
import { ValueStep } from './components/StepperContainer/ValueStep'
import { GoalStep } from './components/StepperContainer/GoalStep'
import ReviewStep from './components/StepperContainer/ReviewStep'
import PromptModal from './components/PromptModal'
import ItemModal from './components/ItemModal'

import { CircularProgress, Modal } from '@material-ui/core'
import BackIcon from '../../../../assets/Icons/BackIcon'
import ErrorIcon from '../../../../assets/Icons/Error'
import ModeCommentIcon from '@material-ui/icons/ModeComment'

import { formatPrice } from '../../../../utils/format'

// Styles
import {
  CenterContainer,
  AddGoalStepContainer,
  MainContainer,
  SupportButton
} from './style'

const AddGoal = () => {
  const { storeId, goalId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const { store } = useSelector(state => state.store)
  const { goalList } = useSelector(state => state.goal)

  const [loadingData, setLoadingData] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)

  const [savedGoal, setSavedGoal] = useState('')
  const [currentGoal, setCurrentGoal] = useState({
    storeId: storeId,
    days: [],
    sections: [],
    usersWorking: [],
    monthGoals: [],
    mainGoals: {},
    dwt: {},
    sectionsList: [],
    daysWeightSuggestion: [],
    lastDaysBackup: []
  })

  const [defaultDays, setDefaultDays] = useState([])

  const [activeStep, setActiveStep] = useState(0)
  const [deleteStep, setDeleteStep] = useState()
  const [resetModal, setResetModal] = useState()

  const [supportModalTextVisible, setSupportModalTextVisible] = useState(true)

  const [storedMainGoals, setStoredMainGoals] = useState()

  const accWeights = () => {
    return (
      currentGoal?.usersWorking.reduce(
        (acc, curr) => (acc += +curr?.goalWeight || 0),
        0
      ) || 0
    )
  }

  const getCurrentStep = data => {
    // Funcao para procurar o primeiro false e redirecionar pra o step

    // Interpreta os dados que já foram preenchidos p/ determinar qual step direcionar.
    // Cada elemento do array são as condições daquela determinada step
    //0 Mês
    //1 Comissão
    //2 Metas da loja aqui
    //3 Metas
    //4 Revisão (se todo mundo der true, significa que ele ta na revisao, o indexOf retorna -1, entao trata o index < 0 para return 4)

    const hasFinishedMonthStep = !!data.month && !!data.storeId

    const hasFinishedCommissionStep =
      typeof data.salary === 'object' &&
      typeof data.distribute === 'boolean' &&
      data.sections?.length > 0 &&
      typeof data.config?.commission?.storeSold === 'boolean'

    const hasFinishedStoreGoalStep =
      data.dsr >= 0 && data.salary?.base > 0 && data.mainGoals.ticket > 0

    const goalDaysSum = data.days.reduce(
      (acc, currDay) => (acc += currDay.goal),
      0
    )

    const hasFinishedGoalStep =
      data.config?.commission?.fixed?.commission >= 0 &&
      data.days?.length > 0 &&
      data.monthGoals?.length > 0 &&
      data.usersWorking?.length > 0 &&
      Number(goalDaysSum.toFixed(2)) === data?.mainGoals?.total

    setActiveStep(
      (index => (index < 0 ? 4 : index))(
        [
          hasFinishedMonthStep,
          hasFinishedCommissionStep,
          hasFinishedStoreGoalStep,
          hasFinishedGoalStep
        ].indexOf(false)
      )
    )
  }

  const updateGoal = obj => {
    setCurrentGoal(prev => {
      let newGoal = { ...prev }
      Object.entries(obj).forEach(([key, value]) => (newGoal[key] = value))

      if (newGoal.days.length > 0) {
        const goalDaysWithSellers = newGoal.days.map(day => {
          const hasNoUsersWorking = day.users.length < 1
          const isWorkingDay = day.working

          if (hasNoUsersWorking && isWorkingDay) {
            const workingSellersIds = newGoal.usersWorking.map(user =>
              typeof user.userId === 'object' ? user.userId._id : user.userId
            )

            const dayWithSellers = {
              ...day,
              users: workingSellersIds
            }

            return dayWithSellers
          }

          return day
        })

        newGoal.days = goalDaysWithSellers
      }

      // VE SE TA DANDO UPDATE EM ALGUNS DESSE ABAIXO, PORQUE SE FOR, ELE NAO MANDA PRO BACK QUANDO UPDATAR A GOAL
      if (
        ![
          'dwt',
          'sectionsList',
          'daysWeightSuggestion',
          'lastDaysBackup',
          'suggestion'
        ].find(el => Object.keys(obj).includes(el))
      ) {
        storeGoal(newGoal)
      }

      return {
        ...newGoal,
        monthGoals: newGoal.monthGoals.map(monthGoal => {
          if (monthGoal.bonusPercentage === 'no-bonus' && !monthGoal.bonus) {
            return {
              ...monthGoal,
              bonusPercentage: false,
              bonus: 0
            }
          }
          return monthGoal
        })
      }
    })
  }

  const updateCurrentGoal = obj => {
    setCurrentGoal(prev => {
      let newGoal = { ...prev }
      Object.entries(obj).forEach(([key, value]) => (newGoal[key] = value))

      if (newGoal.days.length > 0) {
        const goalDaysWithSellers = newGoal.days.map(day => {
          const hasNoUsersWorking = day.users.length < 1
          const isWorkingDay = day.working

          if (hasNoUsersWorking && isWorkingDay) {
            const workingSellersIds = newGoal.usersWorking.map(user =>
              typeof user.userId === 'object' ? user.userId._id : user.userId
            )

            const dayWithSellers = {
              ...day,
              users: workingSellersIds
            }

            return dayWithSellers
          }

          return day
        })

        newGoal.days = goalDaysWithSellers
      }

      return {
        ...newGoal,
        monthGoals: newGoal.monthGoals.map(monthGoal => {
          if (monthGoal.bonusPercentage === 'no-bonus' && !monthGoal.bonus) {
            return {
              ...monthGoal,
              bonusPercentage: false,
              bonus: 0
            }
          }
          return monthGoal
        })
      }
    })
  }

  const handleNextStep = () => {
    // Callback dos botoes "Proxima etapa"
    storeGoal(currentGoal)
    getCurrentStep(currentGoal)
  }

  const deleteGoalStep = deleteStep => {
    const newState = { ...currentGoal }

    const remove = (obj, key) => {
      // Vai varrendo o currentGoal atras do step que passou na chamada pra dar remove nele
      for (let k in obj) {
        if (k === key) {
          if (Array.isArray(obj[key])) obj[key] = []
          else delete obj[key]
          return true
        } else if (typeof obj[k] === 'object') {
          if (remove(obj[k], key)) return true
        }
      }
      return false
    }

    deleteStep.forEach(step => remove(newState, step))

    setCurrentGoal(newState)
  }

  const getDaysWeightSuggestion = id => {
    // Se nao tiver preenchido os dias ainda, ele sugere o peso
    try {
      api.axios.get(`/goal/${id}/suggestion`).then(({ data }) => {
        setCurrentGoal(goal => ({
          ...goal,
          daysWeightSuggestion: data.days
        }))
      })
    } catch (error) {
      console.log(error)
    }
  }

  const loadGoal = async id => {
    setLoadingData(true)
    try {
      //Store carregada
      if (id) {
        //Se tiver id, quer dizer que está no modo edição ou ja selecionou o mes e tem que carregar a goal.
        const loadedGoal = await api.axios.get(`/goal/${id}`)

        const loadedGoalWithSortedDays = {
          ...loadedGoal.data,
          days: loadedGoal.data.days.sort((a, b) =>
            a.date.localeCompare(b.date)
          )
        }

        // Preenche os dados de comissao com o valor da ultima meta
        const lastGoalActive = goalList?.find(goal => goal.config.active)

        if (
          goalList?.length > 0 &&
          !!lastGoalActive &&
          moment(goalList[0].month).unix() >
            moment('2021-08-01T15:00:00.000Z').unix() &&
          !loadedGoalWithSortedDays.salary?.directCommission
        ) {
          const goalSection =
            loadedGoalWithSortedDays.sections.length > 0
              ? loadedGoalWithSortedDays.sections
              : lastGoalActive.sections

          const dataToSave = {
            ...loadedGoalWithSortedDays,
            daysWeightSuggestion: [...currentGoal.daysWeightSuggestion],
            sections: [...goalSection].map((section, i) => {
              if (i === goalSection.length - 1) {
                return {
                  start: new Date(
                    set(new Date(section.start), {
                      year: new Date(
                        getYear(new Date(loadedGoalWithSortedDays.month))
                      ),
                      month: new Date(loadedGoalWithSortedDays.month).getMonth()
                    })
                  ),
                  end: new Date(
                    set(new Date(section.end), {
                      date: getDate(
                        endOfMonth(new Date(loadedGoalWithSortedDays.month))
                      ),
                      year: new Date(
                        getYear(new Date(loadedGoalWithSortedDays.month))
                      ),
                      month: new Date(loadedGoalWithSortedDays.month).getMonth()
                    })
                  )
                }
              } else {
                return {
                  start: new Date(
                    set(new Date(section.start), {
                      year: new Date(
                        getYear(new Date(loadedGoalWithSortedDays.month))
                      ),
                      month: new Date(loadedGoalWithSortedDays.month).getMonth()
                    })
                  ),
                  end: new Date(
                    set(new Date(section.end), {
                      year: new Date(
                        getYear(new Date(loadedGoalWithSortedDays.month))
                      ),
                      month: new Date(loadedGoalWithSortedDays.month).getMonth()
                    })
                  )
                }
              }
            }),
            distribute:
              loadedGoalWithSortedDays.distribute ?? lastGoalActive.distribute,
            salary: {
              ...loadedGoalWithSortedDays.salary,
              directCommission:
                loadedGoalWithSortedDays.salary?.directCommission ??
                lastGoalActive.salary.directCommission,
              splitCommission:
                loadedGoalWithSortedDays.salary?.splitCommission ??
                lastGoalActive.salary.splitCommission
            },
            config: {
              ...loadedGoalWithSortedDays.config,
              commission: {
                ...loadedGoalWithSortedDays.config?.commission,
                storeSold:
                  loadedGoalWithSortedDays.config?.commission?.storeSold ??
                  lastGoalActive.config.commission.storeSold
              },
              suggestion:
                loadedGoalWithSortedDays.config.suggestion === undefined
                  ? lastGoalActive.config.suggestion
                  : loadedGoalWithSortedDays.config.suggestion
            }
          }

          if (loadedGoalWithSortedDays.days.length === 0 && goalId)
            getDaysWeightSuggestion(goalId)

          // Caso seja no-bonus o back devolve como false e bonus 0
          // aqui só formatamos pra aparecer de forma correta pro usuario no front
          const monthGoals = loadedGoalWithSortedDays.monthGoals.map(
            monthGoal => {
              if (!monthGoal.bonusPercentage && monthGoal.bonus === 0) {
                return {
                  ...monthGoal,
                  bonusPercentage: 'no-bonus'
                }
              }
              return monthGoal
            }
          )

          setSavedGoal(JSON.stringify({ ...dataToSave, monthGoals }))
          setCurrentGoal({ ...dataToSave, monthGoals })
          storeGoal(dataToSave)

          getCurrentStep(dataToSave)
        } else {
          const goalToSave = {
            ...currentGoal,
            ...loadedGoalWithSortedDays,
            config: {
              ...currentGoal.config,
              ...loadedGoalWithSortedDays.config,
              suggestion:
                loadedGoalWithSortedDays.config?.suggestion ??
                lastGoalActive?.config?.suggestion ??
                true
            }
          }

          setSavedGoal(JSON.stringify(goalToSave))
          setCurrentGoal(goalToSave)
          getCurrentStep(goalToSave)
        }
      }
      setLoadingData(false)
    } catch (err) {
      console.log(err)
      toast.error(
        err?.response?.data?.error?.friendlyMsg ||
          'Ocorreu um erro ao carregar esta meta'
      )
    }
  }

  const storedGoals = () => {
    if (currentGoal)
      setStoredMainGoals({
        ...currentGoal.mainGoals
      })
  }

  const storeGoal = async goal => {
    setLoadingUpload(true)
    try {
      // Back so aceita o envio dos dados sem esses listados abaixos (ou seja, precisa do ...rest apenas pois os outros sao dados auxiliares)
      const {
        _id,
        createdAt,
        updatedAt,
        dwt,
        sectionsList,
        daysWeightSuggestion,
        lastDaysBackup,
        monthGoals,
        ...goalRest
      } = goal

      // Tratamento de dados pra enviar pro back
      if (goalRest.sections && goalRest.sections.length > 0)
        goalRest.sections = goalRest.sections.map(section => ({
          start: section.start,
          end: section.end
        }))
      if (goalRest.days && goalRest.days.length > 0) {
        goalRest.days = goalRest.days.map(day => {
          const formattedUsers = day.users.map(usr => {
            return {
              dayOff: {
                enable: usr.dayOff.enable
              },
              userId: usr.userId
            }
          })
          return {
            date: day.date,
            working: day.working,
            users: formattedUsers,
            goal: day.goal,
            ...(day.goalLocked && { goalLocked: day.goalLocked })
          }
        })
      }

      if (goalRest.usersWorking && goalRest.usersWorking.length > 0)
        goalRest.usersWorking = goalRest.usersWorking.map(user => ({
          userId: user.userId?._id,
          dsr: user.dsr,
          goalWeight: user.goalWeight,
          active: user.active,
          weightLocked: user.weightLocked
        }))
      // Agora utilizamos uma rota especifica para criar/editar as monthGoals
      // if (goalRest.monthGoals && goalRest.monthGoals.length > 0)
      //   goalRest.monthGoals = goalRest.monthGoals.map(goal => ({
      //     name: goal.name,
      //     value: goal.value,
      //     type: goal.type,
      //     typeBonus: goal.typeBonus,
      //     bonusPercentage:
      //       goal.bonusPercentage === 'no-bonus' && !goal.bonus
      //         ? false
      //         : goal.bonusPercentage,
      //     bonus:
      //       goal.bonusPercentage === 'no-bonus' && !goal.bonus ? 0 : goal.bonus,
      //     commission: goal.commission
      //   }))
      if (typeof goalRest.suggestion === 'boolean')
        goalRest.config = {
          ...goalRest.config,
          suggestion: goalRest.suggestion
        }
      if (goalRest.days.length > 0) {
        const daysWithUsersWorking = goalRest.days.map(day => {
          const hasNoUsersWorking = day.users.length < 1
          const isWorkingDay = day.working
          if (hasNoUsersWorking && isWorkingDay) {
            const workingSellersIds = goalRest.usersWorking.map(
              user => user.userId
            )

            const dayWithSellers = {
              ...day,
              users: workingSellersIds
            }

            return dayWithSellers
          }

          return day
        })

        goalRest.days = daysWithUsersWorking
      }

      if (store._id === goalRest.storeId) {
        const { data } = await api.axios.post('/goal', {
          ...goalRest,
          ...(goalRest.config?.active && { goalId })
        })

        const sortedDefaultDaysByDay = data.days.sort((a, b) =>
          a.date.localeCompare(b.date)
        )

        setDefaultDays(sortedDefaultDaysByDay)

        // Redireciona o usuario pra rota pra ter o funcionamento de edicao no fluxo
        if (!goalId && data._id && data.config.active === false) {
          history.push(`/${storeId}/goals/${data._id}/edit`)
        }
        if (data.month) setSavedGoal(JSON.stringify(goal))
      } else {
        throw new Error('Houve um problema entre a meta das lojas')
      }
    } catch (err) {
      console.log(err)
      toast.error(
        'Ocorreu um erro ao salvar a meta. Verifique os dados e tente novamente!'
      )
    }
    setLoadingUpload(false)
  }

  //useEffect para preparar a goal
  useEffect(() => {
    if (!store) {
      dispatch(storeRequest(storeId))
    } else {
      if (store && goalId) loadGoal(goalId)
    }
  }, [store, goalId])

  // Botao de suporte ficar sem texto
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSupportModalTextVisible(false)
      clearTimeout(timeout)
    }, 10000)
  }, [])

  // Armazenar os valores de mainGoal antes de serem alterados, para fazer comparação posteriormente
  useEffect(() => {
    storedGoals()
  }, [loadingData])

  const formatMonthGoalsToBackendFormat = monthGoals => {
    return monthGoals.map(monthGoal => {
      if (monthGoal.bonusPercentage === 'no-bonus' && !monthGoal.bonus) {
        return {
          ...monthGoal,
          bonusPercentage: false,
          bonus: 0
        }
      }
      return monthGoal
    })
  }

  window.onbeforeunload = () => {
    const {
      _id: __id,
      createdAt: _fkCreatedAt,
      updatedAt: _updatedAt,
      dwt: _dwt,
      sectionsList: _sectionsList,
      daysWeightSuggestion: _daysWeightSuggestion,
      lastDaysBackup: _lastDaysBackup,
      monthGoals: _monthGoals,
      ...currentGoalRest
    } = currentGoal

    const {
      _id,
      createdAt,
      updatedAt,
      dwt,
      sectionsList,
      daysWeightSuggestion,
      lastDaysBackup,
      monthGoals,
      ...savedGoalRest
    } = JSON.parse(savedGoal)

    if (
      JSON.stringify(savedGoalRest) !== JSON.stringify(currentGoalRest) &&
      window.location.href.includes('/edit')
    ) {
      return 'As alterações que você fez talvez não sejam salvas.'
    }
  }

  const steps = [
    <MonthStep
      goal={currentGoal}
      storeGoal={storeGoal}
      handleNextStep={handleNextStep}
    />,
    <CommissionStep
      goal={currentGoal}
      updateGoal={updateGoal}
      setDeleteStep={setDeleteStep}
      handleNextStep={handleNextStep}
    />,
    <ValueStep
      goal={currentGoal}
      updateGoal={updateGoal}
      setDeleteStep={setDeleteStep}
      handleNextStep={handleNextStep}
      storedMainGoals={storedMainGoals}
    />,
    <GoalStep
      goal={currentGoal}
      dispatch={dispatch}
      defaultDays={defaultDays}
      updateGoal={updateGoal}
      updateCurrentGoal={updateCurrentGoal}
      setDeleteStep={setDeleteStep}
      handleNextStep={handleNextStep}
      setResetModal={setResetModal}
      setDeleteGoalStep={deleteGoalStep}
      goalList={goalList}
      loadingUpload={loadingUpload}
      deleteGoalStep={deleteGoalStep}
    />,
    <ReviewStep
      goal={currentGoal}
      setActiveStep={setActiveStep}
      storeGoal={storeGoal}
      updateGoal={updateGoal}
    />
  ]

  return (
    <MainContainer>
      {loadingData && (
        <CircularProgress
          style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 99 }}
          size={40}
        />
      )}
      <Header
        data={{
          ...currentGoal,
          daysWeightSuggestion: [],
          ...(savedGoal.length > 0 &&
            JSON.parse(savedGoal).lastDaysBackup && {
              lastDaysBackup: JSON.parse(savedGoal).lastDaysBackup
            }),
          monthGoals: formatMonthGoalsToBackendFormat(currentGoal.monthGoals)
        }}
        savedGoal={savedGoal}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        dispatch={dispatch}
        storeGoal={storeGoal}
        loading={loadingUpload}
      />
      <AddGoalStepContainer loadingData={loadingData}>
        <CenterContainer>
          <div className='goal-step-container'>
            {!loadingData && steps[activeStep]}
          </div>
        </CenterContainer>
        {activeStep < 4 && (
          <SideBar storeName={store?.name} data={currentGoal} store={store} />
        )}
        <SupportButton
          onMouseEnter={() => setSupportModalTextVisible(true)}
          onMouseLeave={() => setSupportModalTextVisible(false)}
          textVisible={supportModalTextVisible}
          href='https://wa.me/5544991546704'
          target='_blank'
        >
          <ModeCommentIcon style={{ color: '#37474F' }} />
          {supportModalTextVisible && <span>Precisa de ajuda?</span>}
        </SupportButton>
      </AddGoalStepContainer>
      <Modal open={!!deleteStep} closeAfterTransition>
        <PromptModal
          icon={<BackIcon />}
          onClose={() => setDeleteStep()}
          title='Deseja continuar a alteração?'
          description={
            'Se você alterar essa informação, as etapas abaixo serão perdidas.'
          }
          onLeft={() => setDeleteStep()}
          rightTitle='Alterar'
          onRight={() => {
            deleteGoalStep(deleteStep)
            setDeleteStep()
          }}
          isAlert
        >
          <ul style={{ marginBottom: 20 }}>
            {deleteStep?.map((deleted, i) => (
              <li
                style={{
                  listStyle: 'none',
                  fontWeight: 'bold',
                  fontSize: 13,
                  fontFamily: 'Roboto',
                  textAlign: 'center'
                }}
                key={i}
              >
                {{
                  directCommission:
                    'Condição de tipo de comissão (Direta/Piso+Comissão)',
                  splitCommission:
                    'Condição de base de comissão (Resultado do mês/período)',
                  distribute:
                    'Condição de redistribuição da meta (Direta/Redistribuída)',
                  fixed: 'Comissão fixa',
                  storeSold:
                    'Condição de comissão quando atingir meta da loja/vendedor',
                  monthGoals: 'Metas do mês dos vendedores',
                  usersWorking: 'Vendedores que irão trabalhar nesta meta',
                  days: 'Metas diárias (calendário)',
                  dwt: 'Somatório de metas diárias',
                  sections: 'Períodos',
                  sectionsList: 'Valores dos períodos',
                  base: 'Piso salarial',
                  complement: 'Complemento de salário',
                  total: 'Meta da loja',
                  price: 'Meta de preço médio',
                  ticket: 'Meta de ticket médio'
                }[deleted] || deleted}
              </li>
            ))}
          </ul>
        </PromptModal>
      </Modal>
      <Modal open={!!resetModal} closeAfterTransition>
        <PromptModal
          icon={<ErrorIcon />}
          onClose={() => {
            deleteGoalStep(resetModal)
            setResetModal()
          }}
          title='Deseja confirmar meta diferente para sua loja?'
          description={
            'Os pesos definidos para os vendedores estão alterando a meta da loja:'
          }
          leftTitle='Editar os pesos'
          rightTitle='Confirmar meta'
          onRight={() => {
            setResetModal()

            scroller.scrollTo('monthGoals', {
              smooth: true,
              offset: -100
            })
          }}
          isAlert
        >
          <ItemModal
            children={`Meta da loja: ${formatPrice(
              currentGoal?.mainGoals?.total
            )}`}
          />

          <ItemModal
            children={`Meta com peso ${
              currentGoal?.usersWorking.length > 0 && accWeights() * 100
            }%: ${formatPrice(
              (currentGoal?.mainGoals?.total * accWeights()) /
                currentGoal?.usersWorking.length
            )}`}
            feedback='error'
          />
        </PromptModal>
      </Modal>
    </MainContainer>
  )
}

export default AddGoal
