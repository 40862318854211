import { Container } from './styles'

interface TopTableDaysProps {
  topTable: string[]
}

export const TopTableDays = ({ topTable = [] }: TopTableDaysProps) => {
  return (
    <Container topTable={topTable}>
      {topTable.map((table, i) => (
        <span key={i}>{table}</span>
      ))}
    </Container>
  )
}
