import {
  ArrowRightIcon,
  CardInfo,
  CardSubValue,
  CardTitle,
  CardValue,
  ChildrenContent,
  Container,
  LeftCard
} from './styles'

interface ReviewItemProps {
  title?: string
  value?: string | number
  subValue?: string
  contentChildren?: any
  textColor?: string
  extraChildren?: JSX.Element
  spanDataCy?: string
}

export const ReviewItem = ({
  title,
  value,
  subValue,
  contentChildren,
  textColor,
  extraChildren,
  spanDataCy
}: ReviewItemProps) => {
  const hasContentChildren = Boolean(contentChildren)
  const hasSubValue = Boolean(subValue)

  return (
    <Container>
      <CardInfo>
        <LeftCard>
          <CardTitle>{title}</CardTitle>
          {extraChildren}
        </LeftCard>
        <ArrowRightIcon />
      </CardInfo>

      {hasContentChildren ? (
        <ChildrenContent>{contentChildren}</ChildrenContent>
      ) : (
        <>
          <CardValue textColor={textColor} data-cy={spanDataCy}>
            {value}
          </CardValue>
          {hasSubValue && <CardSubValue>{subValue}</CardSubValue>}
        </>
      )}
    </Container>
  )
}
